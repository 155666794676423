.modal_window_bg {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3550;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

.modal_window_content {
    position: fixed;
    max-width: 583px;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3100;
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    border-radius: 8px;
    padding: 2rem 2rem 2.75rem;
}

.delete_modal_window {
    max-width: 489px;
    padding: 1.5rem 6rem 2rem;
}

.modal_window_content h4 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.45;
    margin-bottom: 8px;
    color: var(--dark-grey);
}

.modal_window_content h5 {
    font-size: 20px;
    line-height: 30px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    margin: 0 0 .75rem;
    text-align: center;
}

.modal_window_content p {
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0 0 1.5rem;
    text-align: center;
}

.modal_window_content .error_message {
    color: var(--error);
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    display: block;
    margin: .5rem 0 0;
}

.modal_window_buttons {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 1.5rem;
    align-items: center;
    justify-content: space-between;
    margin: 1.5rem 0 0;
}

.modal_window_content .delete_modal {
    padding: 0;
}

.modal_window_content .delete_modal > span {
    font-size: 20px;
    line-height: 30px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--error);
    text-align: center;
    display: block;
    margin: 0 0 4px;
}

.modal_window_content .delete_modal h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    margin: 0 0 1.75rem;
}

.modal_window_content .delete_modal p {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0;
}

.modal_window_content .delete_modal .modal_window_buttons {
    justify-content: center;
    grid-gap: 1rem;
    margin: 1.75rem 0 0;
}

.modal_window_content .delete_modal .modal_window_buttons button {
    width: 5rem;
}

.modal_window_content .delete_modal .modal_window_buttons .cancel_button {
    color: #0F172A;
    border: 1px solid #475569;
}

.review-subscription-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.review-subscription-title {
    width: 375px;
    font-size: 20px;
    font-weight: 600;
    color: #0F172A;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 24px;
}

.email-gitgo-support {
    font-size: 14px;
    font-weight: 400;
    color: #475569;
    padding: 0 50px;
    margin-top: 12px;
}

.got-it-button {
    display: flex;
    justify-content: space-between;
    width: auto;
}

.got-it-button button {
    width: 204px;
    height: 36px;
    background-color: var(--gitgo-blue);
    color: #FFFFFF;
    border: 1px solid var(--gitgo-blue);
    padding: 0 12px;
}