.multiply_select_wrapper {
    position: relative;
}

.multiply_select_wrapper .title {
    font-size: 14px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    display: block;
    margin: 0 0 4px;
}

.multiply_select_wrapper .error_title {
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    display: grid;
    color: var(--error);
    margin: 8px 0 0;
    padding: 0;
}

.multiply_select_wrapper .content {
    display: grid;
    grid-template-columns: 1fr max-content;
    padding: .5rem 1rem;
    cursor: pointer;
    border: 1px solid #E2E8F0;
    border-radius: 2px;
    grid-gap: 10px;
}

.multiply_select_wrapper .content .items {
    display: flex;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
}

.multiply_select_wrapper .content .items span {
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
}

.multiply_select_wrapper .select_popup {
    top: 6rem;
}

.multiply_select_wrapper .select_popup .item {
    padding: 8px;
    transition: .3s;
    border-radius: 2px;
    cursor: pointer;
    margin: 4px;
    text-transform: capitalize;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 6px;
    align-items: center;
}

.multiply_select_wrapper .select_popup .item .checkbox {
    width: 1rem;
    height: 1rem;
    border: 2px solid var(--gitgo-blue);
    display: grid;
    align-items: center;
    justify-content: center;
    transition: .3s;
}

.multiply_select_wrapper .select_popup .item .checkbox svg {
    transition: .2s;
    opacity: 0;
    transform: scale(.9);
}

.multiply_select_wrapper .select_popup .item .checkbox_active svg {
    opacity: 1;
    transform: scale(1);
}