.calendar_wrapper .title {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    display: block;
    margin: 0 0 4px;
}

.calendar_wrapper .ant-picker {
    width: 100%;
    padding: 8px 16px;
}

.calendar_wrapper .error {
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    display: grid;
    color: var(--error);
    margin: 8px 0 0;
    padding: 0;
}