.dashboard_header span {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    color: var(--gitgo-blue);
}
  
.dashboard_header-btn_download{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;   
    background: #01426A;
    border-radius: 2px;
    width: 190px;
    height: 48px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}
 
.dashboard_total_blocks {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px 30px;
}

.rating_of_month {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 8px;
    align-items: center;
}

.total_stat_block {
    border: 1px solid #ffffff;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 12px;
    position: relative;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 1rem;
    align-items: center;
    transition: 0.4s;
    cursor: pointer;
}
.total_stat_block:hover {
    border: 1px solid var(--gitgo-blue);
}
.total_text {
    text-transform: capitalize;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #475569;
}

.value_total {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: var(--gitgo-blue);
}

.period_text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #94A3B8;
}

.procent_number {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
}

.left_block_side {
    display: grid;
    grid-gap: 4px;
}

.ball_content {
    position: relative;
    border-radius: 50%;
    width: 5em;
    height: 5em;
    background-color: #EDFCF5;
    display: grid;
    align-items: center;
    justify-content: center;
}


.total_opportunities_block {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
}

.graph_wrapper {
    margin-top: 32px;
}

.travel_opportunities_block {
    width: 100%;
    max-width: 505px;
    position: relative;
    padding: 20px;
    background: #FFFFFF;
    border-radius: 8px;
    height: max-content;
}

.content_block {
    padding: 0 24px 20px;
}

.opportunities_title span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: var(--gitgo-blue);
}

.steps_of_opportunities {
    margin-top: 40px;
}

.step_line {
    position: relative;
    margin-top: 13px;
    display: flex;
    border-bottom: 1px solid #E2E8F0;
    justify-content: space-between;
}

.step_line span {
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #475569;
    align-self: flex-end;
}

.prospect_status_block {
    padding: 20px 0;
    width: 100%;
    max-width: 505px;
    height: 400px;
    background: var(--gitgo-blue);
    border-radius: 8px;
}

.prospect_status_title span {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #F8FAFC;
}

.last_step_block {
    margin-right: 18px !important;
}

.line_grid {
    margin-top: 64px;
    display: grid;
    grid-gap: 117px;
}

.status_line_graph {
    display: flex;
    align-items: center;
}

.status_line_ball div {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: #1DBF73;
}

.status_line_ball span {
    margin-left: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #F8FAFC;
}

.status_line_ball {
    display: flex;
    align-items: center;
}

.status_line_graph span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF;
}

.status_line_graph div {
    border-bottom: 1px solid #E2E8F0;
    width: 100%;
    margin-left: 5px;
}

.prospect_status {
    display: flex;
    margin-left: 100px;
    grid-gap: 60px;
}

.recent_engagement_block {
    width: 100%;
    max-width: 505px;
    background: #FFFFFF;
    border-radius: 8px;
    height: 400px;
}

.recent_engagement_title {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E2E8F0;
    padding: 20px 0;
}

.engagement_list {
    height: 340px;
    overflow: hidden;
}

.view_all_engagements_block {
    position: relative;
    cursor: pointer;
    align-items: center;
}
.view_all_engagements_block span{
    text-decoration:underline;
}

.recent_engagement_title span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--gitgo-blue);
}
.recent_engagement_title img {
   margin-left:5px;
}
.recent_engagement_export-data{
    margin-left: 40%;
}
.recent_engagement_export-dataBtn{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;   
    background: #01426A;
    border-radius: 2px;
    width: 153px;
    height: 36px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
  
}
.engagement_line {
    align-items: center;
    display: flex;
    padding: 12px 0;
    justify-content: space-between;
    border-bottom: 1px solid #E2E8F0;
}

.left_engagement_side {
    display: flex;
}

.user_data {
    margin-left: 10px;
    display: grid;
    grid-gap: 5px;
    padding: 5px 0;

}

.user_name {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #475569;
}

.company_name {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #475569;
}

.logged_type {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #94A3B8;
}

.date_of_engagement span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #94A3B8;
}

.step_line span {
    padding: 5px;
}

.lines_chart {
    position: absolute;
    display: grid;
    top: 195px;
    left: 35px;
    width: 450px;
}

.second_line {
    margin-top: 42px;
}

.third_line {
    margin-top: 0;
}

.funnel_columns .funnel_chart {
    /* position: absolute; */
    inset: 45px 45px;
}

.fourth_line {
    margin-top: 0;
}

#my_Chart {
    width: 490px;
    height: 350px;
}

.recent_engagement_wrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3550;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}
.recent_engagement_wrapper_no-blur {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3550;
}

.recent_activity_modal_window {
    position: fixed;
    max-width: 817px;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3100;
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    box-sizing: border-box;
    border-radius: 8px;
    max-height: 75vh;
    /*height: 75vh;*/
}

.fixed_modal_header {
    border-radius: 10px;
    padding: 30px 24px 0;
    position: fixed;
    width: 100%;
    background-color: #FFFFFF;
}
.fixed_modal_header .status-filter__innerList{
    padding: 27px 0;
    border-bottom: 1px solid #E2E8F0;
}
.modal_list_of_activity {
    margin-top: 140px;
    border-radius: 8px;
    overflow: auto;
    height: 55vh;
    padding: 1.5rem 1.5rem 20px;
}

.recent_engagement_modal_header {
    align-items: center;
    display: flex;
    justify-content: space-between;
   
}

.recent_engagement_modal_header .recent_modal_title {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    color: #475569;
}

.no_activity_title {
    margin-top: 30px;
    display: grid;
    justify-content: center;
}

.no_activity_title span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(64, 73, 88, 0.71);
}

.dashboard-graphs-line-first {
    width: 100%;
    height: 1px;
    background-color: #E2E8F0;
    margin-top: 20px;
}

.no-activity-title-graphs-first {
    margin-top: -7px;
    padding-top: 30px;
    display: grid;
    justify-content: center;
    color: rgba(64, 73, 88, 0.71);
}

.dashboard-graphs-line {
    width: 100%;
    height: 1px;
    background-color: white;
    margin-top: 20px;
}

.no-activity-title-graphs {
    display: grid;
    justify-content: center;
    color: white;
    padding-top: 30px;
}

.modal-background-wrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3550;
    cursor: auto;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    -webkit-animation-name: fadeIn; /* Fade in the background */
    -webkit-animation-duration: 0.4s;
    animation-name: fadeIn;
    animation-duration: 0.4s;

}
.no-blur{
    backdrop-filter: blur(0px);;
}

.total-statistics-modal {
    position: fixed;
    max-width: 817px;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3600;
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 24px;
    max-height: 61vh;
    height: 61vh;
    overflow-y: auto;
    -webkit-animation-name:slideIn;
    -webkit-animation-duration: 0.4s;
    animation-name: slideIn;
    animation-duration: 0.4s;
}

.total-statistics-modal .total-statistics-header {
    display: flex;
    justify-content: space-between;
}

.total-statistics-modal .total-statistics-header .title-of-modal-window {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    color: #475569;
    text-transform: uppercase;
}

.total-statistics-modal .total-statistics-header .close-modal-button {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #01426A;
    padding: 6px 25px;
    border: 1px solid #01426A;
}

.total-statistics-modal .percentage-counter-of-total {
    padding: 0 30px 0;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.total-statistics-modal .percentage-counter-of-total.no_activity {
    grid-template-columns: 180px 180px 180px;
    display: grid;
    justify-content: center;
    justify-items: center;
}
.total-statistics-modal .percentage-counter-of-total.center-block {
    justify-content: space-around;
}

.total-statistics-modal .percentage-counter-of-total .grand-total span {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 34px;
    color: #01426A;
}
.total-statistics-modal .percentage-counter-of-total .grand-total .percentage-count-line {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.total-statistics-modal .percentage-counter-of-total .grand-total p {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #94A3B8;
}

.total-statistics-modal .percentage-counter-of-total .grand-total {
    display: grid;
    justify-content: center;
    text-align: center;
}

.total-statistics-modal .modal-statistics-list {
    margin-top: 40px;
}


.total-statistics-modal .modal-statistics-list .titles-modal-header {
    display: grid;
    grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr);
    background-color: #F8FAFC;
    padding: 9px 20px;
}
.total-statistics-modal .modal-statistics-list .titles-modal-header-opportunity {
    display: grid;
    grid-template-columns: minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr);
    background-color: #F8FAFC;
    padding: 9px 20px;
}
.total-statistics-modal .modal-statistics-list .titles-modal-header.no_activity ,
.total-statistics-modal .modal-statistics-list .titles-modal-header-opportunity.no_activity {
    grid-template-columns: minmax(208px, 1fr) minmax(80px, 1fr) ;
}

.total-statistics-modal .modal-statistics-list .titles-modal-header .item ,
.total-statistics-modal .modal-statistics-list .titles-modal-header-opportunity .item{
    display: grid;
    grid-template-columns: max-content 1fr;
    cursor: pointer;
}

.total-statistics-modal .modal-statistics-list .titles-modal-header .item span ,
.total-statistics-modal .modal-statistics-list .titles-modal-header-opportunity .item span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #0F172A;
}
.no-items{
    display: flex;
    justify-content: center;
    color: #475569;
    font-size: 20px;
    font-weight: bold;
    padding-top: 30px;
}

.total-statistics-modal .modal-statistics-list .modal-list-wrapper {
    display: grid;
}

.total-statistics-modal .modal-statistics-list .block-of-status-data .activity-modal-list .modal-list-line {
    display: grid;
    height: 55px;
    padding: 20px 20px 16px 20px;
    border-bottom: 1px solid #E2E8F0;
    grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr);
    align-content: center;
    gap: 15px;
}
.total-statistics-modal .won-opportunities-wrapper .modal-statistics-list .activity-modal-list .modal-list-line {
    display: grid;
    padding: 20px 20px 16px 20px;
    border-bottom: 1px solid #E2E8F0;
    grid-template-columns: minmax(200px, 1fr) minmax(100px, 1fr) minmax(200px, 1fr);
    align-content: center;
}
.total-statistics-modal .won-opportunities-wrapper .modal-statistics-list .activity-modal-list .modal-list-line.no_activity {
    grid-template-columns: minmax(200px, 1fr) minmax(100px, 1fr);
}

.total-statistics-modal .modal-statistics-list .activity-modal-list .modal-list-line .name-of-user {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #01426A;
    cursor: pointer;
}

.total-statistics-modal .modal-statistics-list .activity-modal-list .modal-list-line .revenue-count {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #475569;
    opacity: 0.5;
}

.total-statistics-modal .modal-statistics-list .activity-modal-list .modal-list-line .status-button {
    font-style: normal;
    text-align: start;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #01426A;
    background-color: rgba(1, 66, 106, 0.1);
    padding: 4px 8px;
    display: flex;
    width: max-content;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    height: 25px;
    text-transform: capitalize;
    cursor: auto;
}

.total-statistics-modal .modal-statistics-list .view-all-footer-block {
    margin-top: 30px;
    display: grid;
    grid-template-columns: max-content 1fr;
    justify-content: center;
    align-items: center;
    grid-gap: 15px;
}
.total-statistics-modal .modal-statistics-list .footer-modal {
    display: grid;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
}

.total-statistics-modal .modal-statistics-list .view-all-footer-block .view-all-text {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #01426A;
}

.total-statistics-modal .modal-status-list {
    padding: 33px 22px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    max-width: 812px;
    justify-content: center;
}

.total-statistics-modal .modal-status-list .new-status-column {
    display: grid;
    justify-items: center;
    border-right: 1px solid #E2E8F0;
    align-content: flex-start;

}
.total-statistics-modal .modal-status-list .new-status-column.last-closed{
    border-right: none;
}


.total-statistics-modal .modal-status-list .new-status-column .color-status-block {
    display: grid;
    padding: 8px 10px;
    width: 100%;
    height: 70px;
    justify-content: center;
    cursor:pointer;
}

.total-statistics-modal .modal-status-list .new-status-column .color-status-block span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    color: #FFFFFF;
    text-align: center;
}

.total-statistics-modal .modal-status-list .new-status-column .new-column-users {
    padding: 10px 40px;
    border-bottom: 1px solid #E2E8F0;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    align-items: center;
    color: #01426A;
    cursor: pointer;
    text-align: center;
}

.total-statistics-modal .modal-status-list .new-status-column .color-status-block.new-status {
    background: #FFB648;
}

.total-statistics-modal .modal-status-list .new-status-column .color-status-block.property-status {
    background: #F57C1F;
}

.total-statistics-modal .modal-status-list .new-status-column .color-status-block.ready-status {
    background: #4BDE97;
}
.total-statistics-modal .modal-status-list .new-status-column .color-status-block.inactive-status {
    background: #F26464;
}
.total-statistics-modal .modal-status-list .new-status-column .color-status-block.existing-status {
    background: #49BACA;
}
.total-statistics-modal .view-all-footer-block{
    display: grid;
    grid-gap: 10px;
    align-items: center;
    grid-template-columns: max-content 1fr;
    justify-content: center;
}
.total-statistics-modal .footer-modal{
    margin-top: 25px;
    display: grid;
    justify-content: center;
}
.total-statistics-modal .view-all-footer-block .view-all-text{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    color: #01426A;
    cursor: pointer;
    text-decoration: underline;
}
.modal-statistics-list .view-all-footer-block .discover-opportunities{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    padding: 6px 16px;
    background-color: #FFB648;
    cursor: pointer;
}
.total-statistics-modal .modal-statistics-list .engagement-wrapper-activity  {
    padding: 0 30px 0;
    justify-content: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
}
.total-statistics-modal .modal-statistics-list .engagement-wrapper-activity .contact-title  {
    display: grid;
    grid-gap: 20px;
    height: 50px;
    width: 250px;
}
.total-statistics-modal .modal-statistics-list .engagement-wrapper-activity .contact-title.prospect {
    display: grid;
    grid-gap: 27px;
    width: 250px;
}
.total-statistics-modal .modal-statistics-list .engagement-wrapper-activity .contact-title .title-block {
    border-bottom: 1px solid #4E5D78;
    padding: 5px 0 ;
}
.total-statistics-modal .modal-statistics-list .engagement-wrapper-activity .contact-title .title-engagement {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #13426B;
}
.total-statistics-modal .modal-statistics-list .engagement-wrapper-activity .contact-title .contacts-block {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    cursor: pointer;
}
.total-statistics-modal .modal-statistics-list .engagement-wrapper-activity .contact-title .contacts-block .logo-contacts-side {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 10px;
}
.total-statistics-modal .modal-statistics-list .engagement-wrapper-activity .contact-title .contacts-block.prospect-section {
    border-bottom: 1px solid #13426B;
}
.total-statistics-modal .modal-statistics-list .engagement-wrapper-activity .contact-title .contacts-block span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #475569;
    text-transform: capitalize;
}

.total-statistics-modal .modal-statistics-list .engagement-wrapper-activity .contact-title .contacts-block .last-engaged {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #94A3B8;
}
.total-statistics-modal .modal-statistics-list .engagement-wrapper-activity .contact-title .contacts-block .prospect-name {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #13426B;
}
.total-statistics-modal .modal-statistics-list .engagement-wrapper-activity .contact-title.prospect .contacts-block .prospect-name {
    padding: 0 0 5px;
    cursor: pointer;
}
.total-statistics-modal .modal-statistics-list .engagement-wrapper-activity .contact-title .contacts-block button {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    border-radius:50%;
    background-color:#01426A ;
    width: 32px;
    height: 32px;
    cursor: auto;
}

@media (max-width: 1680px) {
    .funnel_columns .funnel_chart {
        inset: 45px 20px;
    }
}
@media (max-width: 1650px) {
    .total_opportunities_block {
        grid-template-columns: 1fr 1fr;
    }
    .funnel_columns .funnel_chart {
        inset: 45px 45px;
    }

    .travel_opportunities_block {
        order: 0;
    }

    .prospect_status_block {
        order: 1;
    }
}

@media (max-width: 1450px) {
    .prospect_status_block {
        width: 100%;
    }

}

@media (max-width: 1450px) {
    .total-statistics-modal {
        max-height: 71vh;
        height: 71vh;
    }

}
@media (max-width: 1331px) {
    .prospect_status_block,
    .travel_opportunities_block {
        width: 410px;
        height: max-content;
    }

    .mixed-chart {
        inset: 22px 0;
    }

    .line_columns {
        padding: 0 0 0 10px;
    }

    .funnel_columns .funnel_chart {
        position: relative;
        inset: 0 0;
    }
    .total-statistics-modal .modal-statistics-list .modal-list-wrapper {
        display: grid;
        max-height: 19vh;
        height: 19vh;
        overflow: auto;
    }

}
