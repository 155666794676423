.box {
    width: 489px;
    height: 375px;
    margin: auto;
    background: #fff;
    border-radius: 8px;
    padding: 48px;
    border: 1px solid #CBD5E1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box::-webkit-scrollbar {
    overflow: hidden;
}

.assign-close-button {
    cursor: pointer;
    background: #FFFFFF;
    border: 1px solid var(--gitgo-blue);
    border-radius: 2px;
    width: 80px;
    height: 36px;
    font-size: 14px;
    font-weight: 700;
    color: var(--gitgo-blue);
    font-family: 'Inter', sans-serif;
    margin-right: 8px;
}

.assign-confirm-button {
    cursor: pointer;
    background: var(--gitgo-blue);
    border-radius: 2px;
    width: 80px;
    height: 36px;
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF;
    font-family: 'Inter', sans-serif;
    margin-left: 8px;
}

.assign-select-value {
    width: 308px;
    height: 40px;
    border: 1px solid #E2E8F0;
    border-radius: 2px;
    padding-left: 16px;
    margin-top: 4px;
    margin-bottom: 28px;
}

.assign-prospect-text {
    font-size: 20px;
    font-weight: 400;
    color: #0F172A;
    margin-bottom: 8px;
    text-align: center;
    margin-right: 5px;
}

.assign-item-name {
    font-size: 20px;
    font-weight: 600;
    color: #0F172A;
    text-align: center;
    margin-bottom: 8px;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
    display: block;
    max-width: 240px;
}

.assign-form-title {
    font-size: 12px;
    font-weight: 700;
    color: var(--gitgo-blue);
}

.assign-popup-button-container {
    display: flex;
    margin: 25px auto 0;
}

.assign-popup-button-container button {
    width: 85px;
    height: 36px;
}

.assign-popup-button-container button:first-child {
    border: 1px solid var(--gitgo-blue);
    font-size: 14px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    color: var(--gitgo-blue);
    background-color: transparent;
    margin-right: 8px;
    align-items: center;
}

.assign-popup-button-container button:last-child {
    border: 1px solid var(--gitgo-blue);
    font-size: 14px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    background-color: var(--gitgo-blue);
    color: #FFFFFF;
    margin-left: 8px;
    align-items: center;
}

.assign-custom-select {
    width: 308px;
}

.assign-prospect-name-container {
    display: flex;
}

.assign-prospect-name-content {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}