.forgot_password_wrapper {
    width: 100%;
    height: 100vh;
    display: grid;
    align-items: center;
    justify-content: center;
}

.forgot_password_wrapper .content {
    width: 547px;
}

.forgot_password_wrapper .content .card {
    margin: 0 0 2rem;
}

.forgot_password_wrapper .content .head {
    background: var(--gitgo-blue);
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 6px 0;
}

.forgot_password_wrapper .content .head img {
    max-width: 100%;
    width: 81px;
}

.forgot_password_wrapper .content .body {
    padding: 2rem 58px 5rem;
    background: #F8FAFC;
}

.forgot_password_wrapper .content .body h4 {
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    margin: 0 0 8px;
}

.forgot_password_wrapper .content .body p {
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0 0 16px;
}

.forgot_password_wrapper .content .body form {
    display: grid;
    grid-gap: 2rem;
}

.forgot_password_wrapper .content .body button {
    padding: 12px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #FFFFFF;
    background: var(--gitgo-blue);
    border-radius: 2px;
}

.forgot_password_wrapper .content a {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    display: block;
    width: max-content;
    margin: 0 auto;
}

.forgot_password_wrapper .content .body_success {
    padding: 4.5rem 58px 6.25rem;
    background: #F8FAFC;
}

.forgot_password_wrapper .content .body_success h3 {
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    margin: 0 0 2rem;
}

.forgot_password_wrapper .content .body_success button {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #FFFFFF;
    padding: 12px 22px;
    background: var(--gitgo-blue);
    border-radius: 2px;
    width: max-content;
    margin: 0 auto;
    display: block;
    transition: .3s;
}

.forgot_password_wrapper .content .body_success button:disabled {
    background: #475569;
}