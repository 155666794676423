.input_component {
    position: relative;
}

.input_component span {
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    display: grid;
    margin: 0 0 4px;
}

.input_component span.input_component_title_new {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    margin: 0 0 .25rem;
}

.input_component span.prospects_input_title {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    margin: 0 0 .25rem;
}

.input_component span.targeting_input_title, .input_component span.targeting_input_title span {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    margin: 0 0 .25rem;
}

.input_search > div {
    position: relative;
}

.input_search .searchImage {
    /*background-image: url("../assets/search_icon.svg");*/
    background-repeat: no-repeat;
    background-size: cover;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 13px;
    top: 9px;
}

.dropdown_component_wrapper {
    position: relative;
    cursor: pointer;
}

.dropdown_component_wrapper input {
    cursor: pointer;
}

.dropdown_component_wrapper .dropdown_image {
    position: absolute;
    right: 16px;
    top: 35px;
}

.dropdown_component_wrapper .dropdown_component_opened {
    position: absolute;
    left: 0;
    top: 40px;
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid #E2E4E9;
    box-sizing: border-box;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    overflow-y: scroll;
    z-index: 999;
}

.dropdown_component_wrapper .dropdown_component_opened > span,
.dropdown_component_wrapper .dropdown_component_opened label {
    width: 100%;
    padding: 8px 14px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: var(--slate);
}

.input_component label {
    margin-bottom: unset;
    position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
}

.input_component label.input_search_wrap input::placeholder, .input_component label.input_search_pages input::placeholder {
    color: #94A3B8;
}

.input_component label.input_search_wrap input, .input_component label.input_search_pages input {
    padding: 8px 50px 8px 1rem;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #000;
    background: #FFFFFF;
    outline: 1px solid #E2E8F0;
    border-radius: 2px;
    border: none;
    width: 100%;
    max-width: 350px;
}

.input_component label.input_search_wrap input:focus, .input_component label.input_search_pages input:focus {
    border: none;
}

.input_component label.input_search_wrap .search_icon, .input_component label.input_search_pages .search_icon {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
}

.input_component label.input_search_pages {
    max-width: 445px;
}

.input_component label.input_search_pages input {
    padding: 6px 50px 6px 1rem;
    max-width: 100%;
}

.dropdown_component_wrapper .dropdown_component_opened span:hover,
.dropdown_component_wrapper .dropdown_component_opened div:hover {
    background: #EAF6FD;
}

.input_search:hover .searchImage {
    /*background-image: url("../assets/search_active.svg");*/
}

.search_image_active {
    /*background-image: url("../assets/search_active.svg") !important;*/
}

.input_component {
    margin: 8px 0 4px;
}

.input_component_unset_margin {
    margin: unset;
}

.input_component .custom_input,
.input_component textarea {
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    width: 100%;
    padding: .5rem 1rem;
    border: 1px solid #E2E8F0;
    border-radius: 2px;
    transition: .3s all;
}

.input_component_error_text {
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    display: grid;
    color: var(--error);
    margin: 8px 0 0;
    padding: 0;
}

.input_component .custom_input:focus,
.input_component textarea:focus {
    border: 1px solid #475569;
}

.input_component .login_input {
    background: #FFFFFF;
    border: 1px solid #E2E8F0;
    border-radius: 2px;
    padding: 12px 8px;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.input_component .prospects_input {
    border: 1px solid #E2E8F0;
    border-radius: 2px;
    padding: .5rem 1rem;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    transition: .3s;
}

.input_component .prospects_input::placeholder {
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #94A3B8;
}

.input_component .show_pass_icon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 15;
}

.input_component .social_icon {
    position: absolute;
    right: 8px;
    top: 10px;
    cursor: pointer;
}

.input_component textarea {
    resize: none;
    height: auto;
}

.input_search .custom_input {
    padding: 6px 11px 6px 42px;
}

.input_component .custom_input:disabled,
.input_component .textarea:disabled {
    border: none;
    color: #0F172A;
    background: transparent;
    cursor: not-allowed;
}

.input_component .login_input:disabled {
    border-bottom: 1px solid rgba(0, 0, 0, .3) !important;
    color: rgba(0, 0, 0, .3) !important;
    cursor: not-allowed;
}

.input_component .error, .input_component .wrong_input {
    border: 1px solid var(--error);
}

.error.custom_input, .error.textarea, .wrong_input.custom_input, .wrong_input.textarea {
    border: 1px solid var(--error);
}

.error:hover.custom_input, .error:hover.textarea, .wrong_input:hover.custom_input, .wrong_input:hover.textarea {
    border: 1px solid var(--error);
}

.error:focus.custom_input, .error:focus.textarea, .wrong_input:focus.custom_input, .wrong_input:focus.textarea {
    border: 1px solid var(--error);
}

.input_component .success_input {
    border: 1px solid var(--success);
}

.success_input.custom_input, .success_input.textarea {
    border: 1px solid var(--success);
}

.success_input:hover.custom_input, .success_input:hover.textarea {
    border: 1px solid var(--success);
}

.success_input:focus.custom_input, .success_input:focus.textarea {
    border: 1px solid var(--success);
}

.input_component .prospects_input_error, .input_component .prospects_input_error:hover, .input_component .prospects_input_error:focus {
    border: 1px solid var(--error);
}

.social_link_icon {
    position: absolute;
    left: 3px;
    top: -4px;
}

.new_social_link_icon {
    position: absolute;
    left: 8px;
    bottom: 3px;
}

.input_component_error_text {
    display: block;
}

.new_error_wrapper {
    display: grid;
    margin: 1rem 0 0;
    grid-template-columns: max-content 1fr;
    grid-gap: 12px;
    align-items: center;
}

.new_error_wrapper > span {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--new-error);
    margin: 0;
}

.entry_error {
    margin: 8px 0 0;
}

.entry_error .error_message {
    font-weight: 500;
    font-size: 12px;
}