.create_work_wrapper {
    min-height: 100vh;
    background: #FFFFFF;
    margin: -32px -2.25rem -40px -2.75rem;
    padding: 32px 2.25rem 40px 2.75rem;
}

.create_work_wrapper h2 {
    margin: 0 0 2rem;
}

.create_work_wrapper h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0 0 1rem;
}

.create_work_wrapper h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0 0 1rem;
}

.create_work_wrapper form {
    max-width: 730px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
}

.create_work_wrapper form > .head_content, .create_work_wrapper form > .body_content {
    padding: 0 0 1.75rem;
    border-bottom: 1px solid #E2E8F0;
    margin: 0 0 1.75rem;
}

.create_work_wrapper form > .head_content > .full {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    margin: 0 0 1.5rem;
}

.create_work_wrapper form > .head_content > .inline {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    grid-gap: 30px;
}

.create_work_wrapper .buttons_wrapper {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 1rem;
    justify-content: right;
}