.add_email_wrapper {
    position: relative;
}

.add_email_wrapper .title {
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #13426B;
    display: block;
    margin: 0 0 4px;
}

.targeting_add_email_wrapper .title {
    line-height: 24px;
    color: #0F172A;
}

.add_email_wrapper .content {
    width: 100%;
    border: 1px solid #E2E8F0;
    border-radius: 2px;
    padding: .5rem 1rem;
    min-height: 50px;
}

.add_email_wrapper .content .content_flow {
    display: flex;
    flex-wrap: wrap;
}

.add_email_wrapper .content .email_item {
    background: #BEDEF5;
    border-radius: 5px;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 10px;
    align-items: center;
    padding: 0 4px;
    margin: 0 10px 10px 0;
}

.add_email_wrapper .content .email_item div {
    cursor: pointer;
    display: grid;
    align-items: center;
}

.add_email_wrapper .content .email_item span, .add_email_wrapper .content input {
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #13426B;
    border: none;
    outline: none;
}

.targeting_add_email_wrapper .content {
    border: 1px solid #0F172A;
    padding: .5rem;
    min-height: 41px;
    display: flex;
    flex-wrap: wrap;
}

.add_email_wrapper .content .email_item span, .add_email_wrapper .content input {
    color: #0F172A;
}

.add_email_wrapper .content .email_item {
    background: #E2E8F0;
    margin: 0 10px 10px 0;
}

.add_email_wrapper .search_wrap {
    position: absolute;
    bottom: -152px;
    left: 0;
    right: 0;
    width: 100%;
    overflow-y: auto;
    background: #FFFFFF;
    border: 1px solid #E2E8F0;
    box-shadow: 0 0 1px rgba(15, 23, 42, 0.06), 0 10px 15px -3px rgba(15, 23, 42, 0.1), 0 4px 6px -2px rgba(15, 23, 42, 0.05);
    border-radius: 2px;
    padding: 1rem;
    height: 150px;
}

.add_email_wrapper .search_wrap span.option {
    display: block;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    padding: 0 0 12px;
    border-bottom: 1px solid #E2E8F0;
    margin: 0 0 10px;
    cursor: pointer;
}

.add_email_wrapper .search_wrap span:last-of-type {
    margin: 0;
}