.create_account_wrapper {
    min-height: 100vh;
}

.create_account_wrapper > .create_account_container {
    margin: 150px auto 0;
    width: 100%;
    max-width: 918px;
}

.create_account_wrapper > .create_account_container > h2 {
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    margin: 0 0 8px;
}

.create_account_wrapper > .create_account_container > p {
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0 0 20px;
}

.create_account_wrapper > .create_account_container > .content {
    background: #F8FAFC;
    border-radius: 8px;
    padding: 3.5rem 6rem;
}

.create_account_wrapper > .create_account_container > .content h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    margin: 0 0 1.5rem;
}

.create_account_wrapper > .create_account_container > .content .block {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 0 0 2rem;
    border-bottom: 1px solid #E2E8F0;
    margin: 0 0 2rem;
}

.create_account_wrapper > .create_account_container > .content .buttons_wrapper {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: center;
}

.create_account_wrapper > .create_account_container > .content .buttons_wrapper button {
    width: 5rem;
    padding: 6px 0;
    text-transform: unset;
}