
.raphael-group {
    display: none;
}

g[class^="raphael-group-vPCHuMAf"] {
    display: none !important;
}

.apexcharts-legend.position-bottom.apexcharts-align-center, .apexcharts-legend.position-top.apexcharts-align-center {
    display: none;
}

.apexcharts-bar-area {
    width: 10px;
}

.fc__tooltip.fusioncharts-div {
    font-family: "Inter", sans-serif !important;
    width: 250px;
    height: 149px;
    background: #f3f3f3;
    /*max-width: 200px!important;*/

}

.tooltip_opportunities {
    display: grid;
    grid-gap: 5px;
    border-bottom: 1px solid #E2E8F0;
}

.funnel_type {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #475569;
}

.funnel_label {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px;
    color: var(--gitgo-blue);
}

.tooltip_block {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    height: 150px;
    width: 250px;
    border-radius: 5px;
    box-shadow: 2px 2px 6px -4px #999;
    padding: 15px;
}

.tooltip_block span {
    padding: 10px;
}

.count_block_tooltip {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}

.count_number {
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 39px;
    color: var(--gitgo-blue)
}

.record_count {
    border-left: 1px solid #475569;
    padding-left: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #475569;
    height: 10px;
}


.header_tooltip {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #475569;
}

.footer_percent {
    display: flex;
    border-top: 1px solid #E2E8F0;
}

#zingchart-react-0-menu {
    display: none !important;
}

#my_Chart {
    width: 400px !important;
    height: 320px !important;
}

#my_Chart-menu {
    display: none !important;
}

#my_Chart-top {
    position: initial;
}

.funnel-block {
    display: grid;
    justify-content: center;
}

.zc-rel {
    position: relative;
    left: 6px;
}

.blocks-section {
    margin-top: 20px;
    display: grid;
}

.block {
    padding-bottom: 5px;
    display: flex;
    grid-gap: 40px;
    justify-content: space-between;
    align-items: self-end;
    border-bottom: 1px solid #E2E8F0;
}

.block .name-of-category {
    width: 40px;
    height: 40PX;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #475569;
}

.center-funnel {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.center-funnel .value-count {
    position: absolute;
    z-index: 100;
    /* inset: 25% 47%; */
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.tooltip-funnel {
    padding: 16px;
    display: none;
    visibility: hidden;
    text-align: center;
    position: absolute;
    left: 70%;
    top: -20%;
    background: #FFFFFF;
    border: 1px solid #F8FAFC;
    box-sizing: border-box;
    box-shadow: 0 0 1px rgba(15, 23, 42, 0.06), 0 20px 25px -5px rgba(15, 23, 42, 0.1), 0px 10px 10px -5px rgba(15, 23, 42, 0.04);
    border-radius: 8px;
    z-index: 110;
    width: 244px;
    height: 150px;
    opacity: 0.1;
    transition: opacity 0.3s;

}
.modal-tool{
    display: grid;
    text-align: left;  transition: height 2s,visibility 0s 1.5s;

}
.center-funnel:hover .tooltip-funnel {
    visibility: visible;
    display: grid;
    opacity: 1;
    /*transition: all 0.5s ease-out;*/


}
.tooltip-funnel .header-text-tooltip{
    display: flex;
    flex-direction: column;
}
.tooltip-funnel .header-text-tooltip .border-modal{
    padding-top: 8px;
    border-bottom: 1px solid #E2E8F0 ;
}
.center-funnel .title-modal-text{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #475569;
}
.center-funnel .title-modal-value{
    display: block;
    margin-top: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #01426A;
}
.center-funnel .title-count-value{
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 39px;
    color: #01426A;
}
.center-funnel .footer-tooltip-counter{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}
.center-funnel .footer-tooltip-counter .right-count-side .record-count{
    border-left: 1px solid #475569;
    padding-left: 5px;
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #475569;
}
.center-funnel .footer-tooltip-counter .right-count-side {
    display: grid;
    align-self: center;
}
@media (max-width: 1370px) {
    #my_Chart {
        width: 400px !important;
        height: 320px !important;
    }
}

@media (max-width: 1220px) {
    #my_Chart {
        width: 400px !important;
        height: 320px !important;
    }

}