.services_wrapper .services_title span {
    display: block;
    margin-top: 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #475569;
}
.services_wrapper .title_of_blocks_header {
    margin-top: 56px;
    display: grid;
    text-align: center;
}
.title_of_blocks_header .title_of_blocks {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    color: #475569;
}
.services_wrapper .block_wrapper {
    margin-top: 32px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
}

.services_wrapper .block_wrapper .text_block {
    display: grid;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    text-align: center;
    background: #FFFFFF;
    border-radius: 8px;
    max-width: 385px;
    height: 586px;
    padding-bottom: 32px;
}

.services_wrapper .block_wrapper .text_block h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    color: #01426A;
}

.services_wrapper .block_wrapper .text_block .color_header_of_block {
    padding: 32px 25px;
    /* height: 150px; */
    display: grid;
    text-align: left;
}

.services_wrapper .block_wrapper .text_block .content_of_block {
    text-align: left;
    padding: 25px;
}

.services_wrapper .block_wrapper .text_block .color_header_of_block .sub_text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #0F172A;
}

.services_wrapper .block_wrapper .text_block .content_of_block span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #475569;
}
.services_wrapper .block_wrapper .text_block .block_button{
    width: 160px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: white;
    padding: 12px 24px;
    box-shadow: 0 17px 19px rgba(39, 98, 249, 0.1);
    /* border: 1px solid #01426A; */
    background-color: #01426A;
}

.services_wrapper .block_wrapper .text_block .block_button:hover{
    width: 160px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: white;
    padding: 12px 24px;
    box-shadow: 0 17px 19px rgba(39, 98, 249, 0.1);
    /* border: 1px solid #01426A; */
    background-color: white;
}

