.workspace_wrapper .title {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    align-items: center;
    margin: 0 0 2rem;
}

.workspace_wrapper .workspace_search {
    margin: 0 0 1rem;
}

.workspace_wrapper .workspace_content, .prospects_wrapper .prospects_content {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
}

.workspace_wrapper .workspace_content .workspace_container, .prospects_wrapper .prospects_content .prospects_container {
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    border-radius: 8px;
    overflow-x: auto;
}

.workspace_wrapper .workspace_content .workspace_container > .head {
    display: grid;
    padding: 17px 2.75rem 16px 4rem;
    border-bottom: 1px solid #E2E8F0;
    grid-template-columns:  minmax(166px, 1fr) minmax(106px, 1fr) minmax(124px, 1fr) minmax(84px, 1fr) minmax(115px, 1fr) minmax(80px, 1fr) minmax(151px, 1fr) minmax(87px, 1fr);
    align-items: center;
    grid-gap: 1rem;
    width: 100%;
}

.workspace_wrapper .workspace_content .workspace_container > .head .item {
    display: grid;
    padding: 6px 0 10px;
    align-items: center;
    grid-template-columns: repeat(2, max-content);
    cursor: pointer;
}

.workspace_wrapper .workspace_content .workspace_container > .head .item span, .prospects_wrapper .prospects_content .prospects_container > .head .item span {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    text-transform: capitalize;
    word-break: break-word;
    color: #0F172A;
}
.checkbox {
    border: 2px solid #01426A;
    border-radius: 2px;
    opacity: 1!important;
    width:  16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center
}

.workspace_wrapper .workspace_content .workspace_container > .container_body > .item {
    display: grid;
    padding: 17px 2.75rem 16px 1.5rem;
    border-bottom: 1px solid #E2E8F0;
    grid-template-columns: 20px 24px minmax(166px, 1fr) minmax(106px, 1fr) minmax(124px, 1fr) minmax(84px, 1fr) minmax(115px, 1fr) minmax(80px, 1fr) minmax(151px, 1fr) minmax(87px, 1fr);
    align-items: center;
    grid-gap: 1rem;
    width: 100%;
}

.workspace_wrapper .workspace_content .workspace_container > .container_body > .item:last-of-type {
    padding: 17px 2.75rem 17px 1.5rem;
    border-bottom: none;
}

.workspace_wrapper .workspace_content .workspace_container > .container_body > .item .popup_wrap, .prospects_wrapper .prospects_content .prospects_container > .container_body .item .popup_wrap {
    position: relative;
    display: grid;
    align-items: center;
    justify-content: end;
}

.workspace_wrapper .workspace_content .workspace_container > .container_body > .item .popup_wrap svg, .prospects_wrapper .prospects_content .prospects_container > .container_body .item .popup_wrap svg {
    cursor: pointer;
}

.workspace_wrapper .workspace_content .workspace_container > .container_body > .item .popup_wrap .popup_content, .prospects_wrapper .prospects_content .prospects_container > .container_body .item .popup_wrap .popup_content, .contacts_popup_wrapper .content .body .item .popup_content {
    position: absolute;
    top: -82px;
    left: 24px;
    background: #FFFFFF;
    border: 1px solid #E2E8F0;
    box-sizing: border-box;
    box-shadow: 0 0 1px rgba(15, 23, 42, 0.06), 0 10px 15px -3px rgba(15, 23, 42, 0.1), 0 4px 6px -2px rgba(15, 23, 42, 0.05);
    border-radius: 2px;
    padding: 4px;
    width: 194px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4px;
    align-items: center;
}

.prospects_wrapper .prospects_content .prospects_container > .container_body .item .popup_wrap .popup_content, .contacts_popup_wrapper .content .body .item .popup_content {
    left: unset;
    right: 1.5rem;
    top: -74px;
}

.contacts_popup_wrapper .content .body .item .popup_content {
    right: 0;
    top: -96px;
    width: 224px;
}

.workspace_wrapper .workspace_content .workspace_container > .container_body > .item .popup_wrap .popup_content span, .prospects_wrapper .prospects_content .prospects_container > .container_body .item .popup_wrap .popup_content span, .contacts_popup_wrapper .content .body .item .popup_content span {
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    padding: 8px;
    transition: .3s;
    border-radius: 2px;
    display: block;
    cursor: pointer;
    opacity: 1;
}

.workspace_wrapper .workspace_content .workspace_container > .container_body > .item .popup_wrap .popup_content span.delete, .prospects_wrapper .prospects_content .prospects_container > .container_body .item .popup_wrap .popup_content span.delete, .contacts_popup_wrapper .content .body .item .popup_content span.delete {
    color: #F26464;
}

.workspace_wrapper .workspace_content .workspace_container > .container_body > .item .popup_wrap .popup_content span:hover, .prospects_wrapper .prospects_content .prospects_container > .container_body .item .popup_wrap .popup_content span:hover, .contacts_popup_wrapper .content .body .item .popup_content span:hover {
    background: #F8FAFC;
}

.workspace_wrapper .workspace_content .workspace_container > .container_body > .item p, .prospects_wrapper .prospects_content .prospects_container > .container_body .item p {
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    color: var(--gitgo-blue);
    font-weight: 600;
}

.prospects_wrapper .prospects_content .prospects_container > .container_body .item p {
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
}

.workspace_wrapper .workspace_content .workspace_container > .container_body > .item span, .prospects_wrapper .prospects_content .prospects_container > .container_body .item span:not(.status) {
    font-size: 14px;
    line-height: 21px;
    color: #475569;
    opacity: 0.5;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    text-transform: capitalize;
}
/*prospects-status-container*/
.workspace_wrapper .workspace_content .workspace_container > .container_body > .item .open_workspace {
    padding: 1px 11px;
    cursor: pointer;
    background: rgba(1, 66, 106, 0.1);
    border-radius: 4px;
    opacity: 1;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 8px;
    width: max-content;
}

.open_workspace {
    display: flex;
    cursor: pointer;
}

.workspace_wrapper .workspace_content .workspace_container > .container_body > .item .open_workspace span {
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    opacity: 1;
}

.workspace_wrapper .pagination_wrapper {
    margin: 1.5rem 0 10rem;
}

@media (max-width: 1440px) {
    .workspace_wrapper .workspace_content .workspace_container > .container_body > .item, .workspace_wrapper .workspace_content .workspace_container > .head {
        width: fit-content;
        width: -moz-fit-content;
    }
}