.change-password-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 489px;
    max-height: 70vh;
    background: #fff;
    border-radius: 8px;
    padding: 24px 65px;
    border: 1px solid #CBD5E1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.change-password-title-box {
    width: 306px;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;
    margin-bottom: 24px;
}

.change-password-title-box > span {
    font-size: 20px;
    font-weight: 600;
    color: #0F172A;
    display: flex;
    text-align: center;
}

.change-password-buttons-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.change-password-buttons-container button {
    font-size: 14px;
    font-weight: 700;
    border-radius: 2px;
}

.change-password-buttons-container button:first-child {
    background-color: #FFFFFF;
    border: 1px solid var(--gitgo-blue);
    height: 36px;
    padding: 0 16px;
    color: var(--gitgo-blue);
}

.change-password-buttons-container button:last-child {
    background-color: var(--gitgo-blue);
    color: #FFFFFF;
    border: 1px solid var(--gitgo-blue);
    padding: 0 12px;
}