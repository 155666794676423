.page_not_found_wrapper {
    display: grid;
    width: 100%;
    height: 100vh;
    top: 0;
    min-height: 400px;
    right: 0;
    left: 0;
    bottom: 0;
    background: #FFFFFF;
}

.forward_block_page {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.forward_block_page .content {
    background: var(--gitgo-blue);
    border-radius: 11px;
    padding: 73px 0 78px;
    text-align: center;
    width: 635px;
}

.forward_block_page p {
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #FFFFFF;
    margin: 0 0 60px;
}

.return_button_page {
    display: grid;
    justify-content: center;
    align-items: center;
}

.return_button_page button {
    margin: 58px 0 0;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 8px;
    padding: 12px 15px;
}