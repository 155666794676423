.contacts-container {
    width: 100%;
}

.notes-text {
    color: rgb(148, 163, 184);
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.p-style{
    border-radius:4px;
    padding: 8px 10px;
    width: max-content;
}
.notes-text-full {
    color: rgb(148, 163, 184);
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* -webkit-box-orient: vertical; */
    overflow: hidden;
    text-overflow: ellipsis;
}


.title-page {
    font-size: 36px;
    font-weight: 600;
    color: var(--gitgo-blue);
}

.contacts-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contacts-items-container {
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #CBD5E1;
}

.contacts-button-new {
    padding: 0 20px;
    height: 36px;
    border-radius: 2px;
    background-color: var(--gitgo-blue);
    color: #FFFFFF;
}

.contacts-button-new button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.contacts-button-new button img {
    width: 24px;
    height: 24px;
}

.contacts-button-new button span {
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF;
    margin-left: 4px;
}

.contacts-button-dropdown-container {
    position: relative;
}

.contacts-button-dropdown {
    background-color: #FFFFFF;
    color: #000000;
    width: 275px;
    position: absolute;
    right: -20px;
    top: 6px;
    z-index: 9;
    padding: 4px;
    border-radius: 2px;
    border: 1px solid #E2E8F0;
    box-shadow: 0 0 1px rgba(15, 23, 42, 0.06), 0 10px 15px -3px rgba(15, 23, 42, 0.1), 0 4px 6px -2px rgba(15, 23, 42, 0.05);
    transition: .3s;
}

.contacts-button-dropdown button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 8px;
    color: #0F172A;
    font-size: 16px;
    font-weight: 400;
    border-radius: 2px;
}

.contacts-button-dropdown button a {
    display: block;
    width: 100%;
    text-align: left;
}

.contacts-button-dropdown p {
    color: #0F172A;
    font-size: 16px;
    font-weight: 400;
}

.contacts-button-dropdown button:hover {
    background-color: #F8FAFC;
}

.plus-icon {
    width: 24px;
    height: 24px;
}

.contacts-search-container {
    width: 445px;
    height: 36px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 2px;
    padding-right: 16px;
    border: 1px solid #E2E8F0;
    background-color: #FFFFFF;
    margin-top: 40px;
    margin-bottom: 16px;
}

.contacts-search-container button {
    width: 24px;
    height: 24px;
    background-color: transparent;
    display: flex;
    align-items: center;
    padding: 0;
}

.contacts-search-container input {
    height: 100%;
    width: 100%;
    padding-left: 14px;
    font-size: 16px;
    font-weight: 400;
    border: none;
}

.contacts-contact-name {
    width: 160px;
}

.contacts-email {
    width: 180px;
}

.contacts-prospect-name {
    width: 140px;
}

.contacts-job-title {
    width: 100px;
}

.contacts-phone {
    width: 136px;
}

.contacts-create-data {
    width: 130px;
}

.contacts-last-engaged {
    width: 120px;
}

.contacts-items {
    display: flex;
    align-items: center;
    height: 56px;
    padding-left: 16px;
}

.contacts-dots-vertical {
    padding-left: 16px;
    display: flex;
    align-items: center;
}

.contacts-dots-vertical button {
    padding: 0;
}

.contacts-items-line {
    width: 100%;
    height: 1px;
    background-color: #E2E8F0;
}

.contacts-items-line .item {
    border-bottom: 1px solid #E2E8F0;
}

.contacts-container-content {
    max-height: 653px;
    overflow: auto;
}


.contacts-container-content > div:not(.contacts-items-content) .item {
    border-top: 1px solid #E2E8F0;
    min-inline-size: max-content;
}

.contacts-container-content > div:not(.contacts-items-content) .item svg {
    cursor: pointer;
}

.contacts-container-content > div:not(.contacts-items-content) .item span:nth-child(3) {
    display: flex;
    justify-content: center;
}

.contacts-items-span {
    font-weight: 400;
    font-size: 16px;
    color: #475569;
    opacity: 0.5;
}

.contacts-items-span.name {
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #01426A;
    opacity: 1;
}

.contacts-prospects-items-span {
    color: #475569;
    font-size: 16px;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
    display: block;
    cursor: pointer;
}

.contacts-items-span:nth-child(3) {
    cursor: pointer;
}

.contacts-items-span:first-child {
    cursor: pointer;
    color: var(--gitgo-blue);
    font-size: 16px;
    font-weight: 600;
    opacity: 1;
}

.contacts-items-span:nth-child(4) {
    opacity: 1;
}

#scroll-bar::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
}

#scroll-bar::-webkit-scrollbar-thumb {
    background: rgba(203, 213, 225, 1);
    border-radius: 10px;
}

#scroll-bar::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.0);
}

.contact-menu-nav {
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contacts-contact-name-item {
    font-size: 16px;
    font-weight: 600;
    color: #475569;
}

.contacts-email-item {
    font-size: 16px;
    font-weight: 400;
    color: #475569;
    opacity: 0.5;
}

.contacts-prospect-name-item {
    font-size: 14px;
    font-weight: 400;
    color: #475569;
}

.contacts-job-title-item {
    font-size: 16px;
    font-weight: 400;
    color: #475569;
}

.contacts-phone-item {
    font-size: 16px;
    font-weight: 400;
    color: #475569;
    opacity: 0.5;
}

.contacts-create-data-item {
    font-size: 16px;
    font-weight: 400;
    color: #475569;
    opacity: 0.5;
}

.contacts-last-engaged-item {
    font-size: 16px;
    font-weight: 400;
    color: #475569;
    opacity: 0.5;
}

.contacts-menu-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 3000;
    width: 194px;
    border-radius: 2px;
    border: 1px solid #E2E8F0;
    background-color: #FFFFFF;
    padding: 4px;
}

.contacts-menu-button-container {
    width: 100%;
    height: 40px;
    background-color: #FFFFFF;
    display: flex;
}

.contacts-menu-button-container button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 8px;
}

.contacts-menu-button-style {
    background-color: transparent;
}

.contacts-menu-button-style:hover {
    background-color: #F8FAFC;
    border-radius: 2px;
}

.dots-cont img {
    position: relative;
    z-index: 500;
}

.contacts-new-button-container {
    display: flex;
    align-items: center;
}

.contacts-new-buttons-container {
    position: absolute;
    z-index: 3000;
    display: flex;
    flex-direction: column;
    width: 194px;
    border-radius: 2px;
    border: 1px solid #E2E8F0;
    background-color: #FFFFFF;
    padding: 4px;
    margin-top: 39px;
}

.contacts-new-button {
    width: 186px;
    height: 40px;
}

.contacts-new-popup-button {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 8px;
    background-color: #FFFFFF;
}

.contacts-new-popup-button:hover {
    background-color: #F8FAFC;
    border-radius: 2px;
}

.contacts-button-and-popup-container {
    width: 195px;
    display: flex;
    justify-content: flex-end;
}

.contacts-container-content .item {
    display: grid;
    grid-template-columns: 2.5% 10.1% 15.1% 11.6% 11.6% 10.6% 10.6% 11.7% 9.4% 1.7%;
    align-items: center;
    grid-gap: 0.65%;
    padding: 0 1.5%;
    height: 57px;
    font-size: 14px;
    width: auto;
}

.contacts-items-content {
    display: grid;
    /* padding: 5px 0 0 3rem; */
    padding: 0 2% 0 4.5%;
    grid-template-columns: 10.5% 15.8% 12.1% 12.1% 11.1% 11.1% 12.3% 8.8%;
    align-items: center;
    grid-gap: 0.65%;
    width: fit-content;
    font-size: 14px;
    font-weight: 600;
    width: auto;
}
@media (max-width: 1550px) {
    .contacts-container-content .item {
        grid-template-columns: 30px 120px 180px 135px 135px 125px 125px 125px 100px 30px;
        grid-gap: 8px;
        padding: 0 1.5%;
    }
    
    .contacts-items-content {

        padding: 0 20px 0 55px;
        grid-template-columns:  120px 180px 135px 135px 125px 125px 125px 100px;
        grid-gap: 8px;
    }
}

.contacts-items-content .item {
    display: grid;
    padding: 6px 0 10px;
    grid-gap:5px;
    align-items: center;
    grid-template-columns: repeat(2, max-content);
    cursor: pointer;
}

.popup-content-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 194px;
    padding: 4px;
    background-color: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #E2E8F0;
    right: 30px;
    bottom: -30px;
    z-index: 10;
}

.popup-content-menu button {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px;
    font-size: 16px;
    font-weight: 400;
    color: #0F172A;
}

.popup-content-menu button span {
    display: flex;
    justify-content: flex-start;
}

.contacts-container-content div:not(.contacts-items-content .popup_prospect_content_item) .item span {
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
    display: block;
}

.popup-content-menu button {
    font-size: 16px;
    font-weight: 400;
    color: #0F172A;
}

.popup-content-menu button:last-child {
    color: #F26464;
}

.tooltip {
    position: relative;
}

.tooltip .tooltiptext {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 5px;
    border-radius: 6px;
    position: absolute;
    z-index: 4000;
    top: 100%;
    left: 0;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.popup-content-menu button:hover {
    background-color: #F8FAFC;
}

.button_default_plus.contacts {
    box-shadow: none;
}

.assign-contacts-background {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3550;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

.assign-modal-window {
    padding: 24px;
    position: fixed;
    max-width: 817px;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3100;
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    box-sizing: border-box;
    border-radius: 8px;
    max-height: 75vh;
    height: 75vh;
}

.assign-modal-window .modal-header span {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    color: #475569;
}

.assign-modal-window .modal-header {
    display: flex;
    padding-bottom: 25px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E2E8F0;
}

.assign-modal-window .modal-header .modal-buttons {
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    grid-gap: 10px;
}

.assign-modal-window .modal-header .modal-buttons .assign-button {
    background: #01426A;
    border-radius: 2px;
    color: #ffffff;
    padding: 6px 16px;
    /*border: 1px solid #01426A;*/
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
}

.assign-modal-window .modal-header .modal-buttons .close-button {
    background: #ffffff;
    border: 1px solid #01426A;
    border-radius: 2px;
    padding: 6px 16px;
    color: #01426A;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
}

.assign-modal-window .container-assign {
    display: grid;
}

.assign-modal-window .container-assign .assign-line {
    border-bottom: 1px solid #E2E8F0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 19px;
    transition: 0.4s;
}
.assign-modal-window .container-assign .assign-line.checked {
    background: #E2E8F0;
    border: 1px solid #acc0db;
}

.assign-modal-window .container-assign .assign-line .scope-button {
    padding: 4px 8px;
    background: rgba(1, 66, 106, 0.1);
    border-radius: 2px;
    width: 90px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #01426A;

}

.assign-modal-window .container-assign .assign-line .right-info-side {
    display: grid;
    grid-gap: 50px;
    align-items: center;
    grid-template-columns: max-content 1fr;
}

.assign-modal-window .container-assign .assign-line .right-info-side span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #01426A;
}

.assign-modal-window .container-assign .assign-line .right-info-side .check-circle {
    cursor: pointer;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid #01426A;
    transition: 0.4s;
}

.assign-modal-window .container-assign .assign-line .right-info-side .check-circle.checked {
    background-color: #01426A;
    border: 2px solid #01426A;
}

@media (max-width: 1521px) {
    .popup-content-menu {
        right: 4px;
    }
}

/* @media (max-width: 1355px) {
    .contacts-container-content > div:not(.contacts-items-content) .item {
        width: fit-content;
        grid-template-columns: 20px minmax(150px, 1fr) minmax(152px, 1fr)  minmax(145px, 1fr) minmax(130px, 1fr)  minmax(116px, 1fr)  minmax(115px, 2fr) minmax(140px, 1fr) 25px;

    }
} */


.checkbox {
    border: 2px solid #01426A;
    border-radius: 2px;
    opacity: 1 !important;
    width: 16px;
    height: 16px;
    display: flex !important;
    align-items: center;
    justify-content: center
}

@media (min-width: 1400px) {
    /* .contacts-container-content > div:not(.contacts-items-content) .item {
        display: grid;
        grid-template-columns: 20px minmax(152px, 1fr) minmax(177px, 1fr)  minmax(166px, 1fr) minmax(154px, 1fr)  minmax(145px, 1fr)  minmax(110px, 1fr) minmax(140px, 1fr) 40px;

    }

    .contacts-items-content {
        display: grid;
        align-items: center;
        grid-template-columns: 5fr 5fr 5fr 5fr 5fr 5fr 5fr 1fr;
        grid-gap: 1rem;
        width: -moz-fit-content;
        font-size: 14px;
        font-weight: 600;
        width: 100%;
        padding: 0 2.75rem 0 50px;
    } */
}

.contacts-nav-container {
    display: flex;
    margin-bottom: 16px;
}

.contacts-nav-contacts {
    font-size: 16px;
    font-weight: 400;
    color: #475569;
    cursor: pointer;
}

.contacts-nav-name {
    font-size: 16px;
    font-weight: 700;
    color: #475569;
}

.contacts-edit-class {
    width: 87px;
    height: 36px;
    border: 2px solid var(--gitgo-blue);
    font-size: 14px;
    font-weight: 700;
    color: var(--gitgo-blue);
}

.contacts-user-name-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 31px;
}

.contacts-details-text {
    font-size: 18px;
    font-weight: 500;
    color: #475569;
    margin-bottom: 29px;
}

.contacts-details-item-title {
    font-size: 12px;
    font-weight: 700;
    color: var(--gitgo-blue);
    margin: 25px 0 10px 0;
}

.contacts-details {
    display: flex;
    flex-direction: column;
}

.contacts-details-container {
    display: grid;
    grid-template-columns: 255px 255px;
    grid-gap: 20px;
    margin-top: 16px;
}

.contacts-line-container {
    margin: 24px 0 61px 0;
    padding: 0 0 25px 0;
    border-top: 1px solid #E2E8F0;
    border-bottom: 1px solid #E2E8F0;
}

.contacts-line-container .contacts-user-info-line:first-child {
    margin: 24px 0 25px 0;
}

.contacts-line-container .contacts-user-info-line:last-child {
    margin-top: 25px;
    margin-bottom: 61px;
}

.contacts-details-content {
    display: grid;
    grid-gap: 20px;
}

.contacts-user-info-line {
    width: 540px;
    height: 1px;
    background-color: #E2E8F0;
}

.contacts-details-prospects {
    display: flex;
    flex-direction: column;
    width: 540px;
}

.contacts-delete-user {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.contacts-delete-button {
    display: flex;
    align-items: center;
}

.popup-content-menu-item {
    font-size: 16px;
    font-weight: 400;
    color: #0F172A;
}

.popup-content-menu-item-delete {
    font-size: 16px;
    font-weight: 400;
    color: red;
}

.activity-container button {
    width: 117px;
    height: 36px;
    background-color: var(--gitgo-blue);
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px 0 16px;
}

.activity-container {
    display: flex;
    justify-content: space-between;
}

.activity-content-container {
    border: 1px solid #CBD5E1;
}

.activity-content {
    display: grid;
    grid-template-columns: 350px 1fr;
    grid-gap: 130px;
    padding: 24px 24px 16px 24px;
}

.contacts-user-notes {
    display: flex;
    flex-direction: column;
}

.contacts-user-notes-block {
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    color: #475569;
}

.contacts-user-notes-block span {
    margin-bottom: 4px;
}

.contacts-user-notes-block textarea {
    width: 100%;
    resize: none;
    padding: 16px;
}

.contacts-call-logged {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.contacts-call-logged span {
    margin-left: 16px;
    font-size: 14px;
    font-weight: 600;
    color: #475569;
}

.contacts-textarea {
    border: 1px solid #E2E8F0;
    height: 231px;
}

.contacts-activity-buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.contacts-activity-buttons-container button:first-child {
    width: 80px;
    height: 36px;
    border: 1px solid var(--gitgo-blue);
    color: var(--gitgo-blue);
    font-size: 14px;
    font-weight: 700;
    margin-right: 8px;
}

.contacts-activity-buttons-container button:last-child {
    width: 80px;
    height: 36px;
    background-color: var(--gitgo-blue);
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 700;
    margin-left: 8px;
}

.contact-user-calendar {
    width: 325px;
    height: 41px;
    background-color: #FFFFFF;
    border: 1px solid #E2E8F0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px 0 0;
    margin-top: 4px;
}

.contact-user-calendar-container span:first-child {
    font-size: 14px;
    font-weight: 400;
    color: #0F172A;
}

.activity-element {
    display: grid;
    grid-template-columns: 480px 1fr;
    border-top: 1px solid #E2E8F0;
    padding: 16px 6px 16px 24px;
}

.contacts-date-test {
    font-size: 14px;
    font-weight: 400;
    color: #94A3B8;
}

.activity-element-text-container {
    width: 100%;
    display: flex;
}

.activity-element-text-container textarea {
    width: 100%;
    padding: 0 20px 0 12px;
    border: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: 400;
    color: #94A3B8;
    resize: none;
}

.activity-element-text-container span {
    font-size: 14px;
    font-weight: 600;
    color: #475569;
}

.react-datepicker__input-container input {
    width: 100%;
    height: 41px;
    border: 0;
    background-color: transparent;
    padding-left: 12px;
    cursor: pointer;
}

.contacts-user-prospect {
    height: 29px;
    padding: 0 8px 0 8px;
    background-color: rgba(150, 200, 237, 0.2);
    color: #475569;
    border-radius: 2px;
}

.contacts-user-log-dropdown {
    width: 194px;
    top: -12px;
}

.contacts-user-log-dropdown img {
    margin-right: 8px;
}

.activity-element-container {
    max-height: 250px;
    overflow-y: scroll;
}

.activity-element-container::-webkit-scrollbar {
    width: 6px;
}

.activity-element-container::-webkit-scrollbar-track {
    background-color: transparent;
}

.activity-element-container::-webkit-scrollbar-thumb {
    background: #CBD5E1;
    border-radius: 10px;
}

.activity-element-container::-webkit-scrollbar-thumb:hover {
    background: #CBD5E1;
}

.contacts-user-log-dropdown button:hover svg path {
    stroke: var(--gitgo-blue);
}

.contacts-user-log-dropdown button p {
    margin-left: 16px;
    margin-top: 2px;
}

.contacts-button-dropdown button {
    transition: .3s;
}

.contact-prospect-chosen-container {
    display: flex;
    flex-wrap: wrap;
}

.contact-prospect-chosen-item {
    background-color: rgba(150, 200, 237, 0.2);
    display: flex;
    align-items: center;
    border-radius: 2px;
    height: 32px;
    padding: 0 8px 0 8px;
    margin: 8px 8px 0 0;
}

.contacts-details .contact-prospect-chosen-item span {
    font-size: 14px;
}

.contacts-details .contact-prospect-chosen-item svg {
    display: none;
}

.contact-prospect-chosen-item span {
    margin: 0 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #0F172A !important;
}

.contact-prospect-chosen-item svg {
    cursor: pointer;
}

.contacts-container-content > div:not(.contacts-items-content) .item .popup_wrap {
    position: relative;
}

.popup-content-menu-item {
    color: #0F172A;
    opacity: 1;
    font-size: 16px;
    font-weight: 400;
}

.popup_prospect_content {
    position: absolute;
    width: 200px;
    border: 1px solid #E2E8F0;
    background-color: #FFFFFF;
    z-index: 1000;
    padding: 4px;
}

.popup_prospect_content_item {
    width: 100%;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: flex-start;
}

.popup_prospect_content_item span {
    font-size: 16px;
    font-weight: 400;
    color: #475569;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 12px 8px;
}

.popup_prospect_content_item span:hover {
    background-color: #F8FAFC;
    width: 100%;
}