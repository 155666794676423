.new_opportunity_wrapper > .navigation {
    display: grid;
    grid-template-columns: repeat(5, max-content);
    align-items: center;
    grid-gap: 8px;
    margin: 0 0 1rem;
}

.new_opportunity_wrapper > .title_wrap {
    margin: 0 auto 2.5rem;
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    justify-content: space-between;
    max-width: 730px;
}

.new_opportunity_wrapper > .title_wrap button {
    background: transparent;
}

.content-custom {
    background: white;
    border-radius: 8px;
    border: 1px solid #CBD5E1;
    grid-gap: 0px!important;
}

.basic_info_custom {
    border: none;
}

.notes-custom { 
    margin: 0px 24px 24px 24px;
}

.notes-custom span {
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    text-transform: capitalize;
    color: #94A3B8;
}

.notes-style {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    margin: 0 0 12px;
}

.notes-label {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    margin: 0 0 .25rem;
}

.new_opportunity_wrapper > .content {
    max-width: 730px;
    margin: 0 auto 1.5rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
}

.new_opportunity_wrapper > .content > .basic_info, .new_opportunity_wrapper > .content > .additional_info {
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    border-radius: 8px;
    padding: 1.5rem;
}

.ant-picker:hover, .ant-picker-focused {
    border: 1px solid #475569;
    box-shadow: none;
}

.new_opportunity_wrapper > .content > .basic_info .inputs {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(2, 232px);
    justify-content: space-between;
    margin: 0 0 1.5rem;
}

.new_opportunity_wrapper > .content > .edit_basic_info {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(2, 232px);
    justify-content: space-between;
    margin: 0 0 10px;
}

.new_opportunity_wrapper > .content > .edit_basic_info .non_editable h6 {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    margin: 0 0 12px;
}

.new_opportunity_wrapper > .content > .edit_basic_info .non_editable span {
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    text-transform: capitalize;
    color: #475569;
}

.new_opportunity_wrapper > .content > .basic_info .full_width {
    grid-column: 1/-1;
}

.new_opportunity_wrapper > .content > .basic_info .buttons_wrapper, .new_opportunity_wrapper > .content > .additional_info .buttons_wrapper {
    justify-content: space-between;
}

.new_opportunity_wrapper > .content > .basic_info .buttons_wrapper button, .new_opportunity_wrapper > .content > .additional_info .buttons_wrapper button {
    width: 100px;
}

.delete_travel_opportunity {
    margin: 1.5rem auto 2.5rem;
    max-width: 730px;
    border: 1px solid #F26464;
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1.5rem 0;
    cursor: pointer;
}

.delete_travel_opportunity h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #F26464;
}

.delete_travel_opportunity span {
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
}