.changed_confirmed_popup_un_active,
.changed_confirmed_popup {
    position: fixed;
    display: grid;
    justify-content: center;
    left: 0;
    right: 0;
    z-index: 10000;
    bottom: 16px;
    opacity: 0;
    visibility: hidden;
    transition: .4s;
}

.changed_confirmed_popup {
    opacity: 1;
    visibility: visible;
}

.changed_confirmed_popup_un_active .changing_confirmed span,
.changed_confirmed_popup .changing_confirmed span {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    font-family: 'Playfair Display', sans-serif;
    letter-spacing: 0.02em;
    color: #333333;
    padding-left: 16px;
    margin: 0;
}

.changed_confirmed_popup_un_active .changing_confirmed img,
.changed_confirmed_popup .changing_confirmed img {
    height: 28px;
}

.changed_confirmed_popup_un_active .changing_confirmed,
.changed_confirmed_popup .changing_confirmed {
    background: #fff;
    display: flex;
    align-items: center;
    padding: 14px 20px;
    box-shadow: 4px 4px 32px rgba(155, 155, 155, 0.3);
    border-radius: 2px;
}

.changed_confirmed_popup_un_active {
    visibility: hidden;
}