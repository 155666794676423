.page_block_all{
    display: flex;
    flex-direction: row;
    gap: 10px;
    background: #FFFFFF;
    border: 1px solid #E2E8F0;
    padding: 12px 16px;
    border-radius: 2px;
    transition-duration: 1s;
}
.page_block_all span{
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    transition-duration: 1s;
}
.page_block_all .drop_block{
    display: flex;
    flex-direction: row;
    gap: 10px;
    transition-duration: 1s;
    overflow: hidden;
}
.cursor{
    cursor: pointer;
}