.prospects_wrapper .prospects_content .prospects_container > .head_opportunities {
    padding: 17px 50px 16px 60px;
    /* grid-template-columns: 200px minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(122px, 1fr) minmax(85px, 1fr) minmax(90px, 2fr) minmax(251px, 1fr) minmax(107px, 1fr) minmax(231px, 1fr); */
    grid-gap: 22px;
    grid-template-columns: 3fr 3fr 2fr 2fr 2fr;
    display: grid;
    border-bottom: 1px solid #E2E8F0;
    align-items: center;
    width: 100%;
    width: -moz-fit-content;
}
.prospects_wrapper .prospects_content .prospects_container > .container_body .item_opportunities {
    padding: 17px 50px 16px 20px;
    grid-template-columns: 20px 3fr 3fr 2fr 2fr 2fr;
    /* grid-template-columns: 200px minmax(200px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(122px, 1fr) minmax(85px, 1fr) minmax(90px, 2fr) minmax(252px, 1fr) minmax(210px, 1fr) minmax(117px, 1fr); */
    grid-gap: 22px;
    display: grid;
    border-bottom: 1px solid #E2E8F0;
    align-items: center;
    width: -moz-fit-content;
    width: 100%;
}
.prospects_wrapper .prospects_content .prospects_container > .container_body .item_opportunities .choose_item_wrap {
    display: grid;
    align-items: center;
    cursor: pointer;
    padding: 4px;
}
.prospects_wrapper .prospects_content .prospects_container > .container_body .item_opportunities p {
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
}
.workspace_wrapper .workspace_content .workspace_container > .container_body > .item_opportunities span, .prospects_wrapper .prospects_content .prospects_container > .container_body .item_opportunities span:not(.status) {
    font-size: 14px;
    line-height: 21px;
    color: #475569;
    opacity: 0.5;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    text-transform: capitalize;
}
.prospects_wrapper .prospects_content .prospects_container > .container_body .item_opportunities .choose_item {
    display: grid;
    align-items: center;
    justify-content: center;
    border: 2px solid #478ECC;
    width: 1rem;
    height: 1rem;
}
.prospects_wrapper .prospects_content .prospects_container > .container_body .item_opportunities .choose_item svg {
    transform: scale(.8) rotate(-20deg);
    opacity: 0;
    transition: .3s;
}

.prospects_wrapper .prospects_content .prospects_container > .container_body .item_opportunities .choose_item_active svg {
    transform: scale(1) rotate(0);
    opacity: 1;
}
.prospects_wrapper .prospects_content .prospects_container > .head_opportunities .item {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, max-content);
    cursor: pointer;
}
.workspace_wrapper .workspace_content .workspace_container > .head .item span, .prospects_wrapper .prospects_content .prospects_container > .head_opportunities .item span {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    text-transform: capitalize;
    word-break: break-word;
    color: #0F172A;
}
