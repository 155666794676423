:root {
    --dark-grey: #2b2b2e;
    --ugly-purple: #bc4386;
    --turquoise: #01afa8;
    --slate: #4e5c6a;
    --lite-grey: #dbddde;
    --pale-grey: #f5fcff;
    --grey: #cccbcb;
    --error: #F26464;
    --success: #4BDE97;
    --white: #f5f5f5;
    --beige: #F6F6F1;
    --new-error: #FF4949;
    --gitgo-blue: #01426A;
}

*, ::after, ::before {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    color: unset;
    font-size: unset;
    line-height: unset;
    background: #fff;
    /*overflow-x: hidden;*/
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    min-width: 320px;
}

p, ul, li, pre {
    margin-bottom: 0
}

button, textarea,
input, a,
input:focus,
button:focus,
textarea:focus {
    outline: none;
    text-decoration: none;
}

button {
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    margin: 0;
}

input, textarea, label {
    cursor: auto;
    padding: 0;
    margin: 0;
}

input,
textarea {
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-appearance: none;
    cursor: auto;
}

header, main, nav, section, div {
    display: block;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}

.overflow_scrollbar::-webkit-scrollbar {
    width: 8px;
}

.overflow_scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px #ededed;
}

.overflow_scrollbar::-webkit-scrollbar-thumb {
    background-color: #c3c3c2;
    border: 1px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
}

.no_items_style {
    width: 100%;
    display: grid;
    text-align: center;
    height: 100px;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
}

.wrapper_admin_panel_navigation {
    display: grid;
    justify-content: space-between;
    background: #FFFFFF;
    position: fixed;
    height: 100vh;
    left: 0;
    top: 0;
    grid-template-rows: max-content 1fr;
    bottom: 0;
    grid-template-columns: 1fr;
    width: 248px;
    border-right: 1px solid #CBD5E1;
}

.wrapper_admin_panel_log_out {
    display: grid;
    align-content: end;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 8px;
    cursor: pointer;
    bottom: 40px;
    left: 2rem;
    height: max-content;
    width: max-content;
    margin: auto auto 56px 37px;
    align-items: center;
}

.wrapper_admin_panel_navigation_logo {
    display: block;
    margin: 4.5rem auto;
}

.wrapper_admin_panel_log_out span {
    font-family: "Inter", "sans-serif";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
}

.wrapper_admin_panel_navigation_content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    padding-left: 37px;
    padding-top: 112px
}

.wrapper_admin_panel_navigation_content a {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: .5rem;
    width: max-content;
    font-family: "Inter", "sans-serif";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #475569;
    opacity: .5;
    transition: .3s all;
}

.wrapper_admin_panel_navigation_content g, .wrapper_admin_panel_navigation_content svg, .wrapper_admin_panel_navigation_content path {
    transition: .3s all;
}

.wrapper_admin_panel_navigation_content .admin_bar_active_link g, .wrapper_admin_panel_navigation_content .admin_bar_active_link {
    opacity: 1 !important;
}

.wrapper_admin_panel_content {
    margin-left: 248px;
    min-height: 100vh;
    background: #F8FAFC;
    padding: 5.5rem 2.25rem 40px 2.75rem;
}

.wrapper_admin_panel_content h2.page_title {
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
}

.buttons_wrapper {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
}

.admin_content_wrapper h1 {
    font-weight: bold;
    font-size: 34px;
    line-height: 41px;
    letter-spacing: 0.374px;
    color: #333333;
    margin: 0 0 1.5rem;
}

.admin_wrapper_modal h4 {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: #000000;
    margin: 0;
}

.admin_wrapper_modal p {
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin: 0 0 2rem;
}

.pagination_wrapper {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 1rem;
    align-items: center;
    justify-content: space-between;
    margin: 1.5rem 0 0;
}

.pagination_wrapper .pagination_per_page {
    display: grid;
    grid-template-columns: max-content 114px;
    grid-gap: 2rem;
    justify-content: space-between;
    align-items: center;
}

.pagination_wrapper .pagination_per_page > span {
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #94A3B8;
}

.pagination_wrapper .pagination_content .pagination {
    display: flex;
}

.pagination_wrapper .pagination_content .pagination .pagination_list_buttons a {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    border: 1px solid transparent;
    border-radius: 100px;
    transition: .3s;
    width: 36px;
    height: 36px;
    display: grid;
    align-items: center;
    justify-content: center;
}

.pagination_wrapper .pagination_content .pagination .pagination_list_buttons_active a {
    border: 1px solid var(--gitgo-blue);
}


.pagination_wrapper .pagination_content .pagination .pagination_list_buttons a button {
    background: var(--gitgo-blue);
    transition: .3s;
    border-radius: 100px;
    width: 36px;
    height: 36px;
    display: grid;
    align-items: center;
    justify-content: center;
}

