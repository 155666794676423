.targeting_wrapper {
    margin: -5.5rem -2.25rem -40px -2.75rem;
    padding: 5.5rem 2.25rem 40px 2.75rem;
    background: #FFFFFF;
    min-height: 100vh;
}

.targeting_wrapper h2 {
    margin: 0 0 1.5rem;
}

.targeting_wrapper p {
    font-size: 20px;
    line-height: 30px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0 0 2rem;
    max-width: 920px;
}

.targeting_wrapper .first_content, .targeting_wrapper .second_content, .targeting_wrapper .second_content .inline, .targeting_wrapper .third_content, .targeting_wrapper .fourth_content, .targeting_wrapper .fifth_content, .targeting_wrapper .sixth_content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: end;
    max-width: 732px;
    grid-gap: 1.5rem 2rem;
    margin: 0 0 2rem;
    padding: 0 0 2rem;
    border-bottom: 1px solid #E2E8F0;
}

.targeting_wrapper .first_content {
    align-items: start;
}

.targeting_wrapper .second_content .inline {
    grid-column: 1/-1;
    align-items: start;
    padding: 0;
    margin: 0;
    border: none;
}

.targeting_wrapper .second_content .inline_gap {
    grid-column: 1/-1;
    align-items: start;
    padding: 0;
    margin: 0;
    border: none;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem 2rem;
}

.targeting_wrapper .second_content .inline_gap .horizontal_gap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem 2rem;
    align-items: end;
}

.targeting_wrapper .second_content .inline .vertical_gap {
    grid-gap: 1.5rem;
    display: grid;
}

.targeting_wrapper .fourth_content {
    align-items: start;
}

.targeting_wrapper .button_wrap {
    max-width: 732px;
}

.targeting_wrapper .button_wrap .submit_button {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #FFFFFF;
    margin-left: auto;
    padding: 12px 52px;
}