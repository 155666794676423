.login_wrapper {
    height: 100vh;
    width: 100%;
    background: #FFFFFF;
    position: fixed;
}

.login_wrapper > .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 543px;
}

.login_wrapper > .content > .head {
    background: var(--gitgo-blue);
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 6px 0;
}

.login_wrapper > .content > .head img {
    max-width: 100%;
    width: 81px;
}

.login_wrapper > .content > .card_content {
    background: #F8FAFC;
    padding: 2.75rem 3.75rem 3.25rem;
}

.login_wrapper > .content > .card_content h2 {
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    margin: 0 0 2rem;
}

.login_wrapper > .content > .card_content_setup h2 {
    text-align: left;
    margin: 0 0 .5rem;
}

.login_wrapper > .content > .card_content_setup p {
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0 0 1rem;
}

.login_wrapper > .content > .card_content form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    align-items: center;
    margin: 0 0 2rem;
}

.login_wrapper > .content > .card_content a.forgot_password {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    display: block;
    width: max-content;
    margin: 0 auto;
}

.login_wrapper p.login_error_format {
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--error);
    margin: 0;
}