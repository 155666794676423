.custom_select_wrapper {
    position: relative;
    width: minmax(100px, max-content);
}

.custom_select_wrapper > .title {
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    display: block;
    margin: 0 0 4px;
}

.prospects_custom_select_wrapper > .title {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    display: block;
    margin: 0 0 4px;
}

.targeting_custom_select_wrapper > .title, .targeting_custom_select_wrapper > .title span {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    display: block;
    margin: 0 0 4px;
}

.custom_select_wrapper > .select_content {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 10px;
    padding: 8px 16px;
    align-items: center;
    border: 1px solid #E2E8F0;
    border-radius: 2px;
    cursor: pointer;
    text-transform: capitalize;
}

.custom_select_wrapper > .select_content span {
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
}

.custom_select_wrapper .select_popup, .multiply_select_wrapper .select_popup {
    position: absolute;
    top: 2.5rem;
    background: #FFFFFF;
    box-shadow: 0 0 1px rgba(15, 23, 42, 0.06), 0 10px 15px -3px rgba(15, 23, 42, 0.1), 0 4px 6px -2px rgba(15, 23, 42, 0.05);
    border-radius: 6px;
    display: grid;
    grid-template-columns: 1fr;
    max-height: 0;
    left: -10px;
    transition: .3s;
    overflow-y: hidden;
    z-index: 100;
}

.prospects_custom_select_wrapper .select_popup {
    top: 4rem;
}

.pagination_popup .select_popup {
    top: 3.5rem;
}

.pagination_popup .select_content {
    background: #FFFFFF;
    border: 1px solid #E2E8F0;
    padding: 12px 16px;
    border-radius: 2px;
}

.custom_select_wrapper .select_popup_active, .multiply_select_wrapper .select_popup_active {
    max-height: 200px;
    overflow-y: auto;
}

.custom_select_wrapper .select_popup_active_max_height {
    max-height: 150px;
}

.custom_select_wrapper .select_popup .nested {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 8px;
    align-items: center;
    padding: 8px;
    transition: .3s;
    cursor: pointer;
    margin: 4px;
}

.custom_select_wrapper .select_popup .nested .option_color {
    width: 8px;
    height: 8px;
    border-radius: 100%;
}
.select_content .center{
    display: flex;
    justify-content: center;
    align-items:center;
}
.custom_select_wrapper .select_popup span {
    padding: 8px;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    transition: .3s;
    border-radius: 2px;
    cursor: pointer;
    margin: 4px;
    text-transform: capitalize;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.custom_select_wrapper .select_popup .nested span {
    padding: 0;
    margin: 0;
}

.custom_select_wrapper .select_popup span:hover {
    background: #F2F8FD;
}

.custom_select_wrapper .select_popup .nested span:hover {
    background: unset;
}

.custom_select_wrapper .select_popup .nested:hover {
    background: #F2F8FD;
}

.custom_select_wrapper > .error_text {
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    display: grid;
    margin: 0 0 4px;
}