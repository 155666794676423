.user_info_wrapper {
    padding: 5.5rem 2.25rem 40px 2.75rem;
    margin: -5.5rem -2.25rem -40px -2.75rem;
    background: #FFFFFF;
}

.user_info_wrapper .navigation {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    align-items: center;
    grid-gap: 8px;
    margin: 0 0 1rem;
}

.user_info_wrapper .navigation a {
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
}

.user_info_wrapper .navigation span {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
}

.user_info_wrapper .title_wrap {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;
    margin: 0 0 2rem;
}

.user_info_wrapper .contact_details h6 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0 0 1rem;
}

.user_info_wrapper .contact_details .content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem 2rem;
    align-items: center;
    margin: 0 0 1rem;
    padding: 0 0 1.5rem;
    border-bottom: 1px solid #E2E8F0;
    max-width: 540px;
}

.user_info_wrapper .contact_details .content .full_line {
    grid-column: 1/-1;
}

.user_info_wrapper .contact_details .content span {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    text-transform: capitalize;
    display: block;
    margin: 0 0 8px;
}

.user_info_wrapper .contact_details .content p {
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
}

.user_info_wrapper .contact_details .content .prospects {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
}

.user_info_wrapper .contact_details .content .prospects a {
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    background: rgba(150, 200, 237, 0.2);
    border-radius: 2px;
    padding: 4px 8px;
    margin: 8px;
}

.user_info_wrapper .contact_details .delete_contact {
    margin: 0 0 3.25rem;
    padding: 0 0 1.5rem;
    max-width: 540px;
    border-bottom: 1px solid #E2E8F0;
}

.user_info_wrapper .contact_details .delete_contact p {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    margin: 0 0 8px;
}

.user_info_wrapper .contact_details .delete_contact div {
    cursor: pointer;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 8px;
    align-items: center;
}

.user_info_wrapper .contact_details .delete_contact span {
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--error);
}

.user_info_wrapper .activity_wrapper .title_wrap {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;
    margin: 0 0 1.75rem;
}

.user_info_wrapper .activity_wrapper .title_wrap > span {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
}

.user_info_wrapper .activity_wrapper .title_wrap > .add_activity_log {
    position: relative;
}

.user_info_wrapper .activity_wrapper .title_wrap > .add_activity_log .button_default_plus {
    padding: 6px 1rem;
    font-weight: bold;
}

.user_info_wrapper .activity_wrapper .title_wrap > .add_activity_log .content {
    position: absolute;
    top: 2.5rem;
    right: 0;
    display: grid;
    background: #FFFFFF;
    border: 1px solid #E2E8F0;
    box-shadow: 0 0 1px rgba(15, 23, 42, 0.06), 0 10px 15px -3px rgba(15, 23, 42, 0.1), 0 4px 6px -2px rgba(15, 23, 42, 0.05);
    border-radius: 2px;
    width: 194px;
    padding: 4px 8px;
    z-index: 100;
    grid-gap: 4px;
    grid-template-columns: 1fr;
}

.user_info_wrapper .activity_wrapper .title_wrap > .add_activity_log .content div {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 8px;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    border-radius: 2px;
    cursor: pointer;
    color: #0F172A;
    transition: .3s;
    padding: 8px;
    text-transform: capitalize;
}

.user_info_wrapper .activity_wrapper .title_wrap > .add_activity_log .content div svg path {
    transition: .3s;
}

.user_info_wrapper .activity_wrapper .title_wrap > .add_activity_log .content div:hover {
    background: #F8FAFC;
}

.user_info_wrapper .activity_wrapper .title_wrap > .add_activity_log .content div:hover svg path {
    stroke: var(--gitgo-blue);
}

.user_info_wrapper .activity_wrapper .activity_content {
    border: 1px solid #CBD5E1;
    padding: 1.5rem;
    max-height: 600px;
    overflow-y: auto;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item {
    display: grid;
    grid-template-columns: 5fr 7fr;
    align-items: start;
    grid-gap: 1.5rem;
    padding: 0 0 1rem;
    margin: 0 0 1rem;
    border-bottom: 1px solid #E2E8F0;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item:last-of-type {
    padding: 0;
    margin: 0;
    border-bottom: none;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item .logged {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 1rem;
    margin: 0 0 1.5rem;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item .logged svg path {
    stroke: var(--gitgo-blue);
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item .max_width {
    max-width: 325px;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item .logged span {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    text-transform: capitalize;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item .left span.time {
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #94A3B8;
    text-transform: capitalize;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item .notes {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 12px;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item .notes > span {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item .notes p {
    font-size: 14px;
    line-height: 17px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #94A3B8;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item .calendar_wrapper .title {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item_new .notes {
    grid-template-columns: 1fr;
    grid-gap: 4px;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item_new .notes .input_component {
    margin: 0;
    display: grid;
    align-items: center;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item_new .buttons_wrapper {
    grid-column: 1/-1;
    justify-content: end;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item_new .buttons_wrapper button {
    width: 5rem;
}

.user_info_wrapper .contact_details .content span.unassigned {
    background: #E2E8F0;
    border-radius: 2px;
    padding: 4px 8px;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #94A3B8;
    text-transform: capitalize;
    display: unset;
    margin: 0;
}