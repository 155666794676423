@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    z-index: 5000;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.delete-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 489px;
    max-height: 240px;
    margin-top: 25%;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 25px;
    border: 1px solid #CBD5E1;
    overflow: auto;
}

.close-button {
    cursor: pointer;
    background: #FFFFFF;
    border: 1px solid var(--gitgo-blue);
    border-radius: 2px;
    width: 80px;
    height: 36px;
    font-size: 14px;
    font-weight: 700;
    color: var(--gitgo-blue);
    font-family: 'Inter', sans-serif;
    margin-right: 8px;
}

.delete-button {
    cursor: pointer;
    background: #F26464;
    border-radius: 2px;
    width: 80px;
    height: 36px;
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF;
    font-family: 'Inter', sans-serif;
    margin-left: 8px;
}

.delete-popup-title {
    font-size: 20px;
    font-weight: 400;
    color: #475569;
    margin-bottom: 5px;
    font-family: 'Inter', sans-serif;
}

.item-name {
    font-size: 20px;
    font-weight: 600;
    color: var(--gitgo-blue);
    font-family: 'Inter', sans-serif;
}

.delete-question-text {
    font-size: 14px;
    font-weight: 400;
    margin-top: 28px;
    margin-bottom: 28px;
    box-sizing: content-box;
    width: 270px;
    text-align: center;
    font-family: 'Inter', sans-serif;
    color: #475569;
}

.delete-popup-buttons-container {
    display: flex;
}

.delete-popup-buttons-container button {
    width: 80px;
    height: 36px;
    font-size: 15px;
    font-weight: 700;
    display: flex;
    justify-content: center;
}

.delete-popup-buttons-container button:first-child {
    border: 1px solid var(--gitgo-blue);
    color: var(--gitgo-blue);
    background-color: transparent;
    margin-right: 8px;
    display: flex;
    align-items: center;
}

.delete-popup-buttons-container button:last-child {
    border: 1px solid #F26464;
    color: #FFFFFF;
    background-color: #F26464;
    margin-left: 8px;
    display: flex;
    align-items: center;
}