.recovery_wrapper {
    display: grid;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.recovery_wrapper .content {
    width: 543px;
}

.recovery_wrapper .content .head {
    background: var(--gitgo-blue);
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 6px 0;
}

.recovery_wrapper .content .head img {
    max-width: 100%;
    width: 81px;
}

.recovery_wrapper .content .body {
    padding: 2rem 3.5rem 1rem;
    background: #F8FAFC;
}

.recovery_wrapper .content .body h3 {
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    margin: 0 0 8px;
}

.recovery_wrapper .content .body p {
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0 0 1rem;
}

.recovery_wrapper .content .body form {
    display: grid;
    grid-gap: 2rem;
}

.recovery_wrapper .content .body button {
    padding: 12px 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #FFFFFF;
    background: var(--gitgo-blue);
    border-radius: 2px;
}

.recovery_wrapper .content .body p.token_error {
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #94A3B8;
    margin: 0;
}