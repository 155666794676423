.select_options_wrap .title {
    font-size: 14px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    display: block;
    margin: 0 0 1rem;
}

.select_options_wrap .content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.select_options_wrap .content .item {
    display: grid;
    grid-gap: 6px;
    align-items: center;
    grid-template-columns: repeat(2, max-content);
}

.select_options_wrap .content .item {
    display: grid;
    grid-gap: 6px;
    align-items: center;
    grid-template-columns: repeat(2, max-content);
    cursor: pointer;
    margin: 0 1rem .5rem 0;
}

.select_options_wrap .content .item p {
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    margin: 0;
    color: #475569;
}

.select_options_wrap .content .item .circle {
    width: 18px;
    height: 18px;
    border: 2px solid var(--gitgo-blue);
    border-radius: 100%;
    transition: .3s;
}

.select_options_wrap .content .item .active_circle {
    background: var(--gitgo-blue);
    border-radius: 100%;
    border: 2px solid var(--gitgo-blue);
}