.create_contact_wrapper {
    padding: 5.5rem 2.25rem 40px 2.75rem;
    margin: -5.5rem -2.25rem -40px -2.75rem;
    background: #FFFFFF;
    min-height: 100vh;
}

.delete_contact {
    margin: 0 0 0rem;
    padding: 0 0 1.5rem;
    width: max-content;
}

.delete_contact p {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    margin: 0 0 8px;
}

 .delete_contact div {
    cursor: pointer;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 8px;
    align-items: center;
}

.delete_contact span {
    font-size: 16px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--error);
}

.create-contact {
    display: flex;
    flex-direction: column;
    width: 730px;
}

.create-contact-inputs-title {
    font-size: 18px;
    font-weight: 500;
    color: #475569;
    margin: 32px 0 16px 0;
}


.create-contact-input-content {
    display: flex;
    margin-bottom: 24px;
}

.create-contact-input-content:last-child {
    margin-bottom: 0;
}

.create-contact-input-container:nth-child(2) {
    margin-left: 30px;
}

.create-contact-input-container {
    display: flex;
    flex-direction: column;
}

.create-contact-input-container-test {
    height: 40px;
    width: 350px;
    border-radius: 2px;
    border: 1px solid #E2E8F0;
    background-color: #FFFFFF;
    display: grid;
    grid-template-columns: 9fr 1fr;
}

.create-contact-input-container-test-img {
}

.create-contact-input-container input.custom_input {
    height: 40px;
    width: 350px;
    border-radius: 2px;
    padding: 8px 16px;
}

.create-contact-input-container input:focus {
    border: 1px solid #475569;
}

.create-contact-input-container .select_content {
    width: 350px;
    height: 40px;
    background-color: white;
    padding: 7px 16px;
}

.create-contact-input-container span {
    font-size: 14px;
    font-weight: 400;
    color: var(--gitgo-blue);
    margin-bottom: 4px;
}

.create-contact-input-false input.custom_input {
    border: 1px solid #F26464;
}

.line-block {
    width: 100%;
    height: 1px;
    background-color: #E2E8F0;
    margin: 28px 0 40px 0;
}

.create-contact-buttons {
    display: flex;
    justify-content: flex-end;
}

.create-contact-buttons button {
    width: 80px;
    height: 36px;
    font-size: 14px;
    font-weight: 700;
}

.create-contact-buttons button:last-child {
    margin-left: 16px;
}

.cancel-button {
    border: 1px solid var(--gitgo-blue);
    border-radius: 2px;
    color: var(--gitgo-blue);
}

.create-button {
    background-color: var(--gitgo-blue);
    border-radius: 2px;
    color: white;
}