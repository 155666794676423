.upload-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 70vh;
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    border: 1px solid #CBD5E1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.upload-buttons-container {
    display: flex;
}

.upload-buttons-container button:first-child {
    cursor: pointer;
    background: #FFFFFF;
    border: 1px solid var(--gitgo-blue);
    border-radius: 2px;
    width: 80px;
    height: 36px;
    font-size: 14px;
    font-weight: 700;
    color: var(--gitgo-blue);
    font-family: 'Inter', sans-serif;
    margin-right: 8px;
    justify-content: center;
}

.upload-buttons-container button:last-child {
    cursor: pointer;
    background: var(--gitgo-blue);
    border-radius: 2px;
    width: 80px;
    height: 36px;
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF;
    font-family: 'Inter', sans-serif;
    margin-left: 8px;
    justify-content: center;
}

.filedrop {
    width: 338px;
    height: 100px;
    border: 1px solid #CBD5E1;
    text-align: center;
    font-size: 24px;
    padding: 24px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filedrop.drag {
    border: 3px dashed #1e90ff;
}

.filedrop.ready {
    border: 3px solid #32cd32;
}

.file-upload-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.uploadFileText {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}

.upload-title-text {
    font-size: 20px;
    font-weight: 400;
    color: #0F172A;
    margin-bottom: 16px;
}

.upload-info-text {
    font-size: 14px;
    font-weight: 400;
    color: #475569;
    margin-bottom: 16px;
}

.upload-download-template-text {
    font-size: 14px;
    font-weight: 400;
    color: var(--gitgo-blue);
    margin-bottom: 32px;
    margin-top: 32px;
}