.switch_wrapper {
    width: 100%;
    margin: 0 0 1.5rem;
}

.switch_wrapper:last-of-type {
    margin: 0 0 2rem;
}

.switch_wrapper > span.title {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    display: block;
    margin: 0 0 1rem;
}

.switch_wrapper > .switch_content {
    display: grid;
    grid-gap: 1.5rem;
    align-items: start;
    grid-template-columns: 1fr 1fr;
}

.switch_wrapper > .switch_content .block_title {
    display: grid;
    grid-gap: .5rem;
    align-items: center;
    grid-template-columns: max-content 1fr;
    margin: 0 0 .5rem;
}

.switch_wrapper > .switch_content .block_title > span {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
}

.switch_wrapper > .switch_content .options_wrap {
    display: grid;
    align-items: start;
    grid-gap: 4px;
    border: 1px solid #E2E8F0;
    background: #ffffff;
    height: 218px;
    overflow-y: auto;
}

.switch_wrapper > .switch_content .options_wrap .content {
    padding: 4px;
    border-bottom: 1px solid #E2E8F0;
}

.switch_wrapper > .switch_content .options_wrap .content_full {
    height: 100%;
}

.switch_wrapper > .switch_content .options_wrap .item {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 1rem;
    align-items: center;
    background: #ffffff;
    padding: 8px;
    transition: .3s;
    cursor: pointer;
    border-radius: 2px;
}

.switch_wrapper > .switch_content .options_wrap .no_item {
    background: #ffffff;
    padding: 8px;
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    display: block;
}

.switch_wrapper > .switch_content .options_wrap .error {
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #94A3B8;
    display: grid;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    text-align: center;
}

.switch_wrapper > .switch_content .options_wrap_right .item {
    grid-template-columns: 1fr max-content;
}

.switch_wrapper > .switch_content .options_wrap .item:hover {
    background: #F8FAFC;
}

.switch_wrapper > .switch_content .options_wrap .item span {
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
}