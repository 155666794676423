.profile_settings_wrapper h2 {
    margin: 0 0 2.5rem;
}

.profile_settings_wrapper .container {
    margin-left: 6rem;
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    border-radius: 8px;
    padding: 36px 85px 52px;
}

.profile_settings_wrapper .container .content {
    padding: 0 9px;
}

.profile_settings_wrapper .container .content p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    margin: 0 0 1.5rem;
}

.profile_settings_wrapper .container .content form .block {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem 30px;
    align-items: start;
}

.profile_settings_wrapper .container .content form .change_password {
    margin: 1.5rem 0 0;
}

.profile_settings_wrapper .container .content form .change_password p {
    font-size: 14px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    margin: 0 0 12px;
}

.profile_settings_wrapper .container .content form .change_password span {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #F26464;
    width: max-content;
    cursor: pointer;
}

.profile_settings_wrapper .container .content form .line {
    background: #E2E8F0;
    margin: 32px 0 32px -9px;
    width: calc(100% + 18px);
    height: 1px;
}

.profile_settings_wrapper .container .content form .buttons_wrapper {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: center;
}

.profile_settings_wrapper .container .content form .buttons_wrapper button {
    width: 5rem;
    padding: 6px 0;
    text-transform: unset;
}
.footer-profile-edit{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.footer-profile-edit .error-text span{
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: red;
    width: max-content;
}