.prospects_wrapper .title {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;
    margin: 0 0 1.5rem;
}

.prospects_wrapper .prospects_data {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: space-between;
    grid-gap: 30px;
    margin: 0 0 1.5rem;
}

.prospects_wrapper .prospects_data .item {
    border: 1px solid #ffffff;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 12px;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 1rem;
    align-items: center;
    transition: 0.4s;
}
/*.prospects_wrapper .prospects_data .item:hover {*/
/*  border: 1px solid var(--gitgo-blue);*/
/*}*/

.prospects_wrapper .prospects_data .item .icon {
    width: 69px;
    height: 69px;
    display: grid;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: #FFF8ED;
}
.prospects_wrapper .dashboard_total_blocks {
    margin-bottom: 20px;
}

.prospects_wrapper .prospects_data .item .content h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
}

.prospects_wrapper .prospects_data .item .content p {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0 0 4px;
}

.prospects_wrapper .prospects_data .item .content .inline {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 8px;
    align-items: center;
}

.prospects_wrapper .prospects_data .item .content .inline > p {
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #4BDE97;
    margin: 0;
}

.prospects_wrapper .prospects_data .item .content .inline > span {
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #94A3B8;
}


.prospects_wrapper .prospects_content .prospects_container > .head .item {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, max-content);
    cursor: pointer;
}

.prospects_wrapper .prospects_content .prospects_container > .head .item:nth-child(6) {
    grid-template-columns: repeat(2, max-content);
}

.prospects_wrapper .prospects_content .prospects_container > .container_body .item .choose_item_wrap {
    display: grid;
    align-items: center;
    cursor: pointer;
    padding: 4px;
}

.prospects_wrapper .prospects_content .prospects_container > .container_body .item .choose_item {
    display: grid;
    align-items: center;
    justify-content: center;
    border: 2px solid #478ECC;
    width: 1rem;
    height: 1rem;
}

.prospects_wrapper .prospects_content .prospects_container > .container_body .item .choose_item svg {
    transform: scale(.8) rotate(-20deg);
    opacity: 0;
    transition: .3s;
}

.prospects_wrapper .prospects_content .prospects_container > .container_body .item .choose_item_active svg {
    transform: scale(1) rotate(0);
    opacity: 1;
}

.prospects_wrapper .prospects_content .prospects_container > .head {
    padding: 17px 50px 16px 60px;
    /* grid-template-columns: 200px minmax(120px, 1fr) minmax(120px, 1fr)  minmax(120px, 1fr) minmax(122px, 1fr)  minmax(85px, 1fr)  minmax(90px, 2fr) minmax(251px, 1fr) minmax(107px, 1fr) minmax(231px, 1fr); */
    grid-gap: 1%;
    grid-template-columns: 16.2% 13.6% 11.6% 10.9% 9.1% 8.3% 11.6% 14.6%;
    display: grid;
    border-bottom: 1px solid #E2E8F0;
    align-items: center;
    width: auto;
    /* width: -moz-fit-content; */
}

.prospects_wrapper .prospects_content .prospects_container > .container_body .item {
    padding: 17px 30px 16px 20px;
    grid-template-columns: 1.8% 16% 13.5% 11.5% 10.8% 9.1% 8.2% 11.5% 9.8% 1.8% ;
    /* grid-template-columns: 200px  minmax(200px, 1fr)  minmax(120px, 1fr)  minmax(120px, 1fr)   minmax(122px, 1fr)  minmax(85px, 1fr)  minmax(90px, 2fr) minmax(252px, 1fr) minmax(210px, 1fr) minmax(117px, 1fr); */
    grid-gap: 0.65%;
    display: grid;
    border-bottom: 1px solid #E2E8F0;
    align-items: center;
    width: auto;
    /* width: -moz-fit-content; */
}

/* .prospects_wrapper .prospects_content .prospects_container > .container_body .item:last-of-type {
    padding: 17px 2.75rem 16px 50px;
    border-bottom: none;
} */
.status-filter{
    display: flex;
    width: max-content;
    border: 1px solid #01426A;
    align-items:center;
    padding: 2px 5px 2px 10px;
    border-radius: 5px;
}
.status-filter .custom_select_wrapper > .select_content{
    border: none;
    display: flex;
    grid-template-columns: max-content max-content;
    
    padding: 1px 10px;
    grid-gap: 5px;
}
.status-filter .custom_select_wrapper .select_popup_active, .multiply_select_wrapper .select_popup_active{
    top: 2.5em;
    left: -10px;
}
.status-filter .custom_select_wrapper .select_popup span {
    color: #01426A!important;
}
.mt-37{
    margin-top: 37px;
}
.status-filter .custom_select_wrapper > .select_content span{
    color: #01426A;
    font-size: 15px;
    background-color: rgba(1, 66, 106, 0.1);
    padding: 4px 8px;
    /* display: flex; */
    width: 200px;
    /* justify-content: center;
    align-items: center; */
    border-radius: 2px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.status-filter span{
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 17px;
    color: #01426A;
}
.status-filter .status_popup{
    border: none;
    width: minmax(100px, max-content);
}

@media (max-width: 1550px) {
    .prospects_wrapper .prospects_content .prospects_container > .container_body .item {
        grid-template-columns: 20px 185px 155px 130px 130px 100px 100px 130px 110px 25px;
        gap:8px;
    }
    .prospects_wrapper .prospects_content .prospects_container > .head {
        padding: 17px 50px 16px 45px;

        grid-gap: 8px;
        grid-template-columns: 188px 155px 130px 130px 100px 100px 130px 110px 25px;
    }
    
}

@media (min-width: 1900px) {
    .prospects_wrapper .prospects_content .prospects_container > .head, .prospects_wrapper .prospects_content .prospects_container > .container_body .item {
        width: 100%;
    }
}