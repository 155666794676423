@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
:root {
    --dark-grey: #2b2b2e;
    --ugly-purple: #bc4386;
    --turquoise: #01afa8;
    --slate: #4e5c6a;
    --lite-grey: #dbddde;
    --pale-grey: #f5fcff;
    --grey: #cccbcb;
    --error: #F26464;
    --success: #4BDE97;
    --white: #f5f5f5;
    --beige: #F6F6F1;
    --new-error: #FF4949;
    --gitgo-blue: #01426A;
}

*, ::after, ::before {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    color: unset;
    font-size: unset;
    line-height: unset;
    background: #fff;
    /*overflow-x: hidden;*/
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    min-width: 320px;
}

p, ul, li, pre {
    margin-bottom: 0
}

button, textarea,
input, a,
input:focus,
button:focus,
textarea:focus {
    outline: none;
    text-decoration: none;
}

button {
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    margin: 0;
}

input, textarea, label {
    cursor: auto;
    padding: 0;
    margin: 0;
}

input,
textarea {
    border-radius: 0;
    -webkit-appearance: none;
    cursor: auto;
}

header, main, nav, section, div {
    display: block;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}

.overflow_scrollbar::-webkit-scrollbar {
    width: 8px;
}

.overflow_scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px #ededed;
}

.overflow_scrollbar::-webkit-scrollbar-thumb {
    background-color: #c3c3c2;
    border: 1px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
}

.no_items_style {
    width: 100%;
    display: grid;
    text-align: center;
    height: 100px;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
}

.wrapper_admin_panel_navigation {
    display: grid;
    justify-content: space-between;
    background: #FFFFFF;
    position: fixed;
    height: 100vh;
    left: 0;
    top: 0;
    grid-template-rows: -webkit-max-content 1fr;
    grid-template-rows: max-content 1fr;
    bottom: 0;
    grid-template-columns: 1fr;
    width: 248px;
    border-right: 1px solid #CBD5E1;
}

.wrapper_admin_panel_log_out {
    display: grid;
    align-content: end;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    grid-gap: 8px;
    cursor: pointer;
    bottom: 40px;
    left: 2rem;
    height: -webkit-max-content;
    height: max-content;
    width: -webkit-max-content;
    width: max-content;
    margin: auto auto 56px 37px;
    align-items: center;
}

.wrapper_admin_panel_navigation_logo {
    display: block;
    margin: 4.5rem auto;
}

.wrapper_admin_panel_log_out span {
    font-family: "Inter", "sans-serif";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
}

.wrapper_admin_panel_navigation_content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    padding-left: 37px;
    padding-top: 112px
}

.wrapper_admin_panel_navigation_content a {
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: .5rem;
    width: -webkit-max-content;
    width: max-content;
    font-family: "Inter", "sans-serif";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #475569;
    opacity: .5;
    transition: .3s all;
}

.wrapper_admin_panel_navigation_content g, .wrapper_admin_panel_navigation_content svg, .wrapper_admin_panel_navigation_content path {
    transition: .3s all;
}

.wrapper_admin_panel_navigation_content .admin_bar_active_link g, .wrapper_admin_panel_navigation_content .admin_bar_active_link {
    opacity: 1 !important;
}

.wrapper_admin_panel_content {
    margin-left: 248px;
    min-height: 100vh;
    background: #F8FAFC;
    padding: 5.5rem 2.25rem 40px 2.75rem;
}

.wrapper_admin_panel_content h2.page_title {
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #01426A;
    color: var(--gitgo-blue);
}

.buttons_wrapper {
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
}

.admin_content_wrapper h1 {
    font-weight: bold;
    font-size: 34px;
    line-height: 41px;
    letter-spacing: 0.374px;
    color: #333333;
    margin: 0 0 1.5rem;
}

.admin_wrapper_modal h4 {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: #000000;
    margin: 0;
}

.admin_wrapper_modal p {
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin: 0 0 2rem;
}

.pagination_wrapper {
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    grid-gap: 1rem;
    align-items: center;
    justify-content: space-between;
    margin: 1.5rem 0 0;
}

.pagination_wrapper .pagination_per_page {
    display: grid;
    grid-template-columns: -webkit-max-content 114px;
    grid-template-columns: max-content 114px;
    grid-gap: 2rem;
    justify-content: space-between;
    align-items: center;
}

.pagination_wrapper .pagination_per_page > span {
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #94A3B8;
}

.pagination_wrapper .pagination_content .pagination {
    display: flex;
}

.pagination_wrapper .pagination_content .pagination .pagination_list_buttons a {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #01426A;
    color: var(--gitgo-blue);
    border: 1px solid transparent;
    border-radius: 100px;
    transition: .3s;
    width: 36px;
    height: 36px;
    display: grid;
    align-items: center;
    justify-content: center;
}

.pagination_wrapper .pagination_content .pagination .pagination_list_buttons_active a {
    border: 1px solid #01426A;
    border: 1px solid var(--gitgo-blue);
}


.pagination_wrapper .pagination_content .pagination .pagination_list_buttons a button {
    background: #01426A;
    background: var(--gitgo-blue);
    transition: .3s;
    border-radius: 100px;
    width: 36px;
    height: 36px;
    display: grid;
    align-items: center;
    justify-content: center;
}


.button_default {
    background: var(--gitgo-blue);
    border-radius: 4px;
    width: 100%;
    height: auto;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #FFFFFF;
    text-transform: uppercase;
    display: -webkit-box;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    border: unset;
    padding: 12px;
    transition: .3s;
}

.cancel_button, .submit_button, .edit_button {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    padding: 6px 16px;
    border: 1px solid var(--gitgo-blue);
    border-radius: 2px;
    width: -webkit-max-content;
    width: max-content;
    background: #FFFFFF;
    text-transform: unset;
}

 .edit_button {
     display: grid;
     grid-template-columns: repeat(2, -webkit-max-content);
     grid-template-columns: repeat(2, max-content);
     align-items: center;
     grid-gap: 4px;
}

.cancel_button:focus, .submit_button:focus, .edit_button:focus {
    border: 1px solid var(--gitgo-blue);
}

.submit_button {
    color: #FFFFFF;
    background: var(--gitgo-blue);
    border: 1px solid var(--gitgo-blue);
}

.delete_button {
    color: #FFFFFF;
    background: var(--error);
    border: 1px solid var(--error);
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    padding: 6px 0;
    text-transform: unset;
}

.button_default_plus {
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    grid-gap: 8px;
    align-items: center;
    text-transform: unset;
    padding: .75rem 1rem;
    box-shadow: 0 17px 19px rgba(39, 98, 249, 0.1);
    border-radius: 2px;
    border: 1px solid var(--gitgo-blue);
}

.button_add_new {
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    grid-gap: 4px;
    align-items: center;
    text-transform: unset;
    border: 1px solid var(--gitgo-blue);
    border-radius: 2px;
    padding: 6px 1rem;
    background: transparent;
    width: -webkit-max-content;
    width: max-content;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
}

.delete_button_trash {
    background: transparent;
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    justify-content: center;
    align-items: center;
    grid-gap: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    padding: 6px 17px;
    color: var(--error);
    border: 1px solid var(--error);
    border-radius: 4px;
    width: -webkit-max-content;
    width: max-content;
}
.page_not_found_wrapper {
    display: grid;
    width: 100%;
    height: 100vh;
    top: 0;
    min-height: 400px;
    right: 0;
    left: 0;
    bottom: 0;
    background: #FFFFFF;
}

.forward_block_page {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.forward_block_page .content {
    background: var(--gitgo-blue);
    border-radius: 11px;
    padding: 73px 0 78px;
    text-align: center;
    width: 635px;
}

.forward_block_page p {
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #FFFFFF;
    margin: 0 0 60px;
}

.return_button_page {
    display: grid;
    justify-content: center;
    align-items: center;
}

.return_button_page button {
    margin: 58px 0 0;
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 8px;
    padding: 12px 15px;
}

.apexcharts-zoom-icon, .apexcharts-reset-icon, .apexcharts-menu-icon {
    display: none !important;
}
.apexcharts-legend.position-bottom .apexcharts-legend-series, .apexcharts-legend.position-top .apexcharts-legend-series{
    margin: 2px 45px!important;

}
.arrow_box{
    padding: 16px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr;
}
.count_footer{
    padding-top: 10px;
    border-top: 1px solid #d7d5d5;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.status_block{
    display: grid;
    grid-gap: 5px;
}
.block_name{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #475569;

}
.status_type{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}
.count_number{
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 39px;
    color: var(--gitgo-blue);
}
.record_type{
    border-left: 1px solid #475569;
    font-style: normal;
    padding-left: 5px;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #475569;
}
.bar_tags{
    fill-opacity: 0!important;
}
.apexcharts-tooltip {
    width: 274px;
    height: 149px;
    background: #f3f3f3;
}
#my_Chart{
    width: 300px!important;
    height: 320px!important;
}
.mixed-chart{
    inset: 40px 25px;
}
@media (max-width: 1680px) {
    .mixed-chart {
        inset: 40px -5px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
@media (max-width: 1220px) {
    #my_Chart{
        width: 350px!important;
        height: 320px!important;
    }
    .mixed-chart {
        inset: -20px -7px;
    }

}
.block-names{
    /* display: grid; */
    grid-template-columns: 70px 70px;
    grid-row-gap: 20px;
    grid-column-gap: 70px;
}
.block-names .block-name{
    display: grid;
    padding-bottom: 10px;
    align-items: center;
    grid-template-columns: -webkit-max-content -webkit-max-content;
    grid-template-columns: max-content max-content;
    grid-gap: 5px;
    gap: 5px;
}
.block-names .block-name .block {
    padding: 5;
    height: 10px;
    width: 10px;
    background-color: #8e9bae;
    border-radius:4px;
}
/* .block-names .block-name .block-new {
    background-color: #FFB648 ;
} */

.raphael-group {
    display: none;
}

g[class^="raphael-group-vPCHuMAf"] {
    display: none !important;
}

.apexcharts-legend.position-bottom.apexcharts-align-center, .apexcharts-legend.position-top.apexcharts-align-center {
    display: none;
}

.apexcharts-bar-area {
    width: 10px;
}

.fc__tooltip.fusioncharts-div {
    font-family: "Inter", sans-serif !important;
    width: 250px;
    height: 149px;
    background: #f3f3f3;
    /*max-width: 200px!important;*/

}

.tooltip_opportunities {
    display: grid;
    grid-gap: 5px;
    border-bottom: 1px solid #E2E8F0;
}

.funnel_type {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #475569;
}

.funnel_label {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px;
    color: var(--gitgo-blue);
}

.tooltip_block {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    height: 150px;
    width: 250px;
    border-radius: 5px;
    box-shadow: 2px 2px 6px -4px #999;
    padding: 15px;
}

.tooltip_block span {
    padding: 10px;
}

.count_block_tooltip {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}

.count_number {
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 39px;
    color: var(--gitgo-blue)
}

.record_count {
    border-left: 1px solid #475569;
    padding-left: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #475569;
    height: 10px;
}


.header_tooltip {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #475569;
}

.footer_percent {
    display: flex;
    border-top: 1px solid #E2E8F0;
}

#zingchart-react-0-menu {
    display: none !important;
}

#my_Chart {
    width: 400px !important;
    height: 320px !important;
}

#my_Chart-menu {
    display: none !important;
}

#my_Chart-top {
    position: initial;
}

.funnel-block {
    display: grid;
    justify-content: center;
}

.zc-rel {
    position: relative;
    left: 6px;
}

.blocks-section {
    margin-top: 20px;
    display: grid;
}

.block {
    padding-bottom: 5px;
    display: flex;
    grid-gap: 40px;
    justify-content: space-between;
    align-items: self-end;
    border-bottom: 1px solid #E2E8F0;
}

.block .name-of-category {
    width: 40px;
    height: 40PX;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #475569;
}

.center-funnel {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.center-funnel .value-count {
    position: absolute;
    z-index: 100;
    /* inset: 25% 47%; */
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.tooltip-funnel {
    padding: 16px;
    display: none;
    visibility: hidden;
    text-align: center;
    position: absolute;
    left: 70%;
    top: -20%;
    background: #FFFFFF;
    border: 1px solid #F8FAFC;
    box-sizing: border-box;
    box-shadow: 0 0 1px rgba(15, 23, 42, 0.06), 0 20px 25px -5px rgba(15, 23, 42, 0.1), 0px 10px 10px -5px rgba(15, 23, 42, 0.04);
    border-radius: 8px;
    z-index: 110;
    width: 244px;
    height: 150px;
    opacity: 0.1;
    transition: opacity 0.3s;

}
.modal-tool{
    display: grid;
    text-align: left;  transition: height 2s,visibility 0s 1.5s;

}
.center-funnel:hover .tooltip-funnel {
    visibility: visible;
    display: grid;
    opacity: 1;
    /*transition: all 0.5s ease-out;*/


}
.tooltip-funnel .header-text-tooltip{
    display: flex;
    flex-direction: column;
}
.tooltip-funnel .header-text-tooltip .border-modal{
    padding-top: 8px;
    border-bottom: 1px solid #E2E8F0 ;
}
.center-funnel .title-modal-text{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #475569;
}
.center-funnel .title-modal-value{
    display: block;
    margin-top: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #01426A;
}
.center-funnel .title-count-value{
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 39px;
    color: #01426A;
}
.center-funnel .footer-tooltip-counter{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}
.center-funnel .footer-tooltip-counter .right-count-side .record-count{
    border-left: 1px solid #475569;
    padding-left: 5px;
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #475569;
}
.center-funnel .footer-tooltip-counter .right-count-side {
    display: grid;
    align-self: center;
}
@media (max-width: 1370px) {
    #my_Chart {
        width: 400px !important;
        height: 320px !important;
    }
}

@media (max-width: 1220px) {
    #my_Chart {
        width: 400px !important;
        height: 320px !important;
    }

}
.dashboard_header span {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    color: var(--gitgo-blue);
}
  
.dashboard_header-btn_download{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;   
    background: #01426A;
    border-radius: 2px;
    width: 190px;
    height: 48px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}
 
.dashboard_total_blocks {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px 30px;
}

.rating_of_month {
    display: grid;
    grid-template-columns: repeat(3, -webkit-max-content);
    grid-template-columns: repeat(3, max-content);
    grid-gap: 8px;
    align-items: center;
}

.total_stat_block {
    border: 1px solid #ffffff;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 12px;
    position: relative;
    display: grid;
    grid-template-columns: 1fr -webkit-max-content;
    grid-template-columns: 1fr max-content;
    grid-gap: 1rem;
    align-items: center;
    transition: 0.4s;
    cursor: pointer;
}
.total_stat_block:hover {
    border: 1px solid var(--gitgo-blue);
}
.total_text {
    text-transform: capitalize;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #475569;
}

.value_total {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: var(--gitgo-blue);
}

.period_text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #94A3B8;
}

.procent_number {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
}

.left_block_side {
    display: grid;
    grid-gap: 4px;
}

.ball_content {
    position: relative;
    border-radius: 50%;
    width: 5em;
    height: 5em;
    background-color: #EDFCF5;
    display: grid;
    align-items: center;
    justify-content: center;
}


.total_opportunities_block {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
}

.graph_wrapper {
    margin-top: 32px;
}

.travel_opportunities_block {
    width: 100%;
    max-width: 505px;
    position: relative;
    padding: 20px;
    background: #FFFFFF;
    border-radius: 8px;
    height: -webkit-max-content;
    height: max-content;
}

.content_block {
    padding: 0 24px 20px;
}

.opportunities_title span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: var(--gitgo-blue);
}

.steps_of_opportunities {
    margin-top: 40px;
}

.step_line {
    position: relative;
    margin-top: 13px;
    display: flex;
    border-bottom: 1px solid #E2E8F0;
    justify-content: space-between;
}

.step_line span {
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #475569;
    align-self: flex-end;
}

.prospect_status_block {
    padding: 20px 0;
    width: 100%;
    max-width: 505px;
    height: 400px;
    background: var(--gitgo-blue);
    border-radius: 8px;
}

.prospect_status_title span {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #F8FAFC;
}

.last_step_block {
    margin-right: 18px !important;
}

.line_grid {
    margin-top: 64px;
    display: grid;
    grid-gap: 117px;
}

.status_line_graph {
    display: flex;
    align-items: center;
}

.status_line_ball div {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: #1DBF73;
}

.status_line_ball span {
    margin-left: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #F8FAFC;
}

.status_line_ball {
    display: flex;
    align-items: center;
}

.status_line_graph span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF;
}

.status_line_graph div {
    border-bottom: 1px solid #E2E8F0;
    width: 100%;
    margin-left: 5px;
}

.prospect_status {
    display: flex;
    margin-left: 100px;
    grid-gap: 60px;
}

.recent_engagement_block {
    width: 100%;
    max-width: 505px;
    background: #FFFFFF;
    border-radius: 8px;
    height: 400px;
}

.recent_engagement_title {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E2E8F0;
    padding: 20px 0;
}

.engagement_list {
    height: 340px;
    overflow: hidden;
}

.view_all_engagements_block {
    position: relative;
    cursor: pointer;
    align-items: center;
}
.view_all_engagements_block span{
    text-decoration:underline;
}

.recent_engagement_title span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--gitgo-blue);
}
.recent_engagement_title img {
   margin-left:5px;
}
.recent_engagement_export-data{
    margin-left: 40%;
}
.recent_engagement_export-dataBtn{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;   
    background: #01426A;
    border-radius: 2px;
    width: 153px;
    height: 36px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
  
}
.engagement_line {
    align-items: center;
    display: flex;
    padding: 12px 0;
    justify-content: space-between;
    border-bottom: 1px solid #E2E8F0;
}

.left_engagement_side {
    display: flex;
}

.user_data {
    margin-left: 10px;
    display: grid;
    grid-gap: 5px;
    padding: 5px 0;

}

.user_name {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #475569;
}

.company_name {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #475569;
}

.logged_type {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #94A3B8;
}

.date_of_engagement span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #94A3B8;
}

.step_line span {
    padding: 5px;
}

.lines_chart {
    position: absolute;
    display: grid;
    top: 195px;
    left: 35px;
    width: 450px;
}

.second_line {
    margin-top: 42px;
}

.third_line {
    margin-top: 0;
}

.funnel_columns .funnel_chart {
    /* position: absolute; */
    inset: 45px 45px;
}

.fourth_line {
    margin-top: 0;
}

#my_Chart {
    width: 490px;
    height: 350px;
}

.recent_engagement_wrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3550;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}
.recent_engagement_wrapper_no-blur {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3550;
}

.recent_activity_modal_window {
    position: fixed;
    max-width: 817px;
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 3100;
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    box-sizing: border-box;
    border-radius: 8px;
    max-height: 75vh;
    /*height: 75vh;*/
}

.fixed_modal_header {
    border-radius: 10px;
    padding: 30px 24px 0;
    position: fixed;
    width: 100%;
    background-color: #FFFFFF;
}
.fixed_modal_header .status-filter__innerList{
    padding: 27px 0;
    border-bottom: 1px solid #E2E8F0;
}
.modal_list_of_activity {
    margin-top: 140px;
    border-radius: 8px;
    overflow: auto;
    height: 55vh;
    padding: 1.5rem 1.5rem 20px;
}

.recent_engagement_modal_header {
    align-items: center;
    display: flex;
    justify-content: space-between;
   
}

.recent_engagement_modal_header .recent_modal_title {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    color: #475569;
}

.no_activity_title {
    margin-top: 30px;
    display: grid;
    justify-content: center;
}

.no_activity_title span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(64, 73, 88, 0.71);
}

.dashboard-graphs-line-first {
    width: 100%;
    height: 1px;
    background-color: #E2E8F0;
    margin-top: 20px;
}

.no-activity-title-graphs-first {
    margin-top: -7px;
    padding-top: 30px;
    display: grid;
    justify-content: center;
    color: rgba(64, 73, 88, 0.71);
}

.dashboard-graphs-line {
    width: 100%;
    height: 1px;
    background-color: white;
    margin-top: 20px;
}

.no-activity-title-graphs {
    display: grid;
    justify-content: center;
    color: white;
    padding-top: 30px;
}

.modal-background-wrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3550;
    cursor: auto;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    -webkit-animation-name: fadeIn; /* Fade in the background */
    -webkit-animation-duration: 0.4s;
    animation-name: fadeIn;
    animation-duration: 0.4s;

}
.no-blur{
    -webkit-backdrop-filter: blur(0px);
            backdrop-filter: blur(0px);;
}

.total-statistics-modal {
    position: fixed;
    max-width: 817px;
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 3600;
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 24px;
    max-height: 61vh;
    height: 61vh;
    overflow-y: auto;
    -webkit-animation-name:slideIn;
    -webkit-animation-duration: 0.4s;
    animation-name: slideIn;
    animation-duration: 0.4s;
}

.total-statistics-modal .total-statistics-header {
    display: flex;
    justify-content: space-between;
}

.total-statistics-modal .total-statistics-header .title-of-modal-window {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    color: #475569;
    text-transform: uppercase;
}

.total-statistics-modal .total-statistics-header .close-modal-button {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #01426A;
    padding: 6px 25px;
    border: 1px solid #01426A;
}

.total-statistics-modal .percentage-counter-of-total {
    padding: 0 30px 0;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.total-statistics-modal .percentage-counter-of-total.no_activity {
    grid-template-columns: 180px 180px 180px;
    display: grid;
    justify-content: center;
    justify-items: center;
}
.total-statistics-modal .percentage-counter-of-total.center-block {
    justify-content: space-around;
}

.total-statistics-modal .percentage-counter-of-total .grand-total span {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 34px;
    color: #01426A;
}
.total-statistics-modal .percentage-counter-of-total .grand-total .percentage-count-line {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.total-statistics-modal .percentage-counter-of-total .grand-total p {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #94A3B8;
}

.total-statistics-modal .percentage-counter-of-total .grand-total {
    display: grid;
    justify-content: center;
    text-align: center;
}

.total-statistics-modal .modal-statistics-list {
    margin-top: 40px;
}


.total-statistics-modal .modal-statistics-list .titles-modal-header {
    display: grid;
    grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr);
    background-color: #F8FAFC;
    padding: 9px 20px;
}
.total-statistics-modal .modal-statistics-list .titles-modal-header-opportunity {
    display: grid;
    grid-template-columns: minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr);
    background-color: #F8FAFC;
    padding: 9px 20px;
}
.total-statistics-modal .modal-statistics-list .titles-modal-header.no_activity ,
.total-statistics-modal .modal-statistics-list .titles-modal-header-opportunity.no_activity {
    grid-template-columns: minmax(208px, 1fr) minmax(80px, 1fr) ;
}

.total-statistics-modal .modal-statistics-list .titles-modal-header .item ,
.total-statistics-modal .modal-statistics-list .titles-modal-header-opportunity .item{
    display: grid;
    grid-template-columns: -webkit-max-content 1fr;
    grid-template-columns: max-content 1fr;
    cursor: pointer;
}

.total-statistics-modal .modal-statistics-list .titles-modal-header .item span ,
.total-statistics-modal .modal-statistics-list .titles-modal-header-opportunity .item span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #0F172A;
}
.no-items{
    display: flex;
    justify-content: center;
    color: #475569;
    font-size: 20px;
    font-weight: bold;
    padding-top: 30px;
}

.total-statistics-modal .modal-statistics-list .modal-list-wrapper {
    display: grid;
}

.total-statistics-modal .modal-statistics-list .block-of-status-data .activity-modal-list .modal-list-line {
    display: grid;
    height: 55px;
    padding: 20px 20px 16px 20px;
    border-bottom: 1px solid #E2E8F0;
    grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr);
    align-content: center;
    grid-gap: 15px;
    gap: 15px;
}
.total-statistics-modal .won-opportunities-wrapper .modal-statistics-list .activity-modal-list .modal-list-line {
    display: grid;
    padding: 20px 20px 16px 20px;
    border-bottom: 1px solid #E2E8F0;
    grid-template-columns: minmax(200px, 1fr) minmax(100px, 1fr) minmax(200px, 1fr);
    align-content: center;
}
.total-statistics-modal .won-opportunities-wrapper .modal-statistics-list .activity-modal-list .modal-list-line.no_activity {
    grid-template-columns: minmax(200px, 1fr) minmax(100px, 1fr);
}

.total-statistics-modal .modal-statistics-list .activity-modal-list .modal-list-line .name-of-user {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #01426A;
    cursor: pointer;
}

.total-statistics-modal .modal-statistics-list .activity-modal-list .modal-list-line .revenue-count {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #475569;
    opacity: 0.5;
}

.total-statistics-modal .modal-statistics-list .activity-modal-list .modal-list-line .status-button {
    font-style: normal;
    text-align: start;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #01426A;
    background-color: rgba(1, 66, 106, 0.1);
    padding: 4px 8px;
    display: flex;
    width: -webkit-max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    height: 25px;
    text-transform: capitalize;
    cursor: auto;
}

.total-statistics-modal .modal-statistics-list .view-all-footer-block {
    margin-top: 30px;
    display: grid;
    grid-template-columns: -webkit-max-content 1fr;
    grid-template-columns: max-content 1fr;
    justify-content: center;
    align-items: center;
    grid-gap: 15px;
}
.total-statistics-modal .modal-statistics-list .footer-modal {
    display: grid;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
}

.total-statistics-modal .modal-statistics-list .view-all-footer-block .view-all-text {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #01426A;
}

.total-statistics-modal .modal-status-list {
    padding: 33px 22px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    max-width: 812px;
    justify-content: center;
}

.total-statistics-modal .modal-status-list .new-status-column {
    display: grid;
    justify-items: center;
    border-right: 1px solid #E2E8F0;
    align-content: flex-start;

}
.total-statistics-modal .modal-status-list .new-status-column.last-closed{
    border-right: none;
}


.total-statistics-modal .modal-status-list .new-status-column .color-status-block {
    display: grid;
    padding: 8px 10px;
    width: 100%;
    height: 70px;
    justify-content: center;
    cursor:pointer;
}

.total-statistics-modal .modal-status-list .new-status-column .color-status-block span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    color: #FFFFFF;
    text-align: center;
}

.total-statistics-modal .modal-status-list .new-status-column .new-column-users {
    padding: 10px 40px;
    border-bottom: 1px solid #E2E8F0;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    align-items: center;
    color: #01426A;
    cursor: pointer;
    text-align: center;
}

.total-statistics-modal .modal-status-list .new-status-column .color-status-block.new-status {
    background: #FFB648;
}

.total-statistics-modal .modal-status-list .new-status-column .color-status-block.property-status {
    background: #F57C1F;
}

.total-statistics-modal .modal-status-list .new-status-column .color-status-block.ready-status {
    background: #4BDE97;
}
.total-statistics-modal .modal-status-list .new-status-column .color-status-block.inactive-status {
    background: #F26464;
}
.total-statistics-modal .modal-status-list .new-status-column .color-status-block.existing-status {
    background: #49BACA;
}
.total-statistics-modal .view-all-footer-block{
    display: grid;
    grid-gap: 10px;
    align-items: center;
    grid-template-columns: -webkit-max-content 1fr;
    grid-template-columns: max-content 1fr;
    justify-content: center;
}
.total-statistics-modal .footer-modal{
    margin-top: 25px;
    display: grid;
    justify-content: center;
}
.total-statistics-modal .view-all-footer-block .view-all-text{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    color: #01426A;
    cursor: pointer;
    text-decoration: underline;
}
.modal-statistics-list .view-all-footer-block .discover-opportunities{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    padding: 6px 16px;
    background-color: #FFB648;
    cursor: pointer;
}
.total-statistics-modal .modal-statistics-list .engagement-wrapper-activity  {
    padding: 0 30px 0;
    justify-content: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
}
.total-statistics-modal .modal-statistics-list .engagement-wrapper-activity .contact-title  {
    display: grid;
    grid-gap: 20px;
    height: 50px;
    width: 250px;
}
.total-statistics-modal .modal-statistics-list .engagement-wrapper-activity .contact-title.prospect {
    display: grid;
    grid-gap: 27px;
    width: 250px;
}
.total-statistics-modal .modal-statistics-list .engagement-wrapper-activity .contact-title .title-block {
    border-bottom: 1px solid #4E5D78;
    padding: 5px 0 ;
}
.total-statistics-modal .modal-statistics-list .engagement-wrapper-activity .contact-title .title-engagement {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #13426B;
}
.total-statistics-modal .modal-statistics-list .engagement-wrapper-activity .contact-title .contacts-block {
    display: grid;
    grid-template-columns: 1fr -webkit-max-content;
    grid-template-columns: 1fr max-content;
    align-items: center;
    cursor: pointer;
}
.total-statistics-modal .modal-statistics-list .engagement-wrapper-activity .contact-title .contacts-block .logo-contacts-side {
    display: grid;
    grid-template-columns: -webkit-max-content 1fr;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 10px;
}
.total-statistics-modal .modal-statistics-list .engagement-wrapper-activity .contact-title .contacts-block.prospect-section {
    border-bottom: 1px solid #13426B;
}
.total-statistics-modal .modal-statistics-list .engagement-wrapper-activity .contact-title .contacts-block span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #475569;
    text-transform: capitalize;
}

.total-statistics-modal .modal-statistics-list .engagement-wrapper-activity .contact-title .contacts-block .last-engaged {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #94A3B8;
}
.total-statistics-modal .modal-statistics-list .engagement-wrapper-activity .contact-title .contacts-block .prospect-name {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #13426B;
}
.total-statistics-modal .modal-statistics-list .engagement-wrapper-activity .contact-title.prospect .contacts-block .prospect-name {
    padding: 0 0 5px;
    cursor: pointer;
}
.total-statistics-modal .modal-statistics-list .engagement-wrapper-activity .contact-title .contacts-block button {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    border-radius:50%;
    background-color:#01426A ;
    width: 32px;
    height: 32px;
    cursor: auto;
}

@media (max-width: 1680px) {
    .funnel_columns .funnel_chart {
        inset: 45px 20px;
    }
}
@media (max-width: 1650px) {
    .total_opportunities_block {
        grid-template-columns: 1fr 1fr;
    }
    .funnel_columns .funnel_chart {
        inset: 45px 45px;
    }

    .travel_opportunities_block {
        order: 0;
    }

    .prospect_status_block {
        order: 1;
    }
}

@media (max-width: 1450px) {
    .prospect_status_block {
        width: 100%;
    }

}

@media (max-width: 1450px) {
    .total-statistics-modal {
        max-height: 71vh;
        height: 71vh;
    }

}
@media (max-width: 1331px) {
    .prospect_status_block,
    .travel_opportunities_block {
        width: 410px;
        height: -webkit-max-content;
        height: max-content;
    }

    .mixed-chart {
        inset: 22px 0;
    }

    .line_columns {
        padding: 0 0 0 10px;
    }

    .funnel_columns .funnel_chart {
        position: relative;
        inset: 0 0;
    }
    .total-statistics-modal .modal-statistics-list .modal-list-wrapper {
        display: grid;
        max-height: 19vh;
        height: 19vh;
        overflow: auto;
    }

}

.loader {
    display: inline-flex;
    position: relative;
    justify-content: center;
    width: 40px;
    height: var(--wrapper_size);
    margin: 0 auto;
}

.loader div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: var(--size);
    height: var(--size);
    margin: var(--margin);
    border: 6px solid;
    border-radius: 50%;
    -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--bgColor) transparent transparent transparent;
}

.loader div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
}

.loader div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
}

.loader div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
}

@-webkit-keyframes lds-ring {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes lds-ring {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.custom_select_wrapper {
    position: relative;
    width: minmax(100px, -webkit-max-content);
    width: minmax(100px, max-content);
}

.custom_select_wrapper > .title {
    font-size: 14px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    display: block;
    margin: 0 0 4px;
}

.prospects_custom_select_wrapper > .title {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    display: block;
    margin: 0 0 4px;
}

.targeting_custom_select_wrapper > .title, .targeting_custom_select_wrapper > .title span {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    display: block;
    margin: 0 0 4px;
}

.custom_select_wrapper > .select_content {
    display: grid;
    grid-template-columns: 1fr -webkit-max-content;
    grid-template-columns: 1fr max-content;
    grid-gap: 10px;
    padding: 8px 16px;
    align-items: center;
    border: 1px solid #E2E8F0;
    border-radius: 2px;
    cursor: pointer;
    text-transform: capitalize;
}

.custom_select_wrapper > .select_content span {
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
}

.custom_select_wrapper .select_popup, .multiply_select_wrapper .select_popup {
    position: absolute;
    top: 2.5rem;
    background: #FFFFFF;
    box-shadow: 0 0 1px rgba(15, 23, 42, 0.06), 0 10px 15px -3px rgba(15, 23, 42, 0.1), 0 4px 6px -2px rgba(15, 23, 42, 0.05);
    border-radius: 6px;
    display: grid;
    grid-template-columns: 1fr;
    max-height: 0;
    left: -10px;
    transition: .3s;
    overflow-y: hidden;
    z-index: 100;
}

.prospects_custom_select_wrapper .select_popup {
    top: 4rem;
}

.pagination_popup .select_popup {
    top: 3.5rem;
}

.pagination_popup .select_content {
    background: #FFFFFF;
    border: 1px solid #E2E8F0;
    padding: 12px 16px;
    border-radius: 2px;
}

.custom_select_wrapper .select_popup_active, .multiply_select_wrapper .select_popup_active {
    max-height: 200px;
    overflow-y: auto;
}

.custom_select_wrapper .select_popup_active_max_height {
    max-height: 150px;
}

.custom_select_wrapper .select_popup .nested {
    display: grid;
    grid-template-columns: -webkit-max-content 1fr;
    grid-template-columns: max-content 1fr;
    grid-gap: 8px;
    align-items: center;
    padding: 8px;
    transition: .3s;
    cursor: pointer;
    margin: 4px;
}

.custom_select_wrapper .select_popup .nested .option_color {
    width: 8px;
    height: 8px;
    border-radius: 100%;
}
.select_content .center{
    display: flex;
    justify-content: center;
    align-items:center;
}
.custom_select_wrapper .select_popup span {
    padding: 8px;
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    transition: .3s;
    border-radius: 2px;
    cursor: pointer;
    margin: 4px;
    text-transform: capitalize;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.custom_select_wrapper .select_popup .nested span {
    padding: 0;
    margin: 0;
}

.custom_select_wrapper .select_popup span:hover {
    background: #F2F8FD;
}

.custom_select_wrapper .select_popup .nested span:hover {
    background: unset;
}

.custom_select_wrapper .select_popup .nested:hover {
    background: #F2F8FD;
}

.custom_select_wrapper > .error_text {
    font-size: 14px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    display: grid;
    margin: 0 0 4px;
}
.prospects_wrapper .title {
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;
    margin: 0 0 1.5rem;
}

.prospects_wrapper .prospects_data {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: space-between;
    grid-gap: 30px;
    margin: 0 0 1.5rem;
}

.prospects_wrapper .prospects_data .item {
    border: 1px solid #ffffff;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 12px;
    display: grid;
    grid-template-columns: 1fr -webkit-max-content;
    grid-template-columns: 1fr max-content;
    grid-gap: 1rem;
    align-items: center;
    transition: 0.4s;
}
/*.prospects_wrapper .prospects_data .item:hover {*/
/*  border: 1px solid var(--gitgo-blue);*/
/*}*/

.prospects_wrapper .prospects_data .item .icon {
    width: 69px;
    height: 69px;
    display: grid;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: #FFF8ED;
}
.prospects_wrapper .dashboard_total_blocks {
    margin-bottom: 20px;
}

.prospects_wrapper .prospects_data .item .content h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
}

.prospects_wrapper .prospects_data .item .content p {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0 0 4px;
}

.prospects_wrapper .prospects_data .item .content .inline {
    display: grid;
    grid-template-columns: repeat(3, -webkit-max-content);
    grid-template-columns: repeat(3, max-content);
    grid-gap: 8px;
    align-items: center;
}

.prospects_wrapper .prospects_data .item .content .inline > p {
    font-size: 14px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #4BDE97;
    margin: 0;
}

.prospects_wrapper .prospects_data .item .content .inline > span {
    font-size: 12px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #94A3B8;
}


.prospects_wrapper .prospects_content .prospects_container > .head .item {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    cursor: pointer;
}

.prospects_wrapper .prospects_content .prospects_container > .head .item:nth-child(6) {
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
}

.prospects_wrapper .prospects_content .prospects_container > .container_body .item .choose_item_wrap {
    display: grid;
    align-items: center;
    cursor: pointer;
    padding: 4px;
}

.prospects_wrapper .prospects_content .prospects_container > .container_body .item .choose_item {
    display: grid;
    align-items: center;
    justify-content: center;
    border: 2px solid #478ECC;
    width: 1rem;
    height: 1rem;
}

.prospects_wrapper .prospects_content .prospects_container > .container_body .item .choose_item svg {
    -webkit-transform: scale(.8) rotate(-20deg);
            transform: scale(.8) rotate(-20deg);
    opacity: 0;
    transition: .3s;
}

.prospects_wrapper .prospects_content .prospects_container > .container_body .item .choose_item_active svg {
    -webkit-transform: scale(1) rotate(0);
            transform: scale(1) rotate(0);
    opacity: 1;
}

.prospects_wrapper .prospects_content .prospects_container > .head {
    padding: 17px 50px 16px 60px;
    /* grid-template-columns: 200px minmax(120px, 1fr) minmax(120px, 1fr)  minmax(120px, 1fr) minmax(122px, 1fr)  minmax(85px, 1fr)  minmax(90px, 2fr) minmax(251px, 1fr) minmax(107px, 1fr) minmax(231px, 1fr); */
    grid-gap: 1%;
    grid-template-columns: 16.2% 13.6% 11.6% 10.9% 9.1% 8.3% 11.6% 14.6%;
    display: grid;
    border-bottom: 1px solid #E2E8F0;
    align-items: center;
    width: auto;
    /* width: -moz-fit-content; */
}

.prospects_wrapper .prospects_content .prospects_container > .container_body .item {
    padding: 17px 30px 16px 20px;
    grid-template-columns: 1.8% 16% 13.5% 11.5% 10.8% 9.1% 8.2% 11.5% 9.8% 1.8% ;
    /* grid-template-columns: 200px  minmax(200px, 1fr)  minmax(120px, 1fr)  minmax(120px, 1fr)   minmax(122px, 1fr)  minmax(85px, 1fr)  minmax(90px, 2fr) minmax(252px, 1fr) minmax(210px, 1fr) minmax(117px, 1fr); */
    grid-gap: 0.65%;
    display: grid;
    border-bottom: 1px solid #E2E8F0;
    align-items: center;
    width: auto;
    /* width: -moz-fit-content; */
}

/* .prospects_wrapper .prospects_content .prospects_container > .container_body .item:last-of-type {
    padding: 17px 2.75rem 16px 50px;
    border-bottom: none;
} */
.status-filter{
    display: flex;
    width: -webkit-max-content;
    width: max-content;
    border: 1px solid #01426A;
    align-items:center;
    padding: 2px 5px 2px 10px;
    border-radius: 5px;
}
.status-filter .custom_select_wrapper > .select_content{
    border: none;
    display: flex;
    grid-template-columns: -webkit-max-content -webkit-max-content;
    grid-template-columns: max-content max-content;
    
    padding: 1px 10px;
    grid-gap: 5px;
}
.status-filter .custom_select_wrapper .select_popup_active, .multiply_select_wrapper .select_popup_active{
    top: 2.5em;
    left: -10px;
}
.status-filter .custom_select_wrapper .select_popup span {
    color: #01426A!important;
}
.mt-37{
    margin-top: 37px;
}
.status-filter .custom_select_wrapper > .select_content span{
    color: #01426A;
    font-size: 15px;
    background-color: rgba(1, 66, 106, 0.1);
    padding: 4px 8px;
    /* display: flex; */
    width: 200px;
    /* justify-content: center;
    align-items: center; */
    border-radius: 2px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.status-filter span{
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 17px;
    color: #01426A;
}
.status-filter .status_popup{
    border: none;
    width: minmax(100px, -webkit-max-content);
    width: minmax(100px, max-content);
}

@media (max-width: 1550px) {
    .prospects_wrapper .prospects_content .prospects_container > .container_body .item {
        grid-template-columns: 20px 185px 155px 130px 130px 100px 100px 130px 110px 25px;
        grid-gap:8px;
        gap:8px;
    }
    .prospects_wrapper .prospects_content .prospects_container > .head {
        padding: 17px 50px 16px 45px;

        grid-gap: 8px;
        grid-template-columns: 188px 155px 130px 130px 100px 100px 130px 110px 25px;
    }
    
}

@media (min-width: 1900px) {
    .prospects_wrapper .prospects_content .prospects_container > .head, .prospects_wrapper .prospects_content .prospects_container > .container_body .item {
        width: 100%;
    }
}
.page_block_all{
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    background: #FFFFFF;
    border: 1px solid #E2E8F0;
    padding: 12px 16px;
    border-radius: 2px;
    transition-duration: 1s;
}
.page_block_all span{
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    transition-duration: 1s;
}
.page_block_all .drop_block{
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    transition-duration: 1s;
    overflow: hidden;
}
.cursor{
    cursor: pointer;
}
.modal_window_bg {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3550;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

.modal_window_content {
    position: fixed;
    max-width: 583px;
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 3100;
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    border-radius: 8px;
    padding: 2rem 2rem 2.75rem;
}

.delete_modal_window {
    max-width: 489px;
    padding: 1.5rem 6rem 2rem;
}

.modal_window_content h4 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.45;
    margin-bottom: 8px;
    color: var(--dark-grey);
}

.modal_window_content h5 {
    font-size: 20px;
    line-height: 30px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    margin: 0 0 .75rem;
    text-align: center;
}

.modal_window_content p {
    font-size: 14px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0 0 1.5rem;
    text-align: center;
}

.modal_window_content .error_message {
    color: var(--error);
    font-size: 14px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    display: block;
    margin: .5rem 0 0;
}

.modal_window_buttons {
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    grid-gap: 1.5rem;
    align-items: center;
    justify-content: space-between;
    margin: 1.5rem 0 0;
}

.modal_window_content .delete_modal {
    padding: 0;
}

.modal_window_content .delete_modal > span {
    font-size: 20px;
    line-height: 30px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--error);
    text-align: center;
    display: block;
    margin: 0 0 4px;
}

.modal_window_content .delete_modal h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    margin: 0 0 1.75rem;
}

.modal_window_content .delete_modal p {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0;
}

.modal_window_content .delete_modal .modal_window_buttons {
    justify-content: center;
    grid-gap: 1rem;
    margin: 1.75rem 0 0;
}

.modal_window_content .delete_modal .modal_window_buttons button {
    width: 5rem;
}

.modal_window_content .delete_modal .modal_window_buttons .cancel_button {
    color: #0F172A;
    border: 1px solid #475569;
}

.review-subscription-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.review-subscription-title {
    width: 375px;
    font-size: 20px;
    font-weight: 600;
    color: #0F172A;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 24px;
}

.email-gitgo-support {
    font-size: 14px;
    font-weight: 400;
    color: #475569;
    padding: 0 50px;
    margin-top: 12px;
}

.got-it-button {
    display: flex;
    justify-content: space-between;
    width: auto;
}

.got-it-button button {
    width: 204px;
    height: 36px;
    background-color: var(--gitgo-blue);
    color: #FFFFFF;
    border: 1px solid var(--gitgo-blue);
    padding: 0 12px;
}
.changed_confirmed_popup_un_active,
.changed_confirmed_popup {
    position: fixed;
    display: grid;
    justify-content: center;
    left: 0;
    right: 0;
    z-index: 10000;
    bottom: 16px;
    opacity: 0;
    visibility: hidden;
    transition: .4s;
}

.changed_confirmed_popup {
    opacity: 1;
    visibility: visible;
}

.changed_confirmed_popup_un_active .changing_confirmed span,
.changed_confirmed_popup .changing_confirmed span {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    font-family: 'Playfair Display', sans-serif;
    letter-spacing: 0.02em;
    color: #333333;
    padding-left: 16px;
    margin: 0;
}

.changed_confirmed_popup_un_active .changing_confirmed img,
.changed_confirmed_popup .changing_confirmed img {
    height: 28px;
}

.changed_confirmed_popup_un_active .changing_confirmed,
.changed_confirmed_popup .changing_confirmed {
    background: #fff;
    display: flex;
    align-items: center;
    padding: 14px 20px;
    box-shadow: 4px 4px 32px rgba(155, 155, 155, 0.3);
    border-radius: 2px;
}

.changed_confirmed_popup_un_active {
    visibility: hidden;
}
.upload-box {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    max-height: 70vh;
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    border: 1px solid #CBD5E1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.upload-buttons-container {
    display: flex;
}

.upload-buttons-container button:first-child {
    cursor: pointer;
    background: #FFFFFF;
    border: 1px solid var(--gitgo-blue);
    border-radius: 2px;
    width: 80px;
    height: 36px;
    font-size: 14px;
    font-weight: 700;
    color: var(--gitgo-blue);
    font-family: 'Inter', sans-serif;
    margin-right: 8px;
    justify-content: center;
}

.upload-buttons-container button:last-child {
    cursor: pointer;
    background: var(--gitgo-blue);
    border-radius: 2px;
    width: 80px;
    height: 36px;
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF;
    font-family: 'Inter', sans-serif;
    margin-left: 8px;
    justify-content: center;
}

.filedrop {
    width: 338px;
    height: 100px;
    border: 1px solid #CBD5E1;
    text-align: center;
    font-size: 24px;
    padding: 24px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filedrop.drag {
    border: 3px dashed #1e90ff;
}

.filedrop.ready {
    border: 3px solid #32cd32;
}

.file-upload-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.uploadFileText {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}

.upload-title-text {
    font-size: 20px;
    font-weight: 400;
    color: #0F172A;
    margin-bottom: 16px;
}

.upload-info-text {
    font-size: 14px;
    font-weight: 400;
    color: #475569;
    margin-bottom: 16px;
}

.upload-download-template-text {
    font-size: 14px;
    font-weight: 400;
    color: var(--gitgo-blue);
    margin-bottom: 32px;
    margin-top: 32px;
}
.create_work_wrapper {
    min-height: 100vh;
    background: #FFFFFF;
    margin: -32px -2.25rem -40px -2.75rem;
    padding: 32px 2.25rem 40px 2.75rem;
}

.create_work_wrapper h2 {
    margin: 0 0 2rem;
}

.create_work_wrapper h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0 0 1rem;
}

.create_work_wrapper h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0 0 1rem;
}

.create_work_wrapper form {
    max-width: 730px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
}

.create_work_wrapper form > .head_content, .create_work_wrapper form > .body_content {
    padding: 0 0 1.75rem;
    border-bottom: 1px solid #E2E8F0;
    margin: 0 0 1.75rem;
}

.create_work_wrapper form > .head_content > .full {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    margin: 0 0 1.5rem;
}

.create_work_wrapper form > .head_content > .inline {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    grid-gap: 30px;
}

.create_work_wrapper .buttons_wrapper {
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 1rem;
    justify-content: right;
}
.input_component {
    position: relative;
}

.input_component span {
    font-size: 14px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    display: grid;
    margin: 0 0 4px;
}

.input_component span.input_component_title_new {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    margin: 0 0 .25rem;
}

.input_component span.prospects_input_title {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    margin: 0 0 .25rem;
}

.input_component span.targeting_input_title, .input_component span.targeting_input_title span {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    margin: 0 0 .25rem;
}

.input_search > div {
    position: relative;
}

.input_search .searchImage {
    /*background-image: url("../assets/search_icon.svg");*/
    background-repeat: no-repeat;
    background-size: cover;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 13px;
    top: 9px;
}

.dropdown_component_wrapper {
    position: relative;
    cursor: pointer;
}

.dropdown_component_wrapper input {
    cursor: pointer;
}

.dropdown_component_wrapper .dropdown_image {
    position: absolute;
    right: 16px;
    top: 35px;
}

.dropdown_component_wrapper .dropdown_component_opened {
    position: absolute;
    left: 0;
    top: 40px;
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid #E2E4E9;
    box-sizing: border-box;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    overflow-y: scroll;
    z-index: 999;
}

.dropdown_component_wrapper .dropdown_component_opened > span,
.dropdown_component_wrapper .dropdown_component_opened label {
    width: 100%;
    padding: 8px 14px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: var(--slate);
}

.input_component label {
    margin-bottom: unset;
    position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
}

.input_component label.input_search_wrap input::-webkit-input-placeholder, .input_component label.input_search_pages input::-webkit-input-placeholder {
    color: #94A3B8;
}

.input_component label.input_search_wrap input::placeholder, .input_component label.input_search_pages input::placeholder {
    color: #94A3B8;
}

.input_component label.input_search_wrap input, .input_component label.input_search_pages input {
    padding: 8px 50px 8px 1rem;
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #000;
    background: #FFFFFF;
    outline: 1px solid #E2E8F0;
    border-radius: 2px;
    border: none;
    width: 100%;
    max-width: 350px;
}

.input_component label.input_search_wrap input:focus, .input_component label.input_search_pages input:focus {
    border: none;
}

.input_component label.input_search_wrap .search_icon, .input_component label.input_search_pages .search_icon {
    position: absolute;
    top: 50%;
    right: 1rem;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.input_component label.input_search_pages {
    max-width: 445px;
}

.input_component label.input_search_pages input {
    padding: 6px 50px 6px 1rem;
    max-width: 100%;
}

.dropdown_component_wrapper .dropdown_component_opened span:hover,
.dropdown_component_wrapper .dropdown_component_opened div:hover {
    background: #EAF6FD;
}

.input_search:hover .searchImage {
    /*background-image: url("../assets/search_active.svg");*/
}

.search_image_active {
    /*background-image: url("../assets/search_active.svg") !important;*/
}

.input_component {
    margin: 8px 0 4px;
}

.input_component_unset_margin {
    margin: unset;
}

.input_component .custom_input,
.input_component textarea {
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    width: 100%;
    padding: .5rem 1rem;
    border: 1px solid #E2E8F0;
    border-radius: 2px;
    transition: .3s all;
}

.input_component_error_text {
    font-size: 14px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    display: grid;
    color: var(--error);
    margin: 8px 0 0;
    padding: 0;
}

.input_component .custom_input:focus,
.input_component textarea:focus {
    border: 1px solid #475569;
}

.input_component .login_input {
    background: #FFFFFF;
    border: 1px solid #E2E8F0;
    border-radius: 2px;
    padding: 12px 8px;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.input_component .prospects_input {
    border: 1px solid #E2E8F0;
    border-radius: 2px;
    padding: .5rem 1rem;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    transition: .3s;
}

.input_component .prospects_input::-webkit-input-placeholder {
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #94A3B8;
}

.input_component .prospects_input::placeholder {
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #94A3B8;
}

.input_component .show_pass_icon {
    position: absolute;
    right: 16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    cursor: pointer;
    z-index: 15;
}

.input_component .social_icon {
    position: absolute;
    right: 8px;
    top: 10px;
    cursor: pointer;
}

.input_component textarea {
    resize: none;
    height: auto;
}

.input_search .custom_input {
    padding: 6px 11px 6px 42px;
}

.input_component .custom_input:disabled,
.input_component .textarea:disabled {
    border: none;
    color: #0F172A;
    background: transparent;
    cursor: not-allowed;
}

.input_component .login_input:disabled {
    border-bottom: 1px solid rgba(0, 0, 0, .3) !important;
    color: rgba(0, 0, 0, .3) !important;
    cursor: not-allowed;
}

.input_component .error, .input_component .wrong_input {
    border: 1px solid var(--error);
}

.error.custom_input, .error.textarea, .wrong_input.custom_input, .wrong_input.textarea {
    border: 1px solid var(--error);
}

.error:hover.custom_input, .error:hover.textarea, .wrong_input:hover.custom_input, .wrong_input:hover.textarea {
    border: 1px solid var(--error);
}

.error:focus.custom_input, .error:focus.textarea, .wrong_input:focus.custom_input, .wrong_input:focus.textarea {
    border: 1px solid var(--error);
}

.input_component .success_input {
    border: 1px solid var(--success);
}

.success_input.custom_input, .success_input.textarea {
    border: 1px solid var(--success);
}

.success_input:hover.custom_input, .success_input:hover.textarea {
    border: 1px solid var(--success);
}

.success_input:focus.custom_input, .success_input:focus.textarea {
    border: 1px solid var(--success);
}

.input_component .prospects_input_error, .input_component .prospects_input_error:hover, .input_component .prospects_input_error:focus {
    border: 1px solid var(--error);
}

.social_link_icon {
    position: absolute;
    left: 3px;
    top: -4px;
}

.new_social_link_icon {
    position: absolute;
    left: 8px;
    bottom: 3px;
}

.input_component_error_text {
    display: block;
}

.new_error_wrapper {
    display: grid;
    margin: 1rem 0 0;
    grid-template-columns: -webkit-max-content 1fr;
    grid-template-columns: max-content 1fr;
    grid-gap: 12px;
    align-items: center;
}

.new_error_wrapper > span {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--new-error);
    margin: 0;
}

.entry_error {
    margin: 8px 0 0;
}

.entry_error .error_message {
    font-weight: 500;
    font-size: 12px;
}
.new_prospect_wrapper > .navigation {
    display: grid;
    grid-template-columns: repeat(3, -webkit-max-content);
    grid-template-columns: repeat(3, max-content);
    align-items: center;
    grid-gap: 8px;
    margin: 0 0 1rem;
}

.notes { 
    margin: 20px;
    margin-top: -4px;
}

.notes-modal-text {
    color: rgb(148, 163, 184);
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.notes-modal-full-text {
    color: rgb(148, 163, 184);
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: auto;
    text-overflow: ellipsis;
}

.view_all span {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    text-decoration: underline;
}

.view_all {
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    grid-gap: 0.6rem;
    align-items: center;
    justify-content: space-between;
}

.new_prospect_wrapper > .navigation a, .new_prospect_wrapper > .navigation span, .new_opportunity_wrapper > .navigation a, .new_opportunity_wrapper > .navigation span {
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
}

.engagement_line_custom {
    align-items: center;
    display: grid;
    padding: 12px 0;
    grid-template-columns: 200px 400px 100px;
    justify-content: space-between;
    border-bottom: 1px solid #E2E8F0;
}

.new_prospect_wrapper > .navigation span, .new_opportunity_wrapper > .navigation span {
    font-weight: bold;
}

.new_prospect_wrapper h2 {
    margin: 0 0 1.5rem;
}

.new_prospect_wrapper .new_prospect_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    align-items: start;
}

.new_prospect_wrapper .new_prospect_container .basic_info {
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    padding: 1.5rem;
    width: 100%;
}

.new_prospect_wrapper .new_prospect_container .basic_info .name_info, .new_prospect_wrapper .new_prospect_container .basic_info .industry_info, .new_prospect_wrapper .new_prospect_container .basic_info .billing_info, .new_prospect_wrapper .new_prospect_container .basic_info .travel_info, .new_prospect_wrapper .new_prospect_container .basic_info .competitors_info {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    margin: 2rem 0;
    padding: 0 0 2rem;
    border-bottom: 1px solid #E2E8F0;
}

.new_prospect_wrapper .new_prospect_container .basic_info .billing_info .full_line {
    grid-column: 1/-1;
}

.new_prospect_wrapper .new_prospect_container .basic_info .buttons_wrapper {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;
    margin: 2rem 0 0;
}

.new_prospect_wrapper .new_prospect_container .basic_info .buttons_wrapper button {
    width: 100px;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap, .new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap {
    background: #FFFFFF;
    border-radius: 14px;
    border: 1px solid #CBD5E1;
    padding: 1.5rem 1rem;
    margin: 0 0 1.75rem;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .title, .new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .title {
    display: grid;
    grid-template-columns: 1fr -webkit-max-content;
    grid-template-columns: 1fr max-content;
    text-transform: capitalize;
    align-items: center;
    justify-content: space-between;
    grid-gap: 1rem;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .title .inline {
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 1rem;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .title .inline span {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    background: #BEDEF5;
    border-radius: 200px;
    padding: 0 10px;
}
.notes-label {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    margin: 0 0 .25rem;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .title h3, .new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .title h3 {
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options {
    margin: 2.5rem -1rem 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2px;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .item_title {
    padding: 6px 1rem;
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    grid-gap: 24px;
    justify-content: space-between;
    align-items: center;
    background: #F8FAFC;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .item_title span {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .item_title .open {
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: relative;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .item_title .open div {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(0);
            transform: translate(-50%, -50%) rotate(0);
    width: 14px;
    height: 2px;
    border-radius: 16px;
    background: var(--gitgo-blue);
    transition: .3s;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .item_title .open div:nth-child(2) {
    -webkit-transform: translate(-50%, -50%) rotate(90deg);
            transform: translate(-50%, -50%) rotate(90deg);
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .item_title .opened div:nth-child(2) {
    -webkit-transform: translate(-50%, -50%) rotate(0);
            transform: translate(-50%, -50%) rotate(0);
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .opportunity_content {
    padding: 6px 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
    justify-content: space-between;
    align-items: start;
    margin: 0 0 1.5rem;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .edit_opportunity_content {
    padding: 1.5rem 1rem 2rem;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
    border-bottom: 1px solid #E2E8F0;
    margin: 0 0 2rem;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .edit_opportunity_content .edit_buttons, .new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .edit_opportunity_content .edit_buttons .view_profile {
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    grid-gap: .25rem;
    align-items: center;
    justify-content: space-between;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .edit_opportunity_content .edit_buttons .view_profile {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    text-decoration: underline;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .edit_opportunity_content .half_screen {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.75rem;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .edit_opportunity_content .half_screen .title {
    margin: 0 0 .75rem;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .edit_opportunity_content .half_screen h6 {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .edit_opportunity_content .half_screen span {
    font-size: 14px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    text-transform: capitalize;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .opportunity_content .full_width {
    grid-column: 1/-1;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .buttons_wrapper {
    justify-content: space-between;
    padding: 0 1rem;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .buttons_wrapper button {
    width: 100px;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .body {
    margin: 2rem 0 0;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .body .contacts_edit {
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    margin: 1.5rem 0 0;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .body .contacts_edit .submit_button {
    width: 90px;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .body .item {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr -webkit-max-content;
    grid-template-columns: 1fr max-content;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid #E2E8F0;
    cursor: pointer;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .body .item .inline {
    display: grid;
    grid-gap: .5rem;
    grid-template-columns: 133px 1fr;
    align-items: center;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .body .item h6, .new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .body .item span, .new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .body .item p {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .body .item p {
    font-weight: 400;
    color: #475569;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .body .item span {
    font-weight: 400;
    color: #94A3B8;
    display: block;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .body .item .full {
    display: grid;
    grid-gap: .5rem;
    grid-template-columns: 1fr;
    align-items: center;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .body .view_all {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    grid-gap: 8px;
    align-items: center;
    width: -webkit-max-content;
    width: max-content;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap p.error, .new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap p.error {
    font-size: 14px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    margin: 1rem 0 0;
    color: #94A3B8;
    text-align: center;
}

.new_prospect_wrapper .new_prospect_container .edit_basic_info .name_edit {
    display: grid;
    grid-template-columns: 1fr -webkit-max-content;
    grid-template-columns: 1fr max-content;
    grid-gap: 2.5rem;
    align-items: start;
    padding: 0 0 2rem;
    border-bottom: 1px solid #E2E8F0;
}

.new_prospect_wrapper .new_prospect_container .edit_basic_info .name_edit_content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    align-items: center;
    justify-content: space-between;
}

.new_prospect_wrapper .new_prospect_container .edit_basic_info .title {
    margin: 0 0 .5rem;
}

.new_prospect_wrapper .new_prospect_container .edit_basic_info .title_inline {
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 8px;
}

.new_prospect_wrapper .new_prospect_container .edit_basic_info h6 {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
}

.new_prospect_wrapper .new_prospect_container .edit_basic_info .content span {
    font-size: 14px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    display: block;
    text-transform: capitalize;
}

.prospects-status-container {
    background-color: rgba(1, 66, 106, 0.1);
    padding: 4px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 180px;
}
.item_opportunities .w-max-c{
    width: -webkit-max-content;
    width: max-content;
}

.prospects-status-container span {
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    color: var(--gitgo-blue);
}

.new_prospect_wrapper .new_prospect_container .edit_basic_info .content span.status {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    display: block;
    background: #F2F8FD;
    border-radius: 2px;
    padding: 4px 8px;
    width: -webkit-max-content;
    width: max-content;
}

.new_prospect_wrapper .new_prospect_container .edit_basic_info .industry_edit {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    align-items: start;
    padding: 2rem 0;
    border-bottom: 1px solid #E2E8F0;
}

.new_prospect_wrapper .new_prospect_container .edit_basic_info .industry_edit .top {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
    align-items: center;
}

.new_prospect_wrapper .new_prospect_container .edit_basic_info .industry_edit .bottom {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
    align-items: center;
}

.new_prospect_wrapper .new_prospect_container .edit_basic_info .billing_edit {
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 2rem 0;
    border-bottom: 1px solid #E2E8F0;
}

.new_prospect_wrapper .new_prospect_container .edit_basic_info .travel_edit, .new_prospect_wrapper .new_prospect_container .edit_basic_info .competitors_edit {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 2rem 0;
    grid-gap: 2rem;
    border-bottom: 1px solid #E2E8F0;
}

.new_prospect_wrapper .new_prospect_container .edit_basic_info .competitors_edit {
    margin: 0 0 2rem;
}

.new_prospect_wrapper .delete_travel_opportunity {
    max-width: 100%;
    width: 100%;
    margin: 0 0 2rem;
}

@media (max-width: 1300px) {
    .new_prospect_wrapper .new_prospect_container .edit_basic_info .name_edit {
        grid-gap: 1.5rem;
        grid-template-columns: 1fr;
    }

    .new_prospect_wrapper .new_prospect_container .edit_basic_info .name_edit button {
        margin-left: auto;
        grid-column: 1;
        grid-row: 1;
    }

    .new_prospect_wrapper .new_prospect_container .edit_basic_info .name_edit_content {
        grid-template-columns: repeat(2, -webkit-max-content);
        grid-template-columns: repeat(2, max-content);
    }
}
.switch_wrapper {
    width: 100%;
    margin: 0 0 1.5rem;
}

.switch_wrapper:last-of-type {
    margin: 0 0 2rem;
}

.switch_wrapper > span.title {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    display: block;
    margin: 0 0 1rem;
}

.switch_wrapper > .switch_content {
    display: grid;
    grid-gap: 1.5rem;
    align-items: start;
    grid-template-columns: 1fr 1fr;
}

.switch_wrapper > .switch_content .block_title {
    display: grid;
    grid-gap: .5rem;
    align-items: center;
    grid-template-columns: -webkit-max-content 1fr;
    grid-template-columns: max-content 1fr;
    margin: 0 0 .5rem;
}

.switch_wrapper > .switch_content .block_title > span {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
}

.switch_wrapper > .switch_content .options_wrap {
    display: grid;
    align-items: start;
    grid-gap: 4px;
    border: 1px solid #E2E8F0;
    background: #ffffff;
    height: 218px;
    overflow-y: auto;
}

.switch_wrapper > .switch_content .options_wrap .content {
    padding: 4px;
    border-bottom: 1px solid #E2E8F0;
}

.switch_wrapper > .switch_content .options_wrap .content_full {
    height: 100%;
}

.switch_wrapper > .switch_content .options_wrap .item {
    display: grid;
    grid-template-columns: -webkit-max-content 1fr;
    grid-template-columns: max-content 1fr;
    grid-gap: 1rem;
    align-items: center;
    background: #ffffff;
    padding: 8px;
    transition: .3s;
    cursor: pointer;
    border-radius: 2px;
}

.switch_wrapper > .switch_content .options_wrap .no_item {
    background: #ffffff;
    padding: 8px;
    font-size: 14px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    display: block;
}

.switch_wrapper > .switch_content .options_wrap .error {
    font-size: 14px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #94A3B8;
    display: grid;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    text-align: center;
}

.switch_wrapper > .switch_content .options_wrap_right .item {
    grid-template-columns: 1fr -webkit-max-content;
    grid-template-columns: 1fr max-content;
}

.switch_wrapper > .switch_content .options_wrap .item:hover {
    background: #F8FAFC;
}

.switch_wrapper > .switch_content .options_wrap .item span {
    font-size: 14px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
}
.calendar_wrapper .title {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    display: block;
    margin: 0 0 4px;
}

.calendar_wrapper .ant-picker {
    width: 100%;
    padding: 8px 16px;
}

.calendar_wrapper .error {
    font-size: 14px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    display: grid;
    color: var(--error);
    margin: 8px 0 0;
    padding: 0;
}
.contacts_popup_wrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3550;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

.contacts_popup_wrapper .content {
    position: fixed;
    max-width: 817px;
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 3100;
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 1.5rem 0 20px;
    max-height: 75vh;
    height: 75vh;
    overflow: auto;
}

.contacts_popup_wrapper .content > .head, .contacts_popup_wrapper .content > .head .inline, .contacts_popup_wrapper .content .platform_contact > .head .inline {
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;
    grid-gap: 1rem;
    width: 100%;
    position: relative;
}

.contacts_popup_wrapper .content > .head {
    margin: 0 0 1.5rem;
}

.contacts_popup_wrapper .content > .head h4 {
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
}

.contacts_popup_wrapper .content > .head .inline > span {
    background: #BEDEF5;
    border-radius: 200px;
    padding: 0 1rem;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
}

.contacts_popup_wrapper .content > .head .inline button {
    width: 91px;
    padding: 5px 0;
    justify-content: center;
}

.contacts_popup_wrapper .content > .head .inline .popup {
    position: absolute;
    top: 3rem;
    width: 230px;
    right: 0;
    background: #FFFFFF;
    box-shadow: 0 0 1px rgba(15, 23, 42, 0.06), 0 20px 25px -5px rgba(15, 23, 42, 0.1), 0 10px 10px -5px rgba(15, 23, 42, 0.04);
    border-radius: 2px;
    display: grid;
    grid-template-columns: 1fr;
    max-height: 0;
    overflow: hidden;
}

.contacts_popup_wrapper .content > .head .inline .popup_active {
    max-height: 120px;
}

.contacts_popup_wrapper .content > .head .inline .popup span:hover {
    font-weight: 600;
}

.contacts_popup_wrapper .content .platform_contact > .head {
    border-bottom: 1px solid #E2E8F0;
    padding: 18px 0 27px;
}

.contacts_popup_wrapper .content .platform_contact > .head .inline {
    width: -webkit-max-content;
    width: max-content;
}

.contacts_popup_wrapper .content .platform_contact > .head h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
}

.contacts_popup_wrapper .content .platform_contact > .head span {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    padding: 0 10px;
    background: #BEDEF5;
    border-radius: 200px;
}

.contacts_popup_wrapper .content .body_create {
    display: grid;
    grid-gap: 1rem 2rem;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    justify-content: space-between;
    padding: 1.5rem 0 .75rem;
}

.contacts_popup_wrapper .content .body_create .button_wrap {
    grid-column: 1/-1;
    margin: 1rem 0 0 auto;
}

.contacts_popup_wrapper .content .body .item {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr -webkit-max-content;
    grid-template-columns: 1fr max-content;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 0 .75rem;
    border-bottom: 1px solid #E2E8F0;
}

.contacts_popup_wrapper .content .body .item > .left, .contacts_popup_wrapper .content .body .item > .right {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 133px 1fr;
    align-items: center;
}

.contacts_popup_wrapper .content .body .item > .left > div, .contacts_popup_wrapper .content .body .item > .right > div {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr;
    align-items: center;
}

.contacts_popup_wrapper .content .body .item > .right {
    display: grid;
    grid-gap: 2.5rem;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    align-items: center;
}

.contacts_popup_wrapper .content .body .item h6 {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
}

.contacts_popup_wrapper .content .body .item p, .contacts_popup_wrapper .content .body .item span {
    font-size: 12px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
}

.contacts_popup_wrapper .content .body .item span {
    display: block;
    color: #94A3B8;
}

.contacts_popup_wrapper .content .body .item .popup_wrap {
    position: relative;
}

.contacts_popup_wrapper .content .body .item .popup_wrap svg {
    cursor: pointer;
}
.platform_contact .list_of_contacts{
    overflow-y:scroll ;
    height: 150px;
    padding: 0 1.5rem 0;
}

.platform_contact.create_contact {
    padding: 0 1.5rem 0;
}

.platform_contact .header_block_of_contact .inline,
.content .header_block_of_contact {
    padding: 0 1.5rem 0;
}
.contacts_popup_wrapper .platform_contact .popup_content.modal_contact_popup{
    top:-32px!important;
    right: 20px!important;
}
.workspace_wrapper .title {
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    align-items: center;
    margin: 0 0 2rem;
}

.workspace_wrapper .workspace_search {
    margin: 0 0 1rem;
}

.workspace_wrapper .workspace_content, .prospects_wrapper .prospects_content {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
}

.workspace_wrapper .workspace_content .workspace_container, .prospects_wrapper .prospects_content .prospects_container {
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    border-radius: 8px;
    overflow-x: auto;
}

.workspace_wrapper .workspace_content .workspace_container > .head {
    display: grid;
    padding: 17px 2.75rem 16px 4rem;
    border-bottom: 1px solid #E2E8F0;
    grid-template-columns:  minmax(166px, 1fr) minmax(106px, 1fr) minmax(124px, 1fr) minmax(84px, 1fr) minmax(115px, 1fr) minmax(80px, 1fr) minmax(151px, 1fr) minmax(87px, 1fr);
    align-items: center;
    grid-gap: 1rem;
    width: 100%;
}

.workspace_wrapper .workspace_content .workspace_container > .head .item {
    display: grid;
    padding: 6px 0 10px;
    align-items: center;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    cursor: pointer;
}

.workspace_wrapper .workspace_content .workspace_container > .head .item span, .prospects_wrapper .prospects_content .prospects_container > .head .item span {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    text-transform: capitalize;
    word-break: break-word;
    color: #0F172A;
}
.checkbox {
    border: 2px solid #01426A;
    border-radius: 2px;
    opacity: 1!important;
    width:  16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center
}

.workspace_wrapper .workspace_content .workspace_container > .container_body > .item {
    display: grid;
    padding: 17px 2.75rem 16px 1.5rem;
    border-bottom: 1px solid #E2E8F0;
    grid-template-columns: 20px 24px minmax(166px, 1fr) minmax(106px, 1fr) minmax(124px, 1fr) minmax(84px, 1fr) minmax(115px, 1fr) minmax(80px, 1fr) minmax(151px, 1fr) minmax(87px, 1fr);
    align-items: center;
    grid-gap: 1rem;
    width: 100%;
}

.workspace_wrapper .workspace_content .workspace_container > .container_body > .item:last-of-type {
    padding: 17px 2.75rem 17px 1.5rem;
    border-bottom: none;
}

.workspace_wrapper .workspace_content .workspace_container > .container_body > .item .popup_wrap, .prospects_wrapper .prospects_content .prospects_container > .container_body .item .popup_wrap {
    position: relative;
    display: grid;
    align-items: center;
    justify-content: end;
}

.workspace_wrapper .workspace_content .workspace_container > .container_body > .item .popup_wrap svg, .prospects_wrapper .prospects_content .prospects_container > .container_body .item .popup_wrap svg {
    cursor: pointer;
}

.workspace_wrapper .workspace_content .workspace_container > .container_body > .item .popup_wrap .popup_content, .prospects_wrapper .prospects_content .prospects_container > .container_body .item .popup_wrap .popup_content, .contacts_popup_wrapper .content .body .item .popup_content {
    position: absolute;
    top: -82px;
    left: 24px;
    background: #FFFFFF;
    border: 1px solid #E2E8F0;
    box-sizing: border-box;
    box-shadow: 0 0 1px rgba(15, 23, 42, 0.06), 0 10px 15px -3px rgba(15, 23, 42, 0.1), 0 4px 6px -2px rgba(15, 23, 42, 0.05);
    border-radius: 2px;
    padding: 4px;
    width: 194px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4px;
    align-items: center;
}

.prospects_wrapper .prospects_content .prospects_container > .container_body .item .popup_wrap .popup_content, .contacts_popup_wrapper .content .body .item .popup_content {
    left: unset;
    right: 1.5rem;
    top: -74px;
}

.contacts_popup_wrapper .content .body .item .popup_content {
    right: 0;
    top: -96px;
    width: 224px;
}

.workspace_wrapper .workspace_content .workspace_container > .container_body > .item .popup_wrap .popup_content span, .prospects_wrapper .prospects_content .prospects_container > .container_body .item .popup_wrap .popup_content span, .contacts_popup_wrapper .content .body .item .popup_content span {
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    padding: 8px;
    transition: .3s;
    border-radius: 2px;
    display: block;
    cursor: pointer;
    opacity: 1;
}

.workspace_wrapper .workspace_content .workspace_container > .container_body > .item .popup_wrap .popup_content span.delete, .prospects_wrapper .prospects_content .prospects_container > .container_body .item .popup_wrap .popup_content span.delete, .contacts_popup_wrapper .content .body .item .popup_content span.delete {
    color: #F26464;
}

.workspace_wrapper .workspace_content .workspace_container > .container_body > .item .popup_wrap .popup_content span:hover, .prospects_wrapper .prospects_content .prospects_container > .container_body .item .popup_wrap .popup_content span:hover, .contacts_popup_wrapper .content .body .item .popup_content span:hover {
    background: #F8FAFC;
}

.workspace_wrapper .workspace_content .workspace_container > .container_body > .item p, .prospects_wrapper .prospects_content .prospects_container > .container_body .item p {
    font-size: 14px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    color: var(--gitgo-blue);
    font-weight: 600;
}

.prospects_wrapper .prospects_content .prospects_container > .container_body .item p {
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
}

.workspace_wrapper .workspace_content .workspace_container > .container_body > .item span, .prospects_wrapper .prospects_content .prospects_container > .container_body .item span:not(.status) {
    font-size: 14px;
    line-height: 21px;
    color: #475569;
    opacity: 0.5;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    text-transform: capitalize;
}
/*prospects-status-container*/
.workspace_wrapper .workspace_content .workspace_container > .container_body > .item .open_workspace {
    padding: 1px 11px;
    cursor: pointer;
    background: rgba(1, 66, 106, 0.1);
    border-radius: 4px;
    opacity: 1;
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    grid-gap: 8px;
    width: -webkit-max-content;
    width: max-content;
}

.open_workspace {
    display: flex;
    cursor: pointer;
}

.workspace_wrapper .workspace_content .workspace_container > .container_body > .item .open_workspace span {
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    opacity: 1;
}

.workspace_wrapper .pagination_wrapper {
    margin: 1.5rem 0 10rem;
}

@media (max-width: 1440px) {
    .workspace_wrapper .workspace_content .workspace_container > .container_body > .item, .workspace_wrapper .workspace_content .workspace_container > .head {
        width: -webkit-fit-content;
        width: fit-content;
        width: -moz-fit-content;
    }
}
.targeting_wrapper {
    margin: -5.5rem -2.25rem -40px -2.75rem;
    padding: 5.5rem 2.25rem 40px 2.75rem;
    background: #FFFFFF;
    min-height: 100vh;
}

.targeting_wrapper h2 {
    margin: 0 0 1.5rem;
}

.targeting_wrapper p {
    font-size: 20px;
    line-height: 30px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0 0 2rem;
    max-width: 920px;
}

.targeting_wrapper .first_content, .targeting_wrapper .second_content, .targeting_wrapper .second_content .inline, .targeting_wrapper .third_content, .targeting_wrapper .fourth_content, .targeting_wrapper .fifth_content, .targeting_wrapper .sixth_content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: end;
    max-width: 732px;
    grid-gap: 1.5rem 2rem;
    margin: 0 0 2rem;
    padding: 0 0 2rem;
    border-bottom: 1px solid #E2E8F0;
}

.targeting_wrapper .first_content {
    align-items: start;
}

.targeting_wrapper .second_content .inline {
    grid-column: 1/-1;
    align-items: start;
    padding: 0;
    margin: 0;
    border: none;
}

.targeting_wrapper .second_content .inline_gap {
    grid-column: 1/-1;
    align-items: start;
    padding: 0;
    margin: 0;
    border: none;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem 2rem;
}

.targeting_wrapper .second_content .inline_gap .horizontal_gap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem 2rem;
    align-items: end;
}

.targeting_wrapper .second_content .inline .vertical_gap {
    grid-gap: 1.5rem;
    display: grid;
}

.targeting_wrapper .fourth_content {
    align-items: start;
}

.targeting_wrapper .button_wrap {
    max-width: 732px;
}

.targeting_wrapper .button_wrap .submit_button {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #FFFFFF;
    margin-left: auto;
    padding: 12px 52px;
}
.multiply_select_wrapper {
    position: relative;
}

.multiply_select_wrapper .title {
    font-size: 14px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    display: block;
    margin: 0 0 4px;
}

.multiply_select_wrapper .error_title {
    font-size: 14px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    display: grid;
    color: var(--error);
    margin: 8px 0 0;
    padding: 0;
}

.multiply_select_wrapper .content {
    display: grid;
    grid-template-columns: 1fr -webkit-max-content;
    grid-template-columns: 1fr max-content;
    padding: .5rem 1rem;
    cursor: pointer;
    border: 1px solid #E2E8F0;
    border-radius: 2px;
    grid-gap: 10px;
}

.multiply_select_wrapper .content .items {
    display: flex;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
}

.multiply_select_wrapper .content .items span {
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
}

.multiply_select_wrapper .select_popup {
    top: 6rem;
}

.multiply_select_wrapper .select_popup .item {
    padding: 8px;
    transition: .3s;
    border-radius: 2px;
    cursor: pointer;
    margin: 4px;
    text-transform: capitalize;
    display: grid;
    grid-template-columns: -webkit-max-content 1fr;
    grid-template-columns: max-content 1fr;
    grid-gap: 6px;
    align-items: center;
}

.multiply_select_wrapper .select_popup .item .checkbox {
    width: 1rem;
    height: 1rem;
    border: 2px solid var(--gitgo-blue);
    display: grid;
    align-items: center;
    justify-content: center;
    transition: .3s;
}

.multiply_select_wrapper .select_popup .item .checkbox svg {
    transition: .2s;
    opacity: 0;
    -webkit-transform: scale(.9);
            transform: scale(.9);
}

.multiply_select_wrapper .select_popup .item .checkbox_active svg {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
}
.select_options_wrap .title {
    font-size: 14px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    display: block;
    margin: 0 0 1rem;
}

.select_options_wrap .content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.select_options_wrap .content .item {
    display: grid;
    grid-gap: 6px;
    align-items: center;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
}

.select_options_wrap .content .item {
    display: grid;
    grid-gap: 6px;
    align-items: center;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    cursor: pointer;
    margin: 0 1rem .5rem 0;
}

.select_options_wrap .content .item p {
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    margin: 0;
    color: #475569;
}

.select_options_wrap .content .item .circle {
    width: 18px;
    height: 18px;
    border: 2px solid var(--gitgo-blue);
    border-radius: 100%;
    transition: .3s;
}

.select_options_wrap .content .item .active_circle {
    background: var(--gitgo-blue);
    border-radius: 100%;
    border: 2px solid var(--gitgo-blue);
}
.success_popup_wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    z-index: 5000;
}

.data_submitted {
    position: static;
    width: 100%;
    height: auto;
    background: #FFFFFF;
    backdrop-filter: unset;
    -webkit-backdrop-filter: unset;
}

.success_popup_wrapper .success_content {
    z-index: 5001;
    position: absolute;
    padding: 2rem 138px 126px;
    top: 86px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 817px;
    max-height: calc(100vh - 172px);
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    overflow-y: auto;
}

.data_submitted .success_content {
    position: static;
    padding: 0;
    -webkit-transform: unset;
            transform: unset;
    width: 100%;
    max-height: unset;
    background: #FFFFFF;
    border: none;
    overflow-y: unset;
    max-width: 920px;
}

.success_popup_wrapper .success_content h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0 0 3.5rem;
    text-align: center;
}

.success_popup_wrapper .success_content .render_content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    grid-gap: 1.5rem 30px;
    padding: 1.5rem 0;
    border-bottom: 1px solid #E2E8F0;
}

.success_popup_wrapper .success_content .render_content h5 {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0 0 8px;
}

.success_popup_wrapper .success_content .render_content .data_line {
    width: 100%;
    height: 1px;
    background: #E2E8F0;
    grid-column: 1/-1;
}

.success_popup_wrapper .success_content .render_content h5 span span {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    color: #94A3B8;
}

.success_popup_wrapper .success_content .render_content p {
    font-size: 14px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0;
}

.success_popup_wrapper .success_content .send_wrapper {
    margin: 1.5rem 0 0;
}

.success_popup_wrapper .success_content .send_wrapper h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    margin: 0 0 2rem;
}

.success_popup_wrapper .success_content .send_wrapper .buttons_wrapper {
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;
    margin: 2.5rem 0 0;
}

.success_popup_wrapper .success_content .send_wrapper .buttons_wrapper .inline {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    grid-gap: 1rem;
}

.success_popup_wrapper .success_content .send_wrapper .buttons_wrapper .cancel {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #13426B;
    height: 36px;
    width: 100px;
    border: 1px solid #13426B;
    border-radius: 2px;
}

.success_popup_wrapper .success_content .send_wrapper .buttons_wrapper .inline button, .success_popup_wrapper .success_content button.submitted_export {
    width: 100px;
    background: var(--gitgo-blue);
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #FFFFFF;
    border-radius: 2px;
    height: 36px;
}

.success_popup_wrapper .success_content .send_wrapper .buttons_wrapper .inline button.export, .success_popup_wrapper .success_content button.submitted_export {
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 4px;
    justify-content: center;
}

.success_popup_wrapper .success_content .send_wrapper .error_message {
    margin: .5rem 0 0;
    font-size: 14px;
    line-height: 21px;
    color: var(--error);
    display: block;
}

.success_popup_wrapper .success_content button.submitted_export {
    margin: 1.5rem 0 0 auto;
}
.add_email_wrapper {
    position: relative;
}

.add_email_wrapper .title {
    font-size: 14px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #13426B;
    display: block;
    margin: 0 0 4px;
}

.targeting_add_email_wrapper .title {
    line-height: 24px;
    color: #0F172A;
}

.add_email_wrapper .content {
    width: 100%;
    border: 1px solid #E2E8F0;
    border-radius: 2px;
    padding: .5rem 1rem;
    min-height: 50px;
}

.add_email_wrapper .content .content_flow {
    display: flex;
    flex-wrap: wrap;
}

.add_email_wrapper .content .email_item {
    background: #BEDEF5;
    border-radius: 5px;
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    grid-gap: 10px;
    align-items: center;
    padding: 0 4px;
    margin: 0 10px 10px 0;
}

.add_email_wrapper .content .email_item div {
    cursor: pointer;
    display: grid;
    align-items: center;
}

.add_email_wrapper .content .email_item span, .add_email_wrapper .content input {
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #13426B;
    border: none;
    outline: none;
}

.targeting_add_email_wrapper .content {
    border: 1px solid #0F172A;
    padding: .5rem;
    min-height: 41px;
    display: flex;
    flex-wrap: wrap;
}

.add_email_wrapper .content .email_item span, .add_email_wrapper .content input {
    color: #0F172A;
}

.add_email_wrapper .content .email_item {
    background: #E2E8F0;
    margin: 0 10px 10px 0;
}

.add_email_wrapper .search_wrap {
    position: absolute;
    bottom: -152px;
    left: 0;
    right: 0;
    width: 100%;
    overflow-y: auto;
    background: #FFFFFF;
    border: 1px solid #E2E8F0;
    box-shadow: 0 0 1px rgba(15, 23, 42, 0.06), 0 10px 15px -3px rgba(15, 23, 42, 0.1), 0 4px 6px -2px rgba(15, 23, 42, 0.05);
    border-radius: 2px;
    padding: 1rem;
    height: 150px;
}

.add_email_wrapper .search_wrap span.option {
    display: block;
    font-size: 12px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    padding: 0 0 12px;
    border-bottom: 1px solid #E2E8F0;
    margin: 0 0 10px;
    cursor: pointer;
}

.add_email_wrapper .search_wrap span:last-of-type {
    margin: 0;
}
.contacts-container {
    width: 100%;
}

.notes-text {
    color: rgb(148, 163, 184);
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.p-style{
    border-radius:4px;
    padding: 8px 10px;
    width: -webkit-max-content;
    width: max-content;
}
.notes-text-full {
    color: rgb(148, 163, 184);
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* -webkit-box-orient: vertical; */
    overflow: hidden;
    text-overflow: ellipsis;
}


.title-page {
    font-size: 36px;
    font-weight: 600;
    color: var(--gitgo-blue);
}

.contacts-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contacts-items-container {
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #CBD5E1;
}

.contacts-button-new {
    padding: 0 20px;
    height: 36px;
    border-radius: 2px;
    background-color: var(--gitgo-blue);
    color: #FFFFFF;
}

.contacts-button-new button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.contacts-button-new button img {
    width: 24px;
    height: 24px;
}

.contacts-button-new button span {
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF;
    margin-left: 4px;
}

.contacts-button-dropdown-container {
    position: relative;
}

.contacts-button-dropdown {
    background-color: #FFFFFF;
    color: #000000;
    width: 275px;
    position: absolute;
    right: -20px;
    top: 6px;
    z-index: 9;
    padding: 4px;
    border-radius: 2px;
    border: 1px solid #E2E8F0;
    box-shadow: 0 0 1px rgba(15, 23, 42, 0.06), 0 10px 15px -3px rgba(15, 23, 42, 0.1), 0 4px 6px -2px rgba(15, 23, 42, 0.05);
    transition: .3s;
}

.contacts-button-dropdown button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 8px;
    color: #0F172A;
    font-size: 16px;
    font-weight: 400;
    border-radius: 2px;
}

.contacts-button-dropdown button a {
    display: block;
    width: 100%;
    text-align: left;
}

.contacts-button-dropdown p {
    color: #0F172A;
    font-size: 16px;
    font-weight: 400;
}

.contacts-button-dropdown button:hover {
    background-color: #F8FAFC;
}

.plus-icon {
    width: 24px;
    height: 24px;
}

.contacts-search-container {
    width: 445px;
    height: 36px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 2px;
    padding-right: 16px;
    border: 1px solid #E2E8F0;
    background-color: #FFFFFF;
    margin-top: 40px;
    margin-bottom: 16px;
}

.contacts-search-container button {
    width: 24px;
    height: 24px;
    background-color: transparent;
    display: flex;
    align-items: center;
    padding: 0;
}

.contacts-search-container input {
    height: 100%;
    width: 100%;
    padding-left: 14px;
    font-size: 16px;
    font-weight: 400;
    border: none;
}

.contacts-contact-name {
    width: 160px;
}

.contacts-email {
    width: 180px;
}

.contacts-prospect-name {
    width: 140px;
}

.contacts-job-title {
    width: 100px;
}

.contacts-phone {
    width: 136px;
}

.contacts-create-data {
    width: 130px;
}

.contacts-last-engaged {
    width: 120px;
}

.contacts-items {
    display: flex;
    align-items: center;
    height: 56px;
    padding-left: 16px;
}

.contacts-dots-vertical {
    padding-left: 16px;
    display: flex;
    align-items: center;
}

.contacts-dots-vertical button {
    padding: 0;
}

.contacts-items-line {
    width: 100%;
    height: 1px;
    background-color: #E2E8F0;
}

.contacts-items-line .item {
    border-bottom: 1px solid #E2E8F0;
}

.contacts-container-content {
    max-height: 653px;
    overflow: auto;
}


.contacts-container-content > div:not(.contacts-items-content) .item {
    border-top: 1px solid #E2E8F0;
    min-inline-size: -webkit-max-content;
    min-inline-size: max-content;
}

.contacts-container-content > div:not(.contacts-items-content) .item svg {
    cursor: pointer;
}

.contacts-container-content > div:not(.contacts-items-content) .item span:nth-child(3) {
    display: flex;
    justify-content: center;
}

.contacts-items-span {
    font-weight: 400;
    font-size: 16px;
    color: #475569;
    opacity: 0.5;
}

.contacts-items-span.name {
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #01426A;
    opacity: 1;
}

.contacts-prospects-items-span {
    color: #475569;
    font-size: 16px;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
    display: block;
    cursor: pointer;
}

.contacts-items-span:nth-child(3) {
    cursor: pointer;
}

.contacts-items-span:first-child {
    cursor: pointer;
    color: var(--gitgo-blue);
    font-size: 16px;
    font-weight: 600;
    opacity: 1;
}

.contacts-items-span:nth-child(4) {
    opacity: 1;
}

#scroll-bar::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
}

#scroll-bar::-webkit-scrollbar-thumb {
    background: rgba(203, 213, 225, 1);
    border-radius: 10px;
}

#scroll-bar::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.0);
}

.contact-menu-nav {
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contacts-contact-name-item {
    font-size: 16px;
    font-weight: 600;
    color: #475569;
}

.contacts-email-item {
    font-size: 16px;
    font-weight: 400;
    color: #475569;
    opacity: 0.5;
}

.contacts-prospect-name-item {
    font-size: 14px;
    font-weight: 400;
    color: #475569;
}

.contacts-job-title-item {
    font-size: 16px;
    font-weight: 400;
    color: #475569;
}

.contacts-phone-item {
    font-size: 16px;
    font-weight: 400;
    color: #475569;
    opacity: 0.5;
}

.contacts-create-data-item {
    font-size: 16px;
    font-weight: 400;
    color: #475569;
    opacity: 0.5;
}

.contacts-last-engaged-item {
    font-size: 16px;
    font-weight: 400;
    color: #475569;
    opacity: 0.5;
}

.contacts-menu-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 3000;
    width: 194px;
    border-radius: 2px;
    border: 1px solid #E2E8F0;
    background-color: #FFFFFF;
    padding: 4px;
}

.contacts-menu-button-container {
    width: 100%;
    height: 40px;
    background-color: #FFFFFF;
    display: flex;
}

.contacts-menu-button-container button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 8px;
}

.contacts-menu-button-style {
    background-color: transparent;
}

.contacts-menu-button-style:hover {
    background-color: #F8FAFC;
    border-radius: 2px;
}

.dots-cont img {
    position: relative;
    z-index: 500;
}

.contacts-new-button-container {
    display: flex;
    align-items: center;
}

.contacts-new-buttons-container {
    position: absolute;
    z-index: 3000;
    display: flex;
    flex-direction: column;
    width: 194px;
    border-radius: 2px;
    border: 1px solid #E2E8F0;
    background-color: #FFFFFF;
    padding: 4px;
    margin-top: 39px;
}

.contacts-new-button {
    width: 186px;
    height: 40px;
}

.contacts-new-popup-button {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 8px;
    background-color: #FFFFFF;
}

.contacts-new-popup-button:hover {
    background-color: #F8FAFC;
    border-radius: 2px;
}

.contacts-button-and-popup-container {
    width: 195px;
    display: flex;
    justify-content: flex-end;
}

.contacts-container-content .item {
    display: grid;
    grid-template-columns: 2.5% 10.1% 15.1% 11.6% 11.6% 10.6% 10.6% 11.7% 9.4% 1.7%;
    align-items: center;
    grid-gap: 0.65%;
    padding: 0 1.5%;
    height: 57px;
    font-size: 14px;
    width: auto;
}

.contacts-items-content {
    display: grid;
    /* padding: 5px 0 0 3rem; */
    padding: 0 2% 0 4.5%;
    grid-template-columns: 10.5% 15.8% 12.1% 12.1% 11.1% 11.1% 12.3% 8.8%;
    align-items: center;
    grid-gap: 0.65%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 14px;
    font-weight: 600;
    width: auto;
}
@media (max-width: 1550px) {
    .contacts-container-content .item {
        grid-template-columns: 30px 120px 180px 135px 135px 125px 125px 125px 100px 30px;
        grid-gap: 8px;
        padding: 0 1.5%;
    }
    
    .contacts-items-content {

        padding: 0 20px 0 55px;
        grid-template-columns:  120px 180px 135px 135px 125px 125px 125px 100px;
        grid-gap: 8px;
    }
}

.contacts-items-content .item {
    display: grid;
    padding: 6px 0 10px;
    grid-gap:5px;
    align-items: center;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    cursor: pointer;
}

.popup-content-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 194px;
    padding: 4px;
    background-color: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #E2E8F0;
    right: 30px;
    bottom: -30px;
    z-index: 10;
}

.popup-content-menu button {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px;
    font-size: 16px;
    font-weight: 400;
    color: #0F172A;
}

.popup-content-menu button span {
    display: flex;
    justify-content: flex-start;
}

.contacts-container-content div:not(.contacts-items-content .popup_prospect_content_item) .item span {
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
    display: block;
}

.popup-content-menu button {
    font-size: 16px;
    font-weight: 400;
    color: #0F172A;
}

.popup-content-menu button:last-child {
    color: #F26464;
}

.tooltip {
    position: relative;
}

.tooltip .tooltiptext {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 5px;
    border-radius: 6px;
    position: absolute;
    z-index: 4000;
    top: 100%;
    left: 0;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.popup-content-menu button:hover {
    background-color: #F8FAFC;
}

.button_default_plus.contacts {
    box-shadow: none;
}

.assign-contacts-background {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3550;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

.assign-modal-window {
    padding: 24px;
    position: fixed;
    max-width: 817px;
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 3100;
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    box-sizing: border-box;
    border-radius: 8px;
    max-height: 75vh;
    height: 75vh;
}

.assign-modal-window .modal-header span {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    color: #475569;
}

.assign-modal-window .modal-header {
    display: flex;
    padding-bottom: 25px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E2E8F0;
}

.assign-modal-window .modal-header .modal-buttons {
    display: grid;
    grid-template-columns: -webkit-max-content -webkit-max-content;
    grid-template-columns: max-content max-content;
    align-items: center;
    grid-gap: 10px;
}

.assign-modal-window .modal-header .modal-buttons .assign-button {
    background: #01426A;
    border-radius: 2px;
    color: #ffffff;
    padding: 6px 16px;
    /*border: 1px solid #01426A;*/
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
}

.assign-modal-window .modal-header .modal-buttons .close-button {
    background: #ffffff;
    border: 1px solid #01426A;
    border-radius: 2px;
    padding: 6px 16px;
    color: #01426A;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
}

.assign-modal-window .container-assign {
    display: grid;
}

.assign-modal-window .container-assign .assign-line {
    border-bottom: 1px solid #E2E8F0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 19px;
    transition: 0.4s;
}
.assign-modal-window .container-assign .assign-line.checked {
    background: #E2E8F0;
    border: 1px solid #acc0db;
}

.assign-modal-window .container-assign .assign-line .scope-button {
    padding: 4px 8px;
    background: rgba(1, 66, 106, 0.1);
    border-radius: 2px;
    width: 90px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #01426A;

}

.assign-modal-window .container-assign .assign-line .right-info-side {
    display: grid;
    grid-gap: 50px;
    align-items: center;
    grid-template-columns: -webkit-max-content 1fr;
    grid-template-columns: max-content 1fr;
}

.assign-modal-window .container-assign .assign-line .right-info-side span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #01426A;
}

.assign-modal-window .container-assign .assign-line .right-info-side .check-circle {
    cursor: pointer;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid #01426A;
    transition: 0.4s;
}

.assign-modal-window .container-assign .assign-line .right-info-side .check-circle.checked {
    background-color: #01426A;
    border: 2px solid #01426A;
}

@media (max-width: 1521px) {
    .popup-content-menu {
        right: 4px;
    }
}

/* @media (max-width: 1355px) {
    .contacts-container-content > div:not(.contacts-items-content) .item {
        width: fit-content;
        grid-template-columns: 20px minmax(150px, 1fr) minmax(152px, 1fr)  minmax(145px, 1fr) minmax(130px, 1fr)  minmax(116px, 1fr)  minmax(115px, 2fr) minmax(140px, 1fr) 25px;

    }
} */


.checkbox {
    border: 2px solid #01426A;
    border-radius: 2px;
    opacity: 1 !important;
    width: 16px;
    height: 16px;
    display: flex !important;
    align-items: center;
    justify-content: center
}

@media (min-width: 1400px) {
    /* .contacts-container-content > div:not(.contacts-items-content) .item {
        display: grid;
        grid-template-columns: 20px minmax(152px, 1fr) minmax(177px, 1fr)  minmax(166px, 1fr) minmax(154px, 1fr)  minmax(145px, 1fr)  minmax(110px, 1fr) minmax(140px, 1fr) 40px;

    }

    .contacts-items-content {
        display: grid;
        align-items: center;
        grid-template-columns: 5fr 5fr 5fr 5fr 5fr 5fr 5fr 1fr;
        grid-gap: 1rem;
        width: -moz-fit-content;
        font-size: 14px;
        font-weight: 600;
        width: 100%;
        padding: 0 2.75rem 0 50px;
    } */
}

.contacts-nav-container {
    display: flex;
    margin-bottom: 16px;
}

.contacts-nav-contacts {
    font-size: 16px;
    font-weight: 400;
    color: #475569;
    cursor: pointer;
}

.contacts-nav-name {
    font-size: 16px;
    font-weight: 700;
    color: #475569;
}

.contacts-edit-class {
    width: 87px;
    height: 36px;
    border: 2px solid var(--gitgo-blue);
    font-size: 14px;
    font-weight: 700;
    color: var(--gitgo-blue);
}

.contacts-user-name-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 31px;
}

.contacts-details-text {
    font-size: 18px;
    font-weight: 500;
    color: #475569;
    margin-bottom: 29px;
}

.contacts-details-item-title {
    font-size: 12px;
    font-weight: 700;
    color: var(--gitgo-blue);
    margin: 25px 0 10px 0;
}

.contacts-details {
    display: flex;
    flex-direction: column;
}

.contacts-details-container {
    display: grid;
    grid-template-columns: 255px 255px;
    grid-gap: 20px;
    margin-top: 16px;
}

.contacts-line-container {
    margin: 24px 0 61px 0;
    padding: 0 0 25px 0;
    border-top: 1px solid #E2E8F0;
    border-bottom: 1px solid #E2E8F0;
}

.contacts-line-container .contacts-user-info-line:first-child {
    margin: 24px 0 25px 0;
}

.contacts-line-container .contacts-user-info-line:last-child {
    margin-top: 25px;
    margin-bottom: 61px;
}

.contacts-details-content {
    display: grid;
    grid-gap: 20px;
}

.contacts-user-info-line {
    width: 540px;
    height: 1px;
    background-color: #E2E8F0;
}

.contacts-details-prospects {
    display: flex;
    flex-direction: column;
    width: 540px;
}

.contacts-delete-user {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.contacts-delete-button {
    display: flex;
    align-items: center;
}

.popup-content-menu-item {
    font-size: 16px;
    font-weight: 400;
    color: #0F172A;
}

.popup-content-menu-item-delete {
    font-size: 16px;
    font-weight: 400;
    color: red;
}

.activity-container button {
    width: 117px;
    height: 36px;
    background-color: var(--gitgo-blue);
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px 0 16px;
}

.activity-container {
    display: flex;
    justify-content: space-between;
}

.activity-content-container {
    border: 1px solid #CBD5E1;
}

.activity-content {
    display: grid;
    grid-template-columns: 350px 1fr;
    grid-gap: 130px;
    padding: 24px 24px 16px 24px;
}

.contacts-user-notes {
    display: flex;
    flex-direction: column;
}

.contacts-user-notes-block {
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    color: #475569;
}

.contacts-user-notes-block span {
    margin-bottom: 4px;
}

.contacts-user-notes-block textarea {
    width: 100%;
    resize: none;
    padding: 16px;
}

.contacts-call-logged {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.contacts-call-logged span {
    margin-left: 16px;
    font-size: 14px;
    font-weight: 600;
    color: #475569;
}

.contacts-textarea {
    border: 1px solid #E2E8F0;
    height: 231px;
}

.contacts-activity-buttons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.contacts-activity-buttons-container button:first-child {
    width: 80px;
    height: 36px;
    border: 1px solid var(--gitgo-blue);
    color: var(--gitgo-blue);
    font-size: 14px;
    font-weight: 700;
    margin-right: 8px;
}

.contacts-activity-buttons-container button:last-child {
    width: 80px;
    height: 36px;
    background-color: var(--gitgo-blue);
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 700;
    margin-left: 8px;
}

.contact-user-calendar {
    width: 325px;
    height: 41px;
    background-color: #FFFFFF;
    border: 1px solid #E2E8F0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px 0 0;
    margin-top: 4px;
}

.contact-user-calendar-container span:first-child {
    font-size: 14px;
    font-weight: 400;
    color: #0F172A;
}

.activity-element {
    display: grid;
    grid-template-columns: 480px 1fr;
    border-top: 1px solid #E2E8F0;
    padding: 16px 6px 16px 24px;
}

.contacts-date-test {
    font-size: 14px;
    font-weight: 400;
    color: #94A3B8;
}

.activity-element-text-container {
    width: 100%;
    display: flex;
}

.activity-element-text-container textarea {
    width: 100%;
    padding: 0 20px 0 12px;
    border: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: 400;
    color: #94A3B8;
    resize: none;
}

.activity-element-text-container span {
    font-size: 14px;
    font-weight: 600;
    color: #475569;
}

.react-datepicker__input-container input {
    width: 100%;
    height: 41px;
    border: 0;
    background-color: transparent;
    padding-left: 12px;
    cursor: pointer;
}

.contacts-user-prospect {
    height: 29px;
    padding: 0 8px 0 8px;
    background-color: rgba(150, 200, 237, 0.2);
    color: #475569;
    border-radius: 2px;
}

.contacts-user-log-dropdown {
    width: 194px;
    top: -12px;
}

.contacts-user-log-dropdown img {
    margin-right: 8px;
}

.activity-element-container {
    max-height: 250px;
    overflow-y: scroll;
}

.activity-element-container::-webkit-scrollbar {
    width: 6px;
}

.activity-element-container::-webkit-scrollbar-track {
    background-color: transparent;
}

.activity-element-container::-webkit-scrollbar-thumb {
    background: #CBD5E1;
    border-radius: 10px;
}

.activity-element-container::-webkit-scrollbar-thumb:hover {
    background: #CBD5E1;
}

.contacts-user-log-dropdown button:hover svg path {
    stroke: var(--gitgo-blue);
}

.contacts-user-log-dropdown button p {
    margin-left: 16px;
    margin-top: 2px;
}

.contacts-button-dropdown button {
    transition: .3s;
}

.contact-prospect-chosen-container {
    display: flex;
    flex-wrap: wrap;
}

.contact-prospect-chosen-item {
    background-color: rgba(150, 200, 237, 0.2);
    display: flex;
    align-items: center;
    border-radius: 2px;
    height: 32px;
    padding: 0 8px 0 8px;
    margin: 8px 8px 0 0;
}

.contacts-details .contact-prospect-chosen-item span {
    font-size: 14px;
}

.contacts-details .contact-prospect-chosen-item svg {
    display: none;
}

.contact-prospect-chosen-item span {
    margin: 0 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #0F172A !important;
}

.contact-prospect-chosen-item svg {
    cursor: pointer;
}

.contacts-container-content > div:not(.contacts-items-content) .item .popup_wrap {
    position: relative;
}

.popup-content-menu-item {
    color: #0F172A;
    opacity: 1;
    font-size: 16px;
    font-weight: 400;
}

.popup_prospect_content {
    position: absolute;
    width: 200px;
    border: 1px solid #E2E8F0;
    background-color: #FFFFFF;
    z-index: 1000;
    padding: 4px;
}

.popup_prospect_content_item {
    width: 100%;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: flex-start;
}

.popup_prospect_content_item span {
    font-size: 16px;
    font-weight: 400;
    color: #475569;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 12px 8px;
}

.popup_prospect_content_item span:hover {
    background-color: #F8FAFC;
    width: 100%;
}
.box {
    width: 489px;
    height: 375px;
    margin: auto;
    background: #fff;
    border-radius: 8px;
    padding: 48px;
    border: 1px solid #CBD5E1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box::-webkit-scrollbar {
    overflow: hidden;
}

.assign-close-button {
    cursor: pointer;
    background: #FFFFFF;
    border: 1px solid var(--gitgo-blue);
    border-radius: 2px;
    width: 80px;
    height: 36px;
    font-size: 14px;
    font-weight: 700;
    color: var(--gitgo-blue);
    font-family: 'Inter', sans-serif;
    margin-right: 8px;
}

.assign-confirm-button {
    cursor: pointer;
    background: var(--gitgo-blue);
    border-radius: 2px;
    width: 80px;
    height: 36px;
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF;
    font-family: 'Inter', sans-serif;
    margin-left: 8px;
}

.assign-select-value {
    width: 308px;
    height: 40px;
    border: 1px solid #E2E8F0;
    border-radius: 2px;
    padding-left: 16px;
    margin-top: 4px;
    margin-bottom: 28px;
}

.assign-prospect-text {
    font-size: 20px;
    font-weight: 400;
    color: #0F172A;
    margin-bottom: 8px;
    text-align: center;
    margin-right: 5px;
}

.assign-item-name {
    font-size: 20px;
    font-weight: 600;
    color: #0F172A;
    text-align: center;
    margin-bottom: 8px;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
    display: block;
    max-width: 240px;
}

.assign-form-title {
    font-size: 12px;
    font-weight: 700;
    color: var(--gitgo-blue);
}

.assign-popup-button-container {
    display: flex;
    margin: 25px auto 0;
}

.assign-popup-button-container button {
    width: 85px;
    height: 36px;
}

.assign-popup-button-container button:first-child {
    border: 1px solid var(--gitgo-blue);
    font-size: 14px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    color: var(--gitgo-blue);
    background-color: transparent;
    margin-right: 8px;
    align-items: center;
}

.assign-popup-button-container button:last-child {
    border: 1px solid var(--gitgo-blue);
    font-size: 14px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    background-color: var(--gitgo-blue);
    color: #FFFFFF;
    margin-left: 8px;
    align-items: center;
}

.assign-custom-select {
    width: 308px;
}

.assign-prospect-name-container {
    display: flex;
}

.assign-prospect-name-content {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    z-index: 5000;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.delete-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 489px;
    max-height: 240px;
    margin-top: 25%;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 25px;
    border: 1px solid #CBD5E1;
    overflow: auto;
}

.close-button {
    cursor: pointer;
    background: #FFFFFF;
    border: 1px solid var(--gitgo-blue);
    border-radius: 2px;
    width: 80px;
    height: 36px;
    font-size: 14px;
    font-weight: 700;
    color: var(--gitgo-blue);
    font-family: 'Inter', sans-serif;
    margin-right: 8px;
}

.delete-button {
    cursor: pointer;
    background: #F26464;
    border-radius: 2px;
    width: 80px;
    height: 36px;
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF;
    font-family: 'Inter', sans-serif;
    margin-left: 8px;
}

.delete-popup-title {
    font-size: 20px;
    font-weight: 400;
    color: #475569;
    margin-bottom: 5px;
    font-family: 'Inter', sans-serif;
}

.item-name {
    font-size: 20px;
    font-weight: 600;
    color: var(--gitgo-blue);
    font-family: 'Inter', sans-serif;
}

.delete-question-text {
    font-size: 14px;
    font-weight: 400;
    margin-top: 28px;
    margin-bottom: 28px;
    box-sizing: content-box;
    width: 270px;
    text-align: center;
    font-family: 'Inter', sans-serif;
    color: #475569;
}

.delete-popup-buttons-container {
    display: flex;
}

.delete-popup-buttons-container button {
    width: 80px;
    height: 36px;
    font-size: 15px;
    font-weight: 700;
    display: flex;
    justify-content: center;
}

.delete-popup-buttons-container button:first-child {
    border: 1px solid var(--gitgo-blue);
    color: var(--gitgo-blue);
    background-color: transparent;
    margin-right: 8px;
    display: flex;
    align-items: center;
}

.delete-popup-buttons-container button:last-child {
    border: 1px solid #F26464;
    color: #FFFFFF;
    background-color: #F26464;
    margin-left: 8px;
    display: flex;
    align-items: center;
}
.services_wrapper .services_title span {
    display: block;
    margin-top: 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #475569;
}
.services_wrapper .title_of_blocks_header {
    margin-top: 56px;
    display: grid;
    text-align: center;
}
.title_of_blocks_header .title_of_blocks {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    color: #475569;
}
.services_wrapper .block_wrapper {
    margin-top: 32px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
}

.services_wrapper .block_wrapper .text_block {
    display: grid;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    text-align: center;
    background: #FFFFFF;
    border-radius: 8px;
    max-width: 385px;
    height: 586px;
    padding-bottom: 32px;
}

.services_wrapper .block_wrapper .text_block h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    color: #01426A;
}

.services_wrapper .block_wrapper .text_block .color_header_of_block {
    padding: 32px 25px;
    /* height: 150px; */
    display: grid;
    text-align: left;
}

.services_wrapper .block_wrapper .text_block .content_of_block {
    text-align: left;
    padding: 25px;
}

.services_wrapper .block_wrapper .text_block .color_header_of_block .sub_text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #0F172A;
}

.services_wrapper .block_wrapper .text_block .content_of_block span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #475569;
}
.services_wrapper .block_wrapper .text_block .block_button{
    width: 160px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: white;
    padding: 12px 24px;
    box-shadow: 0 17px 19px rgba(39, 98, 249, 0.1);
    /* border: 1px solid #01426A; */
    background-color: #01426A;
}

.services_wrapper .block_wrapper .text_block .block_button:hover{
    width: 160px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: white;
    padding: 12px 24px;
    box-shadow: 0 17px 19px rgba(39, 98, 249, 0.1);
    /* border: 1px solid #01426A; */
    background-color: white;
}


.create_contact_wrapper {
    padding: 5.5rem 2.25rem 40px 2.75rem;
    margin: -5.5rem -2.25rem -40px -2.75rem;
    background: #FFFFFF;
    min-height: 100vh;
}

.delete_contact {
    margin: 0 0 0rem;
    padding: 0 0 1.5rem;
    width: -webkit-max-content;
    width: max-content;
}

.delete_contact p {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    margin: 0 0 8px;
}

 .delete_contact div {
    cursor: pointer;
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    grid-gap: 8px;
    align-items: center;
}

.delete_contact span {
    font-size: 16px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--error);
}

.create-contact {
    display: flex;
    flex-direction: column;
    width: 730px;
}

.create-contact-inputs-title {
    font-size: 18px;
    font-weight: 500;
    color: #475569;
    margin: 32px 0 16px 0;
}


.create-contact-input-content {
    display: flex;
    margin-bottom: 24px;
}

.create-contact-input-content:last-child {
    margin-bottom: 0;
}

.create-contact-input-container:nth-child(2) {
    margin-left: 30px;
}

.create-contact-input-container {
    display: flex;
    flex-direction: column;
}

.create-contact-input-container-test {
    height: 40px;
    width: 350px;
    border-radius: 2px;
    border: 1px solid #E2E8F0;
    background-color: #FFFFFF;
    display: grid;
    grid-template-columns: 9fr 1fr;
}

.create-contact-input-container-test-img {
}

.create-contact-input-container input.custom_input {
    height: 40px;
    width: 350px;
    border-radius: 2px;
    padding: 8px 16px;
}

.create-contact-input-container input:focus {
    border: 1px solid #475569;
}

.create-contact-input-container .select_content {
    width: 350px;
    height: 40px;
    background-color: white;
    padding: 7px 16px;
}

.create-contact-input-container span {
    font-size: 14px;
    font-weight: 400;
    color: var(--gitgo-blue);
    margin-bottom: 4px;
}

.create-contact-input-false input.custom_input {
    border: 1px solid #F26464;
}

.line-block {
    width: 100%;
    height: 1px;
    background-color: #E2E8F0;
    margin: 28px 0 40px 0;
}

.create-contact-buttons {
    display: flex;
    justify-content: flex-end;
}

.create-contact-buttons button {
    width: 80px;
    height: 36px;
    font-size: 14px;
    font-weight: 700;
}

.create-contact-buttons button:last-child {
    margin-left: 16px;
}

.cancel-button {
    border: 1px solid var(--gitgo-blue);
    border-radius: 2px;
    color: var(--gitgo-blue);
}

.create-button {
    background-color: var(--gitgo-blue);
    border-radius: 2px;
    color: white;
}
.create_account_wrapper {
    min-height: 100vh;
}

.create_account_wrapper > .create_account_container {
    margin: 150px auto 0;
    width: 100%;
    max-width: 918px;
}

.create_account_wrapper > .create_account_container > h2 {
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    margin: 0 0 8px;
}

.create_account_wrapper > .create_account_container > p {
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0 0 20px;
}

.create_account_wrapper > .create_account_container > .content {
    background: #F8FAFC;
    border-radius: 8px;
    padding: 3.5rem 6rem;
}

.create_account_wrapper > .create_account_container > .content h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    margin: 0 0 1.5rem;
}

.create_account_wrapper > .create_account_container > .content .block {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 0 0 2rem;
    border-bottom: 1px solid #E2E8F0;
    margin: 0 0 2rem;
}

.create_account_wrapper > .create_account_container > .content .buttons_wrapper {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: center;
}

.create_account_wrapper > .create_account_container > .content .buttons_wrapper button {
    width: 5rem;
    padding: 6px 0;
    text-transform: unset;
}
.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2500;
    width: 100%;
    display: grid;
    background-color: #FFFFFF;
    grid-template-columns: 248px 1fr;
    align-items: center;
}

.header > .logo_container {
    background: var(--gitgo-blue);
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 14px 0;
    border-right: 1px solid #CBD5E1;
}

.header > .logo_container_project {
    display: grid;
    /*
    grid-template-columns: max-content 1fr;
    justify-content: start;
    padding: 14px 1rem 14px 2rem;
     */
}

.header > .logo_container_project span:not(.tooltiptext) {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #F8FAFC;
    opacity: 0.7;
    text-overflow: ellipsis;
    display: block;
    white-space: pre;
    overflow: hidden;
    width: 150px;
}


.header > .header_content {
    display: flex;
    grid-template-columns: repeat(3, -webkit-max-content);
    grid-template-columns: repeat(3, max-content);
    align-items: center;
    padding: 0 3rem 0 2.75rem;
    justify-content: space-between;
    border-bottom: 1px solid #CBD5E1;
    height: 56px;
    grid-gap: 1em;
}

.header > .header_content .search_bar {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
}

.header > .header_content .search_bar_admin {
    grid-template-columns: -webkit-max-content 1fr;
    grid-template-columns: max-content 1fr;
    grid-gap: 3rem;
}

.header > .header_content .search_bar_admin .leave_workspace {
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 10px;
    cursor: pointer;
}

.header > .header_content .search_bar_admin .leave_workspace span {
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    max-width: 300px;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
    display: block;
}

.header > .header_content .search_bar_admin .leave_workspace svg {
    transition: .3s;
}

.header > .header_content .search_bar_admin .leave_workspace:hover svg {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px);
}

.header > .header_content .profile_popup_wrapper {
    position: relative;
    width: -webkit-max-content;
    width: max-content;
    margin: 0 0 0 40px;
}

.header > .header_content .profile_popup_wrapper > .user_name {
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    justify-content: end;
    margin-left: auto;
    width: -webkit-max-content;
    width: max-content;
    grid-gap: 1rem;
    cursor: pointer;
    align-items: center;
}

.header > .header_content .profile_popup_wrapper > .user_name_admin {
    grid-template-columns: -webkit-max-content;
    grid-template-columns: max-content;
    grid-gap: 0;
    cursor: auto;
}

.header > .header_content .profile_popup_wrapper > .user_name span {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
}

.header > .header_content .profile_popup_wrapper .profile_popup {
    position: absolute;
    top: 37px;
    width: 238px;
    right: -2rem;
    background: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0 0 1px rgba(15, 23, 42, 0.06), 0 10px 15px -3px rgba(15, 23, 42, 0.1), 0 4px 6px -2px rgba(15, 23, 42, 0.05);
    border-radius: 2px;
    display: grid;
    grid-template-columns: 1fr;
    max-height: 0;
    transition: .3s;
    overflow-y: hidden;
}

.header > .header_content .profile_popup_wrapper .profile_popup_active {
    max-height: 200px;
}

.header > .header_content .profile_popup_wrapper .profile_popup a,
.header > .header_content .profile_popup_wrapper .profile_popup button, .header > .header_content .profile_popup_wrapper .profile_popup span, .contacts_popup_wrapper .content > .head .inline .popup span {
    display: block;
    padding: 8px;
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    transition: .3s;
    border-radius: 2px;
    cursor: pointer;
    margin: 4px;
    text-align: left;
}

.header > .header_content .profile_popup_wrapper .profile_popup a:hover, .header > .header_content .profile_popup_wrapper .profile_popup span:hover,
.header > .header_content .profile_popup_wrapper .profile_popup button:hover {
    background: #F2F8FD;
}

.header > .header_content .profile_popup_wrapper .profile_popup span {
    color: #F26464;
}

.leave_workspace-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.leave_workspace-container svg {
    cursor: auto;
}

.leave_workspace-container span {
    margin: 0 16px 0 13px;
    font-size: 16px;
    font-weight: 700;
    white-space: pre;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
}

.leave_workspace-container svg {
    margin-left: 16px;
}

.search {
    position: relative;
}

.search_wrapper_popup {
    margin: 15px 0;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    background-color: #ffffff;
    left: 0;
    transition: .4s;
    z-index: 22;
    box-shadow: 0 0 1px rgba(15, 23, 42, 0.06), 0 20px 25px -5px rgba(15, 23, 42, 0.1), 0 10px 10px -5px rgba(15, 23, 42, 0)

}

.workspace-nav-container {
    height: 100%;
    background-color: var(--gitgo-blue);
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
    max-width: 300px;
}

.title_of_search_category {
    background: #F8FAFC;
    border-radius: 8px 8px 0 0;
    padding: 8px 16px;
}

.title_of_search_category span {
    text-transform: capitalize;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #0F172A;
}

.customers_wrapper_search {
    display: grid;
    grid-gap: 5px;
    padding: 8px 16px;
}

.customers_wrapper_search span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #0F172A;
}

.integration_wrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3550;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

.integration_wrapper .integration_content {
    position: fixed;
    max-width: 615px;
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 3100;
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 2.5rem 60px 52px;
}

.integration_wrapper .integration_content p {
    font-size: 20px;
    line-height: 30px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    text-align: center;
    margin: 0 0 3rem;
}

.integration_wrapper .integration_content .inline {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    grid-gap: 1rem;
}

.integration_wrapper .integration_content .card {
    border: 1px solid #E2E8F0;
    text-align: center;
    padding: 0 1.5rem 2.75rem;
    height: 330px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.integration_wrapper .integration_content .card img {
    display: block;
    margin: 0 auto;
}

.integration_wrapper .integration_content .card span {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    display: block;
    margin: 0 auto 3rem;
}

.integration_wrapper .integration_content .card span {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    display: block;
    margin: 0 auto 3rem;
}

.integration_wrapper .integration_content .card button {
    background: var(--gitgo-blue);
    box-shadow: 0 17px 19px rgba(39, 98, 249, 0.1);
    border-radius: 2px;
    transition: .3s;
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    grid-gap: 8px;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding: 12px 0;
}

.integration_wrapper .integration_content .card button span {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #FFFFFF;
    margin: 0;
}

.integration_wrapper .integration_content .card button.added {
    background: #4BDE97;
}

.tooltip {
    position: relative;
    display: inline-block;
    cursor: context-menu;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: rgba(71, 85, 105, 0.8);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 20;
}

.tooltiptext {
    width: -webkit-max-content;
    width: max-content;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    width: -webkit-max-content;
    width: max-content;
    padding: 5px;
}

.header-company-name {
    overflow: hidden;
    max-width: 150px;
}
.profile_settings_wrapper h2 {
    margin: 0 0 2.5rem;
}

.profile_settings_wrapper .container {
    margin-left: 6rem;
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    border-radius: 8px;
    padding: 36px 85px 52px;
}

.profile_settings_wrapper .container .content {
    padding: 0 9px;
}

.profile_settings_wrapper .container .content p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    margin: 0 0 1.5rem;
}

.profile_settings_wrapper .container .content form .block {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem 30px;
    align-items: start;
}

.profile_settings_wrapper .container .content form .change_password {
    margin: 1.5rem 0 0;
}

.profile_settings_wrapper .container .content form .change_password p {
    font-size: 14px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    margin: 0 0 12px;
}

.profile_settings_wrapper .container .content form .change_password span {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #F26464;
    width: -webkit-max-content;
    width: max-content;
    cursor: pointer;
}

.profile_settings_wrapper .container .content form .line {
    background: #E2E8F0;
    margin: 32px 0 32px -9px;
    width: calc(100% + 18px);
    height: 1px;
}

.profile_settings_wrapper .container .content form .buttons_wrapper {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: center;
}

.profile_settings_wrapper .container .content form .buttons_wrapper button {
    width: 5rem;
    padding: 6px 0;
    text-transform: unset;
}
.footer-profile-edit{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.footer-profile-edit .error-text span{
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: red;
    width: -webkit-max-content;
    width: max-content;
}
.change-password-box {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 489px;
    max-height: 70vh;
    background: #fff;
    border-radius: 8px;
    padding: 24px 65px;
    border: 1px solid #CBD5E1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.change-password-title-box {
    width: 306px;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;
    margin-bottom: 24px;
}

.change-password-title-box > span {
    font-size: 20px;
    font-weight: 600;
    color: #0F172A;
    display: flex;
    text-align: center;
}

.change-password-buttons-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.change-password-buttons-container button {
    font-size: 14px;
    font-weight: 700;
    border-radius: 2px;
}

.change-password-buttons-container button:first-child {
    background-color: #FFFFFF;
    border: 1px solid var(--gitgo-blue);
    height: 36px;
    padding: 0 16px;
    color: var(--gitgo-blue);
}

.change-password-buttons-container button:last-child {
    background-color: var(--gitgo-blue);
    color: #FFFFFF;
    border: 1px solid var(--gitgo-blue);
    padding: 0 12px;
}
.manage_seats_wrapper h2 {
    margin: 0 0 3rem;
}

.manage_seats_wrapper .total_seats {
    background: #FFFFFF;
    border: 1px solid #478ECC;
    margin: 0 0 36px;
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    grid-gap: 1rem;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 2.75rem 1.5rem 1.5rem;
    max-width: 352px;
}

.manage_seats_wrapper .total_seats h6 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0 0 1rem;
}

.manage_seats_wrapper .total_seats span {
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
}

.manage_seats_wrapper .total_seats button {
    background: var(--gitgo-blue);
    border-radius: 2px;
    width: 4.5rem;
    height: 3rem;
    display: grid;
    align-items: center;
    justify-content: center;
}

.manage_seats_wrapper .content {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
    justify-content: center;
}

.manage_seats_wrapper .content .block-manage {
    background: #FFFFFF;
    border-radius: 8px;
}

.manage_seats_wrapper .content .block-manage .head, .manage_seats_wrapper .content .block-manage .body_content .item {
    display: grid;
    grid-template-columns: 4fr 5fr 2fr 1fr;
    align-items: center;
    grid-gap: 1.5rem;
    padding: 1.5rem 22px 23px 1rem;
    border-bottom: 1px solid #E2E8F0;
}

.manage_seats_wrapper .content .block-manage .body_content .item:last-of-type {
    border-bottom: none;
    padding: 1.5rem 22px 1.5rem 1rem;
}

.manage_seats_wrapper .content .block-manage .head .item {
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: .5rem;
}

.manage_seats_wrapper .content .block-manage .head .item span {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    text-transform: capitalize;
}

.manage_seats_wrapper .content .block-manage .body_content .item {
    padding: 20px 22px 12px 1rem;
}

.manage_seats_wrapper .content .block-manage .body_content .item p {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
}

.manage_seats_wrapper .content .block-manage .body_content .item span:not(.manage-seats-tooltip .manage-seats-tooltip-text) {
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    opacity: 0.5;
}

.manage_seats_wrapper .content .block-manage .body_content .item svg {
    margin-left: auto;
    cursor: pointer;
}

.manage-seats-icons-container {
    display: flex;
    justify-content: space-between;
}

.manage-seats-tooltip {
    position: relative;
    display: inline-block;
}

.manage-seats-tooltip .manage-seats-tooltip-text {
    visibility: hidden;
    width: 70px;
    background-color: rgba(71, 85, 105, 0.5);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 20;
    top: 100%;
    left: 50%;
    margin-left: -35px;
    transition: .2s;
    opacity: 0;
}

.manage-seats-tooltip:hover .manage-seats-tooltip-text {
    visibility: visible;
    opacity: 1
}
.new_opportunity_wrapper > .navigation {
    display: grid;
    grid-template-columns: repeat(5, -webkit-max-content);
    grid-template-columns: repeat(5, max-content);
    align-items: center;
    grid-gap: 8px;
    margin: 0 0 1rem;
}

.new_opportunity_wrapper > .title_wrap {
    margin: 0 auto 2.5rem;
    display: grid;
    grid-template-columns: 1fr -webkit-max-content;
    grid-template-columns: 1fr max-content;
    align-items: center;
    justify-content: space-between;
    max-width: 730px;
}

.new_opportunity_wrapper > .title_wrap button {
    background: transparent;
}

.content-custom {
    background: white;
    border-radius: 8px;
    border: 1px solid #CBD5E1;
    grid-gap: 0px!important;
}

.basic_info_custom {
    border: none;
}

.notes-custom { 
    margin: 0px 24px 24px 24px;
}

.notes-custom span {
    font-size: 14px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    text-transform: capitalize;
    color: #94A3B8;
}

.notes-style {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    margin: 0 0 12px;
}

.notes-label {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    margin: 0 0 .25rem;
}

.new_opportunity_wrapper > .content {
    max-width: 730px;
    margin: 0 auto 1.5rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
}

.new_opportunity_wrapper > .content > .basic_info, .new_opportunity_wrapper > .content > .additional_info {
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    border-radius: 8px;
    padding: 1.5rem;
}

.ant-picker:hover, .ant-picker-focused {
    border: 1px solid #475569;
    box-shadow: none;
}

.new_opportunity_wrapper > .content > .basic_info .inputs {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(2, 232px);
    justify-content: space-between;
    margin: 0 0 1.5rem;
}

.new_opportunity_wrapper > .content > .edit_basic_info {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(2, 232px);
    justify-content: space-between;
    margin: 0 0 10px;
}

.new_opportunity_wrapper > .content > .edit_basic_info .non_editable h6 {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    margin: 0 0 12px;
}

.new_opportunity_wrapper > .content > .edit_basic_info .non_editable span {
    font-size: 14px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    text-transform: capitalize;
    color: #475569;
}

.new_opportunity_wrapper > .content > .basic_info .full_width {
    grid-column: 1/-1;
}

.new_opportunity_wrapper > .content > .basic_info .buttons_wrapper, .new_opportunity_wrapper > .content > .additional_info .buttons_wrapper {
    justify-content: space-between;
}

.new_opportunity_wrapper > .content > .basic_info .buttons_wrapper button, .new_opportunity_wrapper > .content > .additional_info .buttons_wrapper button {
    width: 100px;
}

.delete_travel_opportunity {
    margin: 1.5rem auto 2.5rem;
    max-width: 730px;
    border: 1px solid #F26464;
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1.5rem 0;
    cursor: pointer;
}

.delete_travel_opportunity h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #F26464;
}

.delete_travel_opportunity span {
    font-size: 14px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
}
.prospects_wrapper .prospects_content .prospects_container > .head_opportunities {
    padding: 17px 50px 16px 60px;
    /* grid-template-columns: 200px minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(122px, 1fr) minmax(85px, 1fr) minmax(90px, 2fr) minmax(251px, 1fr) minmax(107px, 1fr) minmax(231px, 1fr); */
    grid-gap: 22px;
    grid-template-columns: 3fr 3fr 2fr 2fr 2fr;
    display: grid;
    border-bottom: 1px solid #E2E8F0;
    align-items: center;
    width: 100%;
    width: -moz-fit-content;
}
.prospects_wrapper .prospects_content .prospects_container > .container_body .item_opportunities {
    padding: 17px 50px 16px 20px;
    grid-template-columns: 20px 3fr 3fr 2fr 2fr 2fr;
    /* grid-template-columns: 200px minmax(200px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(122px, 1fr) minmax(85px, 1fr) minmax(90px, 2fr) minmax(252px, 1fr) minmax(210px, 1fr) minmax(117px, 1fr); */
    grid-gap: 22px;
    display: grid;
    border-bottom: 1px solid #E2E8F0;
    align-items: center;
    width: -moz-fit-content;
    width: 100%;
}
.prospects_wrapper .prospects_content .prospects_container > .container_body .item_opportunities .choose_item_wrap {
    display: grid;
    align-items: center;
    cursor: pointer;
    padding: 4px;
}
.prospects_wrapper .prospects_content .prospects_container > .container_body .item_opportunities p {
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
}
.workspace_wrapper .workspace_content .workspace_container > .container_body > .item_opportunities span, .prospects_wrapper .prospects_content .prospects_container > .container_body .item_opportunities span:not(.status) {
    font-size: 14px;
    line-height: 21px;
    color: #475569;
    opacity: 0.5;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    text-transform: capitalize;
}
.prospects_wrapper .prospects_content .prospects_container > .container_body .item_opportunities .choose_item {
    display: grid;
    align-items: center;
    justify-content: center;
    border: 2px solid #478ECC;
    width: 1rem;
    height: 1rem;
}
.prospects_wrapper .prospects_content .prospects_container > .container_body .item_opportunities .choose_item svg {
    -webkit-transform: scale(.8) rotate(-20deg);
            transform: scale(.8) rotate(-20deg);
    opacity: 0;
    transition: .3s;
}

.prospects_wrapper .prospects_content .prospects_container > .container_body .item_opportunities .choose_item_active svg {
    -webkit-transform: scale(1) rotate(0);
            transform: scale(1) rotate(0);
    opacity: 1;
}
.prospects_wrapper .prospects_content .prospects_container > .head_opportunities .item {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    cursor: pointer;
}
.workspace_wrapper .workspace_content .workspace_container > .head .item span, .prospects_wrapper .prospects_content .prospects_container > .head_opportunities .item span {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    text-transform: capitalize;
    word-break: break-word;
    color: #0F172A;
}

.user_info_wrapper {
    padding: 5.5rem 2.25rem 40px 2.75rem;
    margin: -5.5rem -2.25rem -40px -2.75rem;
    background: #FFFFFF;
}

.user_info_wrapper .navigation {
    display: grid;
    grid-template-columns: repeat(3, -webkit-max-content);
    grid-template-columns: repeat(3, max-content);
    align-items: center;
    grid-gap: 8px;
    margin: 0 0 1rem;
}

.user_info_wrapper .navigation a {
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
}

.user_info_wrapper .navigation span {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
}

.user_info_wrapper .title_wrap {
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;
    margin: 0 0 2rem;
}

.user_info_wrapper .contact_details h6 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0 0 1rem;
}

.user_info_wrapper .contact_details .content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem 2rem;
    align-items: center;
    margin: 0 0 1rem;
    padding: 0 0 1.5rem;
    border-bottom: 1px solid #E2E8F0;
    max-width: 540px;
}

.user_info_wrapper .contact_details .content .full_line {
    grid-column: 1/-1;
}

.user_info_wrapper .contact_details .content span {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    text-transform: capitalize;
    display: block;
    margin: 0 0 8px;
}

.user_info_wrapper .contact_details .content p {
    font-size: 14px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
}

.user_info_wrapper .contact_details .content .prospects {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
}

.user_info_wrapper .contact_details .content .prospects a {
    font-size: 14px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    background: rgba(150, 200, 237, 0.2);
    border-radius: 2px;
    padding: 4px 8px;
    margin: 8px;
}

.user_info_wrapper .contact_details .delete_contact {
    margin: 0 0 3.25rem;
    padding: 0 0 1.5rem;
    max-width: 540px;
    border-bottom: 1px solid #E2E8F0;
}

.user_info_wrapper .contact_details .delete_contact p {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    margin: 0 0 8px;
}

.user_info_wrapper .contact_details .delete_contact div {
    cursor: pointer;
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    grid-gap: 8px;
    align-items: center;
}

.user_info_wrapper .contact_details .delete_contact span {
    font-size: 14px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--error);
}

.user_info_wrapper .activity_wrapper .title_wrap {
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;
    margin: 0 0 1.75rem;
}

.user_info_wrapper .activity_wrapper .title_wrap > span {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
}

.user_info_wrapper .activity_wrapper .title_wrap > .add_activity_log {
    position: relative;
}

.user_info_wrapper .activity_wrapper .title_wrap > .add_activity_log .button_default_plus {
    padding: 6px 1rem;
    font-weight: bold;
}

.user_info_wrapper .activity_wrapper .title_wrap > .add_activity_log .content {
    position: absolute;
    top: 2.5rem;
    right: 0;
    display: grid;
    background: #FFFFFF;
    border: 1px solid #E2E8F0;
    box-shadow: 0 0 1px rgba(15, 23, 42, 0.06), 0 10px 15px -3px rgba(15, 23, 42, 0.1), 0 4px 6px -2px rgba(15, 23, 42, 0.05);
    border-radius: 2px;
    width: 194px;
    padding: 4px 8px;
    z-index: 100;
    grid-gap: 4px;
    grid-template-columns: 1fr;
}

.user_info_wrapper .activity_wrapper .title_wrap > .add_activity_log .content div {
    display: grid;
    grid-template-columns: -webkit-max-content 1fr;
    grid-template-columns: max-content 1fr;
    grid-gap: 8px;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    border-radius: 2px;
    cursor: pointer;
    color: #0F172A;
    transition: .3s;
    padding: 8px;
    text-transform: capitalize;
}

.user_info_wrapper .activity_wrapper .title_wrap > .add_activity_log .content div svg path {
    transition: .3s;
}

.user_info_wrapper .activity_wrapper .title_wrap > .add_activity_log .content div:hover {
    background: #F8FAFC;
}

.user_info_wrapper .activity_wrapper .title_wrap > .add_activity_log .content div:hover svg path {
    stroke: var(--gitgo-blue);
}

.user_info_wrapper .activity_wrapper .activity_content {
    border: 1px solid #CBD5E1;
    padding: 1.5rem;
    max-height: 600px;
    overflow-y: auto;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item {
    display: grid;
    grid-template-columns: 5fr 7fr;
    align-items: start;
    grid-gap: 1.5rem;
    padding: 0 0 1rem;
    margin: 0 0 1rem;
    border-bottom: 1px solid #E2E8F0;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item:last-of-type {
    padding: 0;
    margin: 0;
    border-bottom: none;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item .logged {
    display: grid;
    grid-template-columns: repeat(2, -webkit-max-content);
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 1rem;
    margin: 0 0 1.5rem;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item .logged svg path {
    stroke: var(--gitgo-blue);
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item .max_width {
    max-width: 325px;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item .logged span {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    text-transform: capitalize;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item .left span.time {
    font-size: 14px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #94A3B8;
    text-transform: capitalize;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item .notes {
    display: grid;
    grid-template-columns: -webkit-max-content 1fr;
    grid-template-columns: max-content 1fr;
    grid-gap: 12px;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item .notes > span {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item .notes p {
    font-size: 14px;
    line-height: 17px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #94A3B8;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item .calendar_wrapper .title {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item_new .notes {
    grid-template-columns: 1fr;
    grid-gap: 4px;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item_new .notes .input_component {
    margin: 0;
    display: grid;
    align-items: center;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item_new .buttons_wrapper {
    grid-column: 1/-1;
    justify-content: end;
}

.user_info_wrapper .activity_wrapper .activity_content .activity_item_new .buttons_wrapper button {
    width: 5rem;
}

.user_info_wrapper .contact_details .content span.unassigned {
    background: #E2E8F0;
    border-radius: 2px;
    padding: 4px 8px;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #94A3B8;
    text-transform: capitalize;
    display: unset;
    margin: 0;
}
.relative_wrapper {
    position: relative;
}

.support_modal_wrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 35500;
    background: rgba(0, 0, 0, 0.6);
    -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
}

.support_modal_window {
    /* overflow: auto;
    padding: 32px 56px; */
    position: fixed;
    max-width: 817px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 3100;
    /* background: #FFFFFF;
    border: 1px solid #CBD5E1; */
    box-sizing: border-box;
    border-radius: 8px;
    max-height: 690px;

}
/* .support_modal_window{
     display: flex;
     justify-content: center;
     max-height: 690px;
} */

.support_modal_wrapper .support_modal_window .support_header_window h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    color: var(--gitgo-blue);
}

.support_modal_wrapper .support_modal_window .support_header_window span {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    display: block;
    margin-top: 4px;
    color: #94A3B8;
}

.support_modal_wrapper .support_modal_window .support_fields_wrapper {
    margin-top: 40px;
}

.fields_list {
    display: grid;
    grid-gap: 16px;
}

.custom_input .message_input input {
    height: 159px;
}

.fields_list span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #0F172A;
}

.support_footer_submit_block {
    padding-top: 16px;
    display: flex;
    justify-content: center;
}

.support_footer_submit_block .please_help_button {
    padding: 12px 60px;
    background: var(--gitgo-blue);
    box-shadow: 0 17px 19px rgba(39, 98, 249, 0.1);
    border-radius: 2px;
    width: 160px;
    height: 48px;
}

.fields_list .support_footer_submit_block .please_help_button span {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.support_footer_submit_block .file_input_block {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.support_footer_submit_block .file_input_block label {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: var(--gitgo-blue);
    margin-left: 5px;
    cursor: pointer;
}

#file {
    display: none;
}

#name_field,
#email_field {
    border-radius: 0;
}

#message_input {
    height: 100px;
    border-radius: 0;

}



.login_wrapper {
    height: 100vh;
    width: 100%;
    background: #FFFFFF;
    position: fixed;
}

.login_wrapper > .content {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%;
    max-width: 543px;
}

.login_wrapper > .content > .head {
    background: var(--gitgo-blue);
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 6px 0;
}

.login_wrapper > .content > .head img {
    max-width: 100%;
    width: 81px;
}

.login_wrapper > .content > .card_content {
    background: #F8FAFC;
    padding: 2.75rem 3.75rem 3.25rem;
}

.login_wrapper > .content > .card_content h2 {
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    text-transform: uppercase;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    margin: 0 0 2rem;
}

.login_wrapper > .content > .card_content_setup h2 {
    text-align: left;
    margin: 0 0 .5rem;
}

.login_wrapper > .content > .card_content_setup p {
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0 0 1rem;
}

.login_wrapper > .content > .card_content form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    align-items: center;
    margin: 0 0 2rem;
}

.login_wrapper > .content > .card_content a.forgot_password {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    display: block;
    width: -webkit-max-content;
    width: max-content;
    margin: 0 auto;
}

.login_wrapper p.login_error_format {
    font-size: 14px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--error);
    margin: 0;
}
.forgot_password_wrapper {
    width: 100%;
    height: 100vh;
    display: grid;
    align-items: center;
    justify-content: center;
}

.forgot_password_wrapper .content {
    width: 547px;
}

.forgot_password_wrapper .content .card {
    margin: 0 0 2rem;
}

.forgot_password_wrapper .content .head {
    background: var(--gitgo-blue);
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 6px 0;
}

.forgot_password_wrapper .content .head img {
    max-width: 100%;
    width: 81px;
}

.forgot_password_wrapper .content .body {
    padding: 2rem 58px 5rem;
    background: #F8FAFC;
}

.forgot_password_wrapper .content .body h4 {
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    margin: 0 0 8px;
}

.forgot_password_wrapper .content .body p {
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0 0 16px;
}

.forgot_password_wrapper .content .body form {
    display: grid;
    grid-gap: 2rem;
}

.forgot_password_wrapper .content .body button {
    padding: 12px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #FFFFFF;
    background: var(--gitgo-blue);
    border-radius: 2px;
}

.forgot_password_wrapper .content a {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    display: block;
    width: -webkit-max-content;
    width: max-content;
    margin: 0 auto;
}

.forgot_password_wrapper .content .body_success {
    padding: 4.5rem 58px 6.25rem;
    background: #F8FAFC;
}

.forgot_password_wrapper .content .body_success h3 {
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    text-transform: uppercase;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    margin: 0 0 2rem;
}

.forgot_password_wrapper .content .body_success button {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #FFFFFF;
    padding: 12px 22px;
    background: var(--gitgo-blue);
    border-radius: 2px;
    width: -webkit-max-content;
    width: max-content;
    margin: 0 auto;
    display: block;
    transition: .3s;
}

.forgot_password_wrapper .content .body_success button:disabled {
    background: #475569;
}
.recovery_wrapper {
    display: grid;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.recovery_wrapper .content {
    width: 543px;
}

.recovery_wrapper .content .head {
    background: var(--gitgo-blue);
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 6px 0;
}

.recovery_wrapper .content .head img {
    max-width: 100%;
    width: 81px;
}

.recovery_wrapper .content .body {
    padding: 2rem 3.5rem 1rem;
    background: #F8FAFC;
}

.recovery_wrapper .content .body h3 {
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    margin: 0 0 8px;
}

.recovery_wrapper .content .body p {
    font-size: 16px;
    line-height: 24px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0 0 1rem;
}

.recovery_wrapper .content .body form {
    display: grid;
    grid-gap: 2rem;
}

.recovery_wrapper .content .body button {
    padding: 12px 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #FFFFFF;
    background: var(--gitgo-blue);
    border-radius: 2px;
}

.recovery_wrapper .content .body p.token_error {
    font-size: 14px;
    line-height: 21px;
    -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
            font-feature-settings: 'tnum' on, 'lnum' on;
    color: #94A3B8;
    margin: 0;
}
