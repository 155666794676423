.relative_wrapper {
    position: relative;
}

.support_modal_wrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 35500;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(8px);
}

.support_modal_window {
    /* overflow: auto;
    padding: 32px 56px; */
    position: fixed;
    max-width: 817px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3100;
    /* background: #FFFFFF;
    border: 1px solid #CBD5E1; */
    box-sizing: border-box;
    border-radius: 8px;
    max-height: 690px;

}
/* .support_modal_window{
     display: flex;
     justify-content: center;
     max-height: 690px;
} */

.support_modal_wrapper .support_modal_window .support_header_window h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    color: var(--gitgo-blue);
}

.support_modal_wrapper .support_modal_window .support_header_window span {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    display: block;
    margin-top: 4px;
    color: #94A3B8;
}

.support_modal_wrapper .support_modal_window .support_fields_wrapper {
    margin-top: 40px;
}

.fields_list {
    display: grid;
    grid-gap: 16px;
}

.custom_input .message_input input {
    height: 159px;
}

.fields_list span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #0F172A;
}

.support_footer_submit_block {
    padding-top: 16px;
    display: flex;
    justify-content: center;
}

.support_footer_submit_block .please_help_button {
    padding: 12px 60px;
    background: var(--gitgo-blue);
    box-shadow: 0 17px 19px rgba(39, 98, 249, 0.1);
    border-radius: 2px;
    width: 160px;
    height: 48px;
}

.fields_list .support_footer_submit_block .please_help_button span {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.support_footer_submit_block .file_input_block {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.support_footer_submit_block .file_input_block label {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: var(--gitgo-blue);
    margin-left: 5px;
    cursor: pointer;
}

#file {
    display: none;
}

#name_field,
#email_field {
    border-radius: 0;
}

#message_input {
    height: 100px;
    border-radius: 0;

}


