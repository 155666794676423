.contacts_popup_wrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3550;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

.contacts_popup_wrapper .content {
    position: fixed;
    max-width: 817px;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3100;
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 1.5rem 0 20px;
    max-height: 75vh;
    height: 75vh;
    overflow: auto;
}

.contacts_popup_wrapper .content > .head, .contacts_popup_wrapper .content > .head .inline, .contacts_popup_wrapper .content .platform_contact > .head .inline {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;
    grid-gap: 1rem;
    width: 100%;
    position: relative;
}

.contacts_popup_wrapper .content > .head {
    margin: 0 0 1.5rem;
}

.contacts_popup_wrapper .content > .head h4 {
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
}

.contacts_popup_wrapper .content > .head .inline > span {
    background: #BEDEF5;
    border-radius: 200px;
    padding: 0 1rem;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
}

.contacts_popup_wrapper .content > .head .inline button {
    width: 91px;
    padding: 5px 0;
    justify-content: center;
}

.contacts_popup_wrapper .content > .head .inline .popup {
    position: absolute;
    top: 3rem;
    width: 230px;
    right: 0;
    background: #FFFFFF;
    box-shadow: 0 0 1px rgba(15, 23, 42, 0.06), 0 20px 25px -5px rgba(15, 23, 42, 0.1), 0 10px 10px -5px rgba(15, 23, 42, 0.04);
    border-radius: 2px;
    display: grid;
    grid-template-columns: 1fr;
    max-height: 0;
    overflow: hidden;
}

.contacts_popup_wrapper .content > .head .inline .popup_active {
    max-height: 120px;
}

.contacts_popup_wrapper .content > .head .inline .popup span:hover {
    font-weight: 600;
}

.contacts_popup_wrapper .content .platform_contact > .head {
    border-bottom: 1px solid #E2E8F0;
    padding: 18px 0 27px;
}

.contacts_popup_wrapper .content .platform_contact > .head .inline {
    width: max-content;
}

.contacts_popup_wrapper .content .platform_contact > .head h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
}

.contacts_popup_wrapper .content .platform_contact > .head span {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    padding: 0 10px;
    background: #BEDEF5;
    border-radius: 200px;
}

.contacts_popup_wrapper .content .body_create {
    display: grid;
    grid-gap: 1rem 2rem;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    justify-content: space-between;
    padding: 1.5rem 0 .75rem;
}

.contacts_popup_wrapper .content .body_create .button_wrap {
    grid-column: 1/-1;
    margin: 1rem 0 0 auto;
}

.contacts_popup_wrapper .content .body .item {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr max-content;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 0 .75rem;
    border-bottom: 1px solid #E2E8F0;
}

.contacts_popup_wrapper .content .body .item > .left, .contacts_popup_wrapper .content .body .item > .right {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 133px 1fr;
    align-items: center;
}

.contacts_popup_wrapper .content .body .item > .left > div, .contacts_popup_wrapper .content .body .item > .right > div {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr;
    align-items: center;
}

.contacts_popup_wrapper .content .body .item > .right {
    display: grid;
    grid-gap: 2.5rem;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
}

.contacts_popup_wrapper .content .body .item h6 {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
}

.contacts_popup_wrapper .content .body .item p, .contacts_popup_wrapper .content .body .item span {
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
}

.contacts_popup_wrapper .content .body .item span {
    display: block;
    color: #94A3B8;
}

.contacts_popup_wrapper .content .body .item .popup_wrap {
    position: relative;
}

.contacts_popup_wrapper .content .body .item .popup_wrap svg {
    cursor: pointer;
}
.platform_contact .list_of_contacts{
    overflow-y:scroll ;
    height: 150px;
    padding: 0 1.5rem 0;
}

.platform_contact.create_contact {
    padding: 0 1.5rem 0;
}

.platform_contact .header_block_of_contact .inline,
.content .header_block_of_contact {
    padding: 0 1.5rem 0;
}
.contacts_popup_wrapper .platform_contact .popup_content.modal_contact_popup{
    top:-32px!important;
    right: 20px!important;
}