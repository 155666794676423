.new_prospect_wrapper > .navigation {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    align-items: center;
    grid-gap: 8px;
    margin: 0 0 1rem;
}

.notes { 
    margin: 20px;
    margin-top: -4px;
}

.notes-modal-text {
    color: rgb(148, 163, 184);
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.notes-modal-full-text {
    color: rgb(148, 163, 184);
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: auto;
    text-overflow: ellipsis;
}

.view_all span {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    text-decoration: underline;
}

.view_all {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 0.6rem;
    align-items: center;
    justify-content: space-between;
}

.new_prospect_wrapper > .navigation a, .new_prospect_wrapper > .navigation span, .new_opportunity_wrapper > .navigation a, .new_opportunity_wrapper > .navigation span {
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
}

.engagement_line_custom {
    align-items: center;
    display: grid;
    padding: 12px 0;
    grid-template-columns: 200px 400px 100px;
    justify-content: space-between;
    border-bottom: 1px solid #E2E8F0;
}

.new_prospect_wrapper > .navigation span, .new_opportunity_wrapper > .navigation span {
    font-weight: bold;
}

.new_prospect_wrapper h2 {
    margin: 0 0 1.5rem;
}

.new_prospect_wrapper .new_prospect_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    align-items: start;
}

.new_prospect_wrapper .new_prospect_container .basic_info {
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    padding: 1.5rem;
    width: 100%;
}

.new_prospect_wrapper .new_prospect_container .basic_info .name_info, .new_prospect_wrapper .new_prospect_container .basic_info .industry_info, .new_prospect_wrapper .new_prospect_container .basic_info .billing_info, .new_prospect_wrapper .new_prospect_container .basic_info .travel_info, .new_prospect_wrapper .new_prospect_container .basic_info .competitors_info {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    margin: 2rem 0;
    padding: 0 0 2rem;
    border-bottom: 1px solid #E2E8F0;
}

.new_prospect_wrapper .new_prospect_container .basic_info .billing_info .full_line {
    grid-column: 1/-1;
}

.new_prospect_wrapper .new_prospect_container .basic_info .buttons_wrapper {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;
    margin: 2rem 0 0;
}

.new_prospect_wrapper .new_prospect_container .basic_info .buttons_wrapper button {
    width: 100px;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap, .new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap {
    background: #FFFFFF;
    border-radius: 14px;
    border: 1px solid #CBD5E1;
    padding: 1.5rem 1rem;
    margin: 0 0 1.75rem;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .title, .new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .title {
    display: grid;
    grid-template-columns: 1fr max-content;
    text-transform: capitalize;
    align-items: center;
    justify-content: space-between;
    grid-gap: 1rem;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .title .inline {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 1rem;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .title .inline span {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    background: #BEDEF5;
    border-radius: 200px;
    padding: 0 10px;
}
.notes-label {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    margin: 0 0 .25rem;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .title h3, .new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .title h3 {
    font-weight: 800;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options {
    margin: 2.5rem -1rem 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2px;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .item_title {
    padding: 6px 1rem;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 24px;
    justify-content: space-between;
    align-items: center;
    background: #F8FAFC;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .item_title span {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .item_title .open {
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: relative;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .item_title .open div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0);
    width: 14px;
    height: 2px;
    border-radius: 16px;
    background: var(--gitgo-blue);
    transition: .3s;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .item_title .open div:nth-child(2) {
    transform: translate(-50%, -50%) rotate(90deg);
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .item_title .opened div:nth-child(2) {
    transform: translate(-50%, -50%) rotate(0);
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .opportunity_content {
    padding: 6px 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
    justify-content: space-between;
    align-items: start;
    margin: 0 0 1.5rem;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .edit_opportunity_content {
    padding: 1.5rem 1rem 2rem;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
    border-bottom: 1px solid #E2E8F0;
    margin: 0 0 2rem;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .edit_opportunity_content .edit_buttons, .new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .edit_opportunity_content .edit_buttons .view_profile {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: .25rem;
    align-items: center;
    justify-content: space-between;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .edit_opportunity_content .edit_buttons .view_profile {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    text-decoration: underline;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .edit_opportunity_content .half_screen {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.75rem;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .edit_opportunity_content .half_screen .title {
    margin: 0 0 .75rem;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .edit_opportunity_content .half_screen h6 {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .edit_opportunity_content .half_screen span {
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    text-transform: capitalize;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .opportunity_content .full_width {
    grid-column: 1/-1;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .buttons_wrapper {
    justify-content: space-between;
    padding: 0 1rem;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap .opportunity_options .buttons_wrapper button {
    width: 100px;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .body {
    margin: 2rem 0 0;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .body .contacts_edit {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    margin: 1.5rem 0 0;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .body .contacts_edit .submit_button {
    width: 90px;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .body .item {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr max-content;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid #E2E8F0;
    cursor: pointer;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .body .item .inline {
    display: grid;
    grid-gap: .5rem;
    grid-template-columns: 133px 1fr;
    align-items: center;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .body .item h6, .new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .body .item span, .new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .body .item p {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .body .item p {
    font-weight: 400;
    color: #475569;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .body .item span {
    font-weight: 400;
    color: #94A3B8;
    display: block;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .body .item .full {
    display: grid;
    grid-gap: .5rem;
    grid-template-columns: 1fr;
    align-items: center;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap .body .view_all {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    text-decoration-line: underline;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 8px;
    align-items: center;
    width: max-content;
}

.new_prospect_wrapper .new_prospect_container .opportunities_contacts > .opportunities_wrap p.error, .new_prospect_wrapper .new_prospect_container .opportunities_contacts > .contacts_wrap p.error {
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    margin: 1rem 0 0;
    color: #94A3B8;
    text-align: center;
}

.new_prospect_wrapper .new_prospect_container .edit_basic_info .name_edit {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 2.5rem;
    align-items: start;
    padding: 0 0 2rem;
    border-bottom: 1px solid #E2E8F0;
}

.new_prospect_wrapper .new_prospect_container .edit_basic_info .name_edit_content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    align-items: center;
    justify-content: space-between;
}

.new_prospect_wrapper .new_prospect_container .edit_basic_info .title {
    margin: 0 0 .5rem;
}

.new_prospect_wrapper .new_prospect_container .edit_basic_info .title_inline {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 8px;
}

.new_prospect_wrapper .new_prospect_container .edit_basic_info h6 {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
}

.new_prospect_wrapper .new_prospect_container .edit_basic_info .content span {
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    display: block;
    text-transform: capitalize;
}

.prospects-status-container {
    background-color: rgba(1, 66, 106, 0.1);
    padding: 4px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 180px;
}
.item_opportunities .w-max-c{
    width: max-content;
}

.prospects-status-container span {
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    color: var(--gitgo-blue);
}

.new_prospect_wrapper .new_prospect_container .edit_basic_info .content span.status {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
    display: block;
    background: #F2F8FD;
    border-radius: 2px;
    padding: 4px 8px;
    width: max-content;
}

.new_prospect_wrapper .new_prospect_container .edit_basic_info .industry_edit {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    align-items: start;
    padding: 2rem 0;
    border-bottom: 1px solid #E2E8F0;
}

.new_prospect_wrapper .new_prospect_container .edit_basic_info .industry_edit .top {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
    align-items: center;
}

.new_prospect_wrapper .new_prospect_container .edit_basic_info .industry_edit .bottom {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
    align-items: center;
}

.new_prospect_wrapper .new_prospect_container .edit_basic_info .billing_edit {
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 2rem 0;
    border-bottom: 1px solid #E2E8F0;
}

.new_prospect_wrapper .new_prospect_container .edit_basic_info .travel_edit, .new_prospect_wrapper .new_prospect_container .edit_basic_info .competitors_edit {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 2rem 0;
    grid-gap: 2rem;
    border-bottom: 1px solid #E2E8F0;
}

.new_prospect_wrapper .new_prospect_container .edit_basic_info .competitors_edit {
    margin: 0 0 2rem;
}

.new_prospect_wrapper .delete_travel_opportunity {
    max-width: 100%;
    width: 100%;
    margin: 0 0 2rem;
}

@media (max-width: 1300px) {
    .new_prospect_wrapper .new_prospect_container .edit_basic_info .name_edit {
        grid-gap: 1.5rem;
        grid-template-columns: 1fr;
    }

    .new_prospect_wrapper .new_prospect_container .edit_basic_info .name_edit button {
        margin-left: auto;
        grid-column: 1;
        grid-row: 1;
    }

    .new_prospect_wrapper .new_prospect_container .edit_basic_info .name_edit_content {
        grid-template-columns: repeat(2, max-content);
    }
}