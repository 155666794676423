.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2500;
    width: 100%;
    display: grid;
    background-color: #FFFFFF;
    grid-template-columns: 248px 1fr;
    align-items: center;
}

.header > .logo_container {
    background: var(--gitgo-blue);
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 14px 0;
    border-right: 1px solid #CBD5E1;
}

.header > .logo_container_project {
    display: grid;
    /*
    grid-template-columns: max-content 1fr;
    justify-content: start;
    padding: 14px 1rem 14px 2rem;
     */
}

.header > .logo_container_project span:not(.tooltiptext) {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #F8FAFC;
    opacity: 0.7;
    text-overflow: ellipsis;
    display: block;
    white-space: pre;
    overflow: hidden;
    width: 150px;
}


.header > .header_content {
    display: flex;
    grid-template-columns: repeat(3, max-content);
    align-items: center;
    padding: 0 3rem 0 2.75rem;
    justify-content: space-between;
    border-bottom: 1px solid #CBD5E1;
    height: 56px;
    grid-gap: 1em;
}

.header > .header_content .search_bar {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
}

.header > .header_content .search_bar_admin {
    grid-template-columns: max-content 1fr;
    grid-gap: 3rem;
}

.header > .header_content .search_bar_admin .leave_workspace {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 10px;
    cursor: pointer;
}

.header > .header_content .search_bar_admin .leave_workspace span {
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    max-width: 300px;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
    display: block;
}

.header > .header_content .search_bar_admin .leave_workspace svg {
    transition: .3s;
}

.header > .header_content .search_bar_admin .leave_workspace:hover svg {
    transform: translateX(-2px);
}

.header > .header_content .profile_popup_wrapper {
    position: relative;
    width: max-content;
    margin: 0 0 0 40px;
}

.header > .header_content .profile_popup_wrapper > .user_name {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: end;
    margin-left: auto;
    width: max-content;
    grid-gap: 1rem;
    cursor: pointer;
    align-items: center;
}

.header > .header_content .profile_popup_wrapper > .user_name_admin {
    grid-template-columns: max-content;
    grid-gap: 0;
    cursor: auto;
}

.header > .header_content .profile_popup_wrapper > .user_name span {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
}

.header > .header_content .profile_popup_wrapper .profile_popup {
    position: absolute;
    top: 37px;
    width: 238px;
    right: -2rem;
    background: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0 0 1px rgba(15, 23, 42, 0.06), 0 10px 15px -3px rgba(15, 23, 42, 0.1), 0 4px 6px -2px rgba(15, 23, 42, 0.05);
    border-radius: 2px;
    display: grid;
    grid-template-columns: 1fr;
    max-height: 0;
    transition: .3s;
    overflow-y: hidden;
}

.header > .header_content .profile_popup_wrapper .profile_popup_active {
    max-height: 200px;
}

.header > .header_content .profile_popup_wrapper .profile_popup a,
.header > .header_content .profile_popup_wrapper .profile_popup button, .header > .header_content .profile_popup_wrapper .profile_popup span, .contacts_popup_wrapper .content > .head .inline .popup span {
    display: block;
    padding: 8px;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    transition: .3s;
    border-radius: 2px;
    cursor: pointer;
    margin: 4px;
    text-align: left;
}

.header > .header_content .profile_popup_wrapper .profile_popup a:hover, .header > .header_content .profile_popup_wrapper .profile_popup span:hover,
.header > .header_content .profile_popup_wrapper .profile_popup button:hover {
    background: #F2F8FD;
}

.header > .header_content .profile_popup_wrapper .profile_popup span {
    color: #F26464;
}

.leave_workspace-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.leave_workspace-container svg {
    cursor: auto;
}

.leave_workspace-container span {
    margin: 0 16px 0 13px;
    font-size: 16px;
    font-weight: 700;
    white-space: pre;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
}

.leave_workspace-container svg {
    margin-left: 16px;
}

.search {
    position: relative;
}

.search_wrapper_popup {
    margin: 15px 0;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    background-color: #ffffff;
    left: 0;
    transition: .4s;
    z-index: 22;
    box-shadow: 0 0 1px rgba(15, 23, 42, 0.06), 0 20px 25px -5px rgba(15, 23, 42, 0.1), 0 10px 10px -5px rgba(15, 23, 42, 0)

}

.workspace-nav-container {
    height: 100%;
    background-color: var(--gitgo-blue);
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
    max-width: 300px;
}

.title_of_search_category {
    background: #F8FAFC;
    border-radius: 8px 8px 0 0;
    padding: 8px 16px;
}

.title_of_search_category span {
    text-transform: capitalize;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #0F172A;
}

.customers_wrapper_search {
    display: grid;
    grid-gap: 5px;
    padding: 8px 16px;
}

.customers_wrapper_search span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #0F172A;
}

.integration_wrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3550;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

.integration_wrapper .integration_content {
    position: fixed;
    max-width: 615px;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3100;
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 2.5rem 60px 52px;
}

.integration_wrapper .integration_content p {
    font-size: 20px;
    line-height: 30px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    text-align: center;
    margin: 0 0 3rem;
}

.integration_wrapper .integration_content .inline {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    grid-gap: 1rem;
}

.integration_wrapper .integration_content .card {
    border: 1px solid #E2E8F0;
    text-align: center;
    padding: 0 1.5rem 2.75rem;
    height: 330px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.integration_wrapper .integration_content .card img {
    display: block;
    margin: 0 auto;
}

.integration_wrapper .integration_content .card span {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    display: block;
    margin: 0 auto 3rem;
}

.integration_wrapper .integration_content .card span {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    display: block;
    margin: 0 auto 3rem;
}

.integration_wrapper .integration_content .card button {
    background: var(--gitgo-blue);
    box-shadow: 0 17px 19px rgba(39, 98, 249, 0.1);
    border-radius: 2px;
    transition: .3s;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 8px;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding: 12px 0;
}

.integration_wrapper .integration_content .card button span {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #FFFFFF;
    margin: 0;
}

.integration_wrapper .integration_content .card button.added {
    background: #4BDE97;
}

.tooltip {
    position: relative;
    display: inline-block;
    cursor: context-menu;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: rgba(71, 85, 105, 0.8);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 20;
}

.tooltiptext {
    width: max-content;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    width: max-content;
    padding: 5px;
}

.header-company-name {
    overflow: hidden;
    max-width: 150px;
}