
.apexcharts-zoom-icon, .apexcharts-reset-icon, .apexcharts-menu-icon {
    display: none !important;
}
.apexcharts-legend.position-bottom .apexcharts-legend-series, .apexcharts-legend.position-top .apexcharts-legend-series{
    margin: 2px 45px!important;

}
.arrow_box{
    padding: 16px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr;
}
.count_footer{
    padding-top: 10px;
    border-top: 1px solid #d7d5d5;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.status_block{
    display: grid;
    grid-gap: 5px;
}
.block_name{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #475569;

}
.status_type{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}
.count_number{
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 39px;
    color: var(--gitgo-blue);
}
.record_type{
    border-left: 1px solid #475569;
    font-style: normal;
    padding-left: 5px;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #475569;
}
.bar_tags{
    fill-opacity: 0!important;
}
.apexcharts-tooltip {
    width: 274px;
    height: 149px;
    background: #f3f3f3;
}
#my_Chart{
    width: 300px!important;
    height: 320px!important;
}
.mixed-chart{
    inset: 40px 25px;
}
@media (max-width: 1680px) {
    .mixed-chart {
        inset: 40px -5px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
@media (max-width: 1220px) {
    #my_Chart{
        width: 350px!important;
        height: 320px!important;
    }
    .mixed-chart {
        inset: -20px -7px;
    }

}
.block-names{
    /* display: grid; */
    grid-template-columns: 70px 70px;
    grid-row-gap: 20px;
    grid-column-gap: 70px;
}
.block-names .block-name{
    display: grid;
    padding-bottom: 10px;
    align-items: center;
    grid-template-columns: max-content max-content;
    gap: 5px;
}
.block-names .block-name .block {
    padding: 5;
    height: 10px;
    width: 10px;
    background-color: #8e9bae;
    border-radius:4px;
}
/* .block-names .block-name .block-new {
    background-color: #FFB648 ;
} */