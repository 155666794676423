.success_popup_wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    z-index: 5000;
}

.data_submitted {
    position: static;
    width: 100%;
    height: auto;
    background: #FFFFFF;
    backdrop-filter: unset;
    -webkit-backdrop-filter: unset;
}

.success_popup_wrapper .success_content {
    z-index: 5001;
    position: absolute;
    padding: 2rem 138px 126px;
    top: 86px;
    left: 50%;
    transform: translateX(-50%);
    width: 817px;
    max-height: calc(100vh - 172px);
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    overflow-y: auto;
}

.data_submitted .success_content {
    position: static;
    padding: 0;
    transform: unset;
    width: 100%;
    max-height: unset;
    background: #FFFFFF;
    border: none;
    overflow-y: unset;
    max-width: 920px;
}

.success_popup_wrapper .success_content h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0 0 3.5rem;
    text-align: center;
}

.success_popup_wrapper .success_content .render_content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    grid-gap: 1.5rem 30px;
    padding: 1.5rem 0;
    border-bottom: 1px solid #E2E8F0;
}

.success_popup_wrapper .success_content .render_content h5 {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0 0 8px;
}

.success_popup_wrapper .success_content .render_content .data_line {
    width: 100%;
    height: 1px;
    background: #E2E8F0;
    grid-column: 1/-1;
}

.success_popup_wrapper .success_content .render_content h5 span span {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    color: #94A3B8;
}

.success_popup_wrapper .success_content .render_content p {
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0;
}

.success_popup_wrapper .success_content .send_wrapper {
    margin: 1.5rem 0 0;
}

.success_popup_wrapper .success_content .send_wrapper h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    margin: 0 0 2rem;
}

.success_popup_wrapper .success_content .send_wrapper .buttons_wrapper {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;
    margin: 2.5rem 0 0;
}

.success_popup_wrapper .success_content .send_wrapper .buttons_wrapper .inline {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 1rem;
}

.success_popup_wrapper .success_content .send_wrapper .buttons_wrapper .cancel {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #13426B;
    height: 36px;
    width: 100px;
    border: 1px solid #13426B;
    border-radius: 2px;
}

.success_popup_wrapper .success_content .send_wrapper .buttons_wrapper .inline button, .success_popup_wrapper .success_content button.submitted_export {
    width: 100px;
    background: var(--gitgo-blue);
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #FFFFFF;
    border-radius: 2px;
    height: 36px;
}

.success_popup_wrapper .success_content .send_wrapper .buttons_wrapper .inline button.export, .success_popup_wrapper .success_content button.submitted_export {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 4px;
    justify-content: center;
}

.success_popup_wrapper .success_content .send_wrapper .error_message {
    margin: .5rem 0 0;
    font-size: 14px;
    line-height: 21px;
    color: var(--error);
    display: block;
}

.success_popup_wrapper .success_content button.submitted_export {
    margin: 1.5rem 0 0 auto;
}