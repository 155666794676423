.manage_seats_wrapper h2 {
    margin: 0 0 3rem;
}

.manage_seats_wrapper .total_seats {
    background: #FFFFFF;
    border: 1px solid #478ECC;
    margin: 0 0 36px;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 1rem;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 2.75rem 1.5rem 1.5rem;
    max-width: 352px;
}

.manage_seats_wrapper .total_seats h6 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    margin: 0 0 1rem;
}

.manage_seats_wrapper .total_seats span {
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
}

.manage_seats_wrapper .total_seats button {
    background: var(--gitgo-blue);
    border-radius: 2px;
    width: 4.5rem;
    height: 3rem;
    display: grid;
    align-items: center;
    justify-content: center;
}

.manage_seats_wrapper .content {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
    justify-content: center;
}

.manage_seats_wrapper .content .block-manage {
    background: #FFFFFF;
    border-radius: 8px;
}

.manage_seats_wrapper .content .block-manage .head, .manage_seats_wrapper .content .block-manage .body_content .item {
    display: grid;
    grid-template-columns: 4fr 5fr 2fr 1fr;
    align-items: center;
    grid-gap: 1.5rem;
    padding: 1.5rem 22px 23px 1rem;
    border-bottom: 1px solid #E2E8F0;
}

.manage_seats_wrapper .content .block-manage .body_content .item:last-of-type {
    border-bottom: none;
    padding: 1.5rem 22px 1.5rem 1rem;
}

.manage_seats_wrapper .content .block-manage .head .item {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: .5rem;
}

.manage_seats_wrapper .content .block-manage .head .item span {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #0F172A;
    text-transform: capitalize;
}

.manage_seats_wrapper .content .block-manage .body_content .item {
    padding: 20px 22px 12px 1rem;
}

.manage_seats_wrapper .content .block-manage .body_content .item p {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--gitgo-blue);
}

.manage_seats_wrapper .content .block-manage .body_content .item span:not(.manage-seats-tooltip .manage-seats-tooltip-text) {
    font-size: 16px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #475569;
    opacity: 0.5;
}

.manage_seats_wrapper .content .block-manage .body_content .item svg {
    margin-left: auto;
    cursor: pointer;
}

.manage-seats-icons-container {
    display: flex;
    justify-content: space-between;
}

.manage-seats-tooltip {
    position: relative;
    display: inline-block;
}

.manage-seats-tooltip .manage-seats-tooltip-text {
    visibility: hidden;
    width: 70px;
    background-color: rgba(71, 85, 105, 0.5);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 20;
    top: 100%;
    left: 50%;
    margin-left: -35px;
    transition: .2s;
    opacity: 0;
}

.manage-seats-tooltip:hover .manage-seats-tooltip-text {
    visibility: visible;
    opacity: 1
}